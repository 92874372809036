import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { loadDiscoveryLogsAction } from './discovery-logs.actions';

const selectDiscoveryLogsStore = (state: RootState) => state.discoveryLogs;

export const selectDiscoveryLogsItems = createSelector(
  selectDiscoveryLogsStore,
  state => state.items
);

export const selectLoadDiscoveryLogsPendingStatus = createSelector(
  selectDiscoveryLogsStore,
  state => state.pending.some(action => action.type.startsWith(loadDiscoveryLogsAction.typePrefix))
);

export const selectDiscoveryLogsNextPageAvailable = createSelector(
  selectDiscoveryLogsStore,
  state => Boolean(state.next)
);

export const selectDiscoveryLogsNextPageParams = createSelector(
  selectDiscoveryLogsStore,
  state => ({
    next: state.next,
    pageSize: state.pageSize
  })
);
