import { createSvgIcon } from '@mui/material/utils';

export const IntegrationHubIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3333 13.3333V5.41667C16.3333 4.5 15.5833 3.75 14.6666 3.75H10.0833"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1667 1.66669L9.66675 3.75002L12.1667 5.83335"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3333 18.3333C17.714 18.3333 18.8333 17.214 18.8333 15.8333C18.8333 14.4526 17.714 13.3333 16.3333 13.3333C14.9525 13.3333 13.8333 14.4526 13.8333 15.8333C13.8333 17.214 14.9525 18.3333 16.3333 18.3333Z"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66675 6.66669V14.5834C4.66675 15.5 5.41675 16.25 6.33341 16.25H10.9167"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83325 18.3334L11.3333 16.25L8.83325 14.1667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66675 6.66669C6.04746 6.66669 7.16675 5.5474 7.16675 4.16669C7.16675 2.78598 6.04746 1.66669 4.66675 1.66669C3.28604 1.66669 2.16675 2.78598 2.16675 4.16669C2.16675 5.5474 3.28604 6.66669 4.66675 6.66669Z"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IntegrationHubIcon'
);
