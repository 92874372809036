import type { FC } from 'react';
import Typography from '@mui/material/Typography';

export const NavListSectionHeader: FC<{ readonly text: string; readonly collapsed: boolean }> = ({
  text,
  collapsed
}) => {
  return (
    <Typography
      variant="subtitle2Medium"
      sx={{
        textTransform: 'uppercase',
        color: theme => theme.palette.custom.sidebar.sectionHeaderFg,
        pl: theme => (collapsed ? theme.spacing(2) : theme.spacing(1.5))
      }}
    >
      {collapsed ? '—' : text}
    </Typography>
  );
};
