import AddIcon from '@mui/icons-material/Add';
import { ApplicationButton } from './ApplicationButton';

export const AddApplicationButton = () => {
  return (
    <ApplicationButton startIcon={<AddIcon />} variant="contained">
      Add Application
    </ApplicationButton>
  );
};
