import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { SecuritySettings, SetupMfaView } from '../models';
import {
  changePasswordAction,
  disableMfaAction,
  enableMfaAction,
  generateMfaCodeAction,
  loadSecuritySettingsAction,
  setPasswordAction,
  verifyMfaAction
} from './security-settings.actions';

export interface SecuritySettingsState {
  readonly securitySettings: SecuritySettings | null;
  readonly mfaView: SetupMfaView | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialSecuritySettingsState: SecuritySettingsState = {
  securitySettings: null,
  mfaView: null,
  pending: [],
  error: null
};

export const securitySettingsSlice = createSlice({
  name: 'securitySettings',
  initialState: initialSecuritySettingsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSecuritySettingsAction.pending, (state, action) => ({
      ...state,
      securitySettings: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadSecuritySettingsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          securitySettings: action.payload
        },
        loadSecuritySettingsAction
      )
    );
    builder.addCase(loadSecuritySettingsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadSecuritySettingsAction
      )
    );

    builder.addCase(setPasswordAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(setPasswordAction.fulfilled, state =>
      cleanupPendingState(
        {
          ...state,
          mfaView: null
        },
        setPasswordAction
      )
    );
    builder.addCase(setPasswordAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        setPasswordAction
      )
    );

    builder.addCase(changePasswordAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(changePasswordAction.fulfilled, state =>
      cleanupPendingState(
        {
          ...state,
          mfaView: null
        },
        changePasswordAction
      )
    );
    builder.addCase(changePasswordAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        changePasswordAction
      )
    );

    builder.addCase(generateMfaCodeAction.pending, (state, action) => ({
      ...state,
      mfaView: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(generateMfaCodeAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          mfaView: action.payload
        },
        generateMfaCodeAction
      )
    );
    builder.addCase(generateMfaCodeAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        generateMfaCodeAction
      )
    );

    builder.addCase(enableMfaAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(enableMfaAction.fulfilled, state =>
      cleanupPendingState(
        {
          ...state,
          mfaView: null
        },
        enableMfaAction
      )
    );
    builder.addCase(enableMfaAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        enableMfaAction
      )
    );

    builder.addCase(disableMfaAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(disableMfaAction.fulfilled, state =>
      cleanupPendingState(
        {
          ...state,
          mfaView: null
        },
        disableMfaAction
      )
    );
    builder.addCase(disableMfaAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        disableMfaAction
      )
    );

    builder.addCase(verifyMfaAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(verifyMfaAction.fulfilled, state =>
      cleanupPendingState(
        {
          ...state,
          mfaView: null
        },
        verifyMfaAction
      )
    );
    builder.addCase(verifyMfaAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        verifyMfaAction
      )
    );
  }
});

export const securitySettingsReducer = securitySettingsSlice.reducer;
