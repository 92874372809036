import type { CookieAttributes } from 'js-cookie';
import Cookies from 'js-cookie';

type PlainObject = any;

interface ClientStorageOptions {
  expiresIn: number;
}

export const CookieStorageService = (() => {
  const cookieAttributes: CookieAttributes | undefined =
    document.location.protocol === 'https:' ? { secure: true, sameSite: 'none' } : undefined;

  const parseJSON = (json: string | undefined): PlainObject => {
    if (typeof json === 'undefined') {
      return undefined;
    }
    try {
      return JSON.parse(json);
    } catch (e) {
      return undefined;
    }
  };

  const serializeObject = (obj: PlainObject): string => {
    try {
      return JSON.stringify(obj);
    } catch (e) {
      return '';
    }
  };

  return {
    getCookie: (key: string): PlainObject => {
      return parseJSON(Cookies.get(key));
    },
    setCookie: (key: string, value: PlainObject, options?: ClientStorageOptions): void => {
      const attributes: CookieAttributes = {
        ...(cookieAttributes ?? {}),
        expires: options?.expiresIn
      };
      Cookies.set(key, serializeObject(value), attributes);
    },
    deleteCookie: (key: string): void => {
      const attributes: CookieAttributes = {
        ...(cookieAttributes ?? {}),
        expires: new Date(0)
      };
      Cookies.remove(key, attributes);
    }
  };
})();
