import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, ID, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { Authentication, AuthenticationBulkAction, AuthenticationDetails } from '../models';
import {
  exportAuthentications,
  loadAuthentication,
  loadAuthentications,
  runAuthenticationsBulkAction,
  updateAuthenticationName
} from '../services';

export const loadAuthenticationsAction = createAsyncThunk<
  PaginationResponse<Authentication>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'authentications/loadAuthentications',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadAuthentications(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authentications, loadAuthenticationsAction)
  }
);

export const runAuthenticationsBulkActionAction = createAsyncThunk<
  ID[] | void,
  {
    ids: string[];
    action: AuthenticationBulkAction;
  },
  { state: RootState }
>('authentications/runAuthenticationsBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runAuthenticationsBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportAuthenticationsAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>(
  'authentications/exportAuthenticationsAction',
  async ({ appId, ids, format }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const response = await exportAuthentications(orgId, appId, format, ids);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
      fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const loadAuthenticationDetailsAction = createAsyncThunk<
  AuthenticationDetails,
  {
    authenticationId: string;
  },
  { state: RootState }
>(
  'authentications/loadAuthenticationDetails',
  async ({ authenticationId }, { rejectWithValue }) => {
    try {
      return await loadAuthentication(authenticationId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ authenticationId }, { getState }) =>
      actionNotPending(
        getState().authentications,
        action =>
          action.type === loadAuthenticationDetailsAction.pending.type &&
          (action as ReturnType<typeof loadAuthenticationDetailsAction.pending>).meta.arg
            .authenticationId === authenticationId
      )
  }
);

export const updateAuthenticationNameAction = createAsyncThunk<
  { authenticationId: string; name: string },
  {
    authenticationId: string;
    data: Pick<Authentication, 'name'>;
  },
  { state: RootState }
>(
  'authentications/updateAuthenticationName',
  async ({ authenticationId, data }, { rejectWithValue }) => {
    try {
      await updateAuthenticationName(authenticationId, data);
      return { authenticationId: authenticationId, name: data.name };
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authentications, updateAuthenticationNameAction)
  }
);
