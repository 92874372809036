import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, Category } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadCategories } from '../services';

export const loadCategoriesAction = createAsyncThunk<Category[], void, { state: RootState }>(
  'categories/loadCategories',
  async (_, { rejectWithValue }) => {
    try {
      return await loadCategories();
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().categories, loadCategoriesAction)
  }
);
