import type { OrgApiKey } from '@bright/api';

export enum ApiKeyType {
  ORGANIZATION = 'organization',
  PERSONAL = 'personal'
}

export interface ApiKeyDetails extends OrgApiKey {
  readonly type: ApiKeyType;
}
