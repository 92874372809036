import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, RoleListItem } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportRolesAction,
  loadRolesAction,
  runRolesBulkActionAction,
  updateRoleNameAction
} from './roles.actions';

export interface RolesState {
  readonly items: RoleListItem[];
  readonly total: number;
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
}

const initialRolesState: RolesState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: initialRolesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadRolesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadRolesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadRolesAction
      )
    );
    builder.addCase(loadRolesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadRolesAction
      )
    );

    builder.addCase(updateRoleNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateRoleNameAction.fulfilled, state =>
      cleanupPendingState(state, updateRoleNameAction)
    );
    builder.addCase(updateRoleNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateRoleNameAction
      )
    );

    builder.addCase(runRolesBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runRolesBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runRolesBulkActionAction)
    );
    builder.addCase(runRolesBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runRolesBulkActionAction
      )
    );

    builder.addCase(exportRolesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportRolesAction.fulfilled, state =>
      cleanupPendingState(state, exportRolesAction)
    );
    builder.addCase(exportRolesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportRolesAction
      )
    );
  }
});

export const rolesReducer = rolesSlice.reducer;
