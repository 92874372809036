import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import type { ApiError, LogEntry, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import { exportScanLogs, loadScanLogs } from '../services';
import { selectScanLogsNextPageParams } from './scan-logs.selectors';
import { selectScan } from './scan.selectors';

export const loadScanLogsAction = createAsyncThunk<
  PaginationResponse<LogEntry>,
  { scanId: string },
  { state: RootState }
>(
  'scan/loadScanLogs',
  async ({ scanId }, { getState, rejectWithValue }) => {
    try {
      if (!scanId) {
        return rejectWithValue('Scan is not available');
      }

      const { next, limit } = selectScanLogsNextPageParams(getState());

      return loadScanLogs(scanId, `limit=${limit}${next ? `&next=${next}` : ''}`);
    } catch (error: unknown) {
      return rejectWithValue(error as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().scanLogs, loadScanLogsAction)
  }
);

export const exportScanLogsAction = createAsyncThunk<void, { appId: string }, { state: RootState }>(
  'scan/exportScanLogs',
  async ({ appId }, { getState, rejectWithValue }) => {
    const state: RootState = getState();

    const scan = selectScan(state);
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      if (!scan) {
        return rejectWithValue('Scan is not available');
      }
      const res = await exportScanLogs(orgId, appId, scan.id);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(res.headers));
      fileSaver.saveAs(res.data, name);
    } catch (error: unknown) {
      return rejectWithValue(error as unknown as ApiError);
    }
  }
);
