import type { AxiosResponse } from 'axios';

const replaceRootPrevWithPrevious = (data: any): any => {
  if (data && typeof data === 'object' && !Array.isArray(data)) {
    if (Object.hasOwn(data, 'prev')) {
      data['previous'] = data['prev'];
      delete data['prev'];
    }
  }
  return data;
};

export const legacyResponseInterceptor = async (
  response: AxiosResponse
): Promise<AxiosResponse> => {
  const url = response.config.url;
  if (url && url.startsWith('/api/v4')) {
    response.data = replaceRootPrevWithPrevious(response.data);
  }
  return response;
};
