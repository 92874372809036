import { axiosInstance, toShortId } from '@bright/core';
import type { SitemapNode, SitemapNodeParam } from '../models';

export const loadSitemap = async (orgId: string, appId: string): Promise<SitemapNode[]> => {
  // TODO: remove once application sitemap API (ADM-1981) will be ready
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return await axiosInstance
      .get<
        SitemapNode[]
      >(`/api/v2/projects/${toShortId(appId)}/discoveries/p3n8b753dmMzHrFZC5L54q/sitemap`)
      .then(({ data }) => {
        const replaceInParams = (params: SitemapNodeParam[]): SitemapNodeParam[] => {
          return params.map(node => ({
            ...node,
            name: node.name === 'N/A' ? '' : node.name,
            ...(node.children ? { children: replaceInParams(node.children) } : {})
          }));
        };

        const replaceInNodes = (nodes: SitemapNode[]): SitemapNode[] => {
          return nodes.map(node => ({
            ...node,
            name: node.name === 'N/A' ? '' : node.name,
            ...(node.params ? { params: replaceInParams(node.params) } : {}),
            ...(node.children ? { children: replaceInNodes(node.children) } : {})
          }));
        };

        return replaceInNodes(data);
      });
  }

  return await axiosInstance
    .get<SitemapNode[]>(`/api/v4/orgs/${orgId}/apps/${appId}/sitemap`)
    .then(({ data }) => data);
};
