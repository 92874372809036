import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, OrgApiKey } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportOrgApiKeysAction,
  loadOrgApiKeysAction,
  runOrgApiKeysBulkActionAction
} from './api-keys.actions';

export interface OrgApiKeysState {
  readonly items: OrgApiKey[];
  readonly total: number;
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
}

const initialOrgApiKeysState: OrgApiKeysState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const orgApiKeysSlice = createSlice({
  name: 'orgApiKeys',
  initialState: initialOrgApiKeysState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadOrgApiKeysAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadOrgApiKeysAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadOrgApiKeysAction
      )
    );
    builder.addCase(loadOrgApiKeysAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadOrgApiKeysAction
      )
    );

    builder.addCase(runOrgApiKeysBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runOrgApiKeysBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runOrgApiKeysBulkActionAction)
    );
    builder.addCase(runOrgApiKeysBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runOrgApiKeysBulkActionAction
      )
    );

    builder.addCase(exportOrgApiKeysAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportOrgApiKeysAction.fulfilled, state =>
      cleanupPendingState(state, exportOrgApiKeysAction)
    );
    builder.addCase(exportOrgApiKeysAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportOrgApiKeysAction
      )
    );
  }
});

export const orgApiKeysReducer = orgApiKeysSlice.reducer;
