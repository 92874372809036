import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { RouterNavigationService } from '@bright/core';

export const NavigationServiceInitializer = () => {
  const navigate = useNavigate();

  useEffect(() => RouterNavigationService.setNavigateFunction(navigate), [navigate]);

  return <Outlet />;
};
