import type { AxiosResponse } from 'axios';
import type { ExportFormat, PaginationResponse } from '@bright/api';
import { axiosInstance } from '@bright/core';
// eslint-disable-next-line import/extensions
import mockedAuthenticationsPoolData from '../../../mocks/mocked-authentication-pools.json';
import type { AuthPool } from '../models';
import { AuthPoolBulkAction } from '../models';

let mockedAuthenticationPools: AuthPool[] = JSON.parse(
  JSON.stringify(mockedAuthenticationsPoolData)
);

export const loadAuthenticationPools = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<AuthPool>> => {
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return new Promise(resolve => {
      resolve({
        items: mockedAuthenticationPools,
        total: mockedAuthenticationPools.length
      });
    });
  }

  const response = await axiosInstance.get<PaginationResponse<AuthPool>>(
    `/rest/v1/apps/${appId}/authentication-pools?${queryString}`
  );
  return response.data;
};

export const runAuthenticationPoolsBulkAction = async (
  ids: string[],
  action: AuthPoolBulkAction
): Promise<void> => {
  switch (action) {
    case AuthPoolBulkAction.DELETE:
      // eslint-disable-next-line no-constant-condition
      if ('mocked') {
        mockedAuthenticationPools = mockedAuthenticationPools.filter(
          pool => !ids.includes(pool.id)
        );
        return Promise.resolve();
      }

      await axiosInstance.post<void>(`/rest/v1/authentication-pools/delete`, {
        ids
      });
      break;
    default:
      throw new Error(`Unknown authentication pool bulk action: ${action}`);
  }
};

export const exportAuthenticationPools = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/authentication-pools/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};
