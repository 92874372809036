import type { RouteObject } from 'react-router-dom';

export const vulnerabilityEntrypointsRoutes: RouteObject[] = [
  {
    path: '',
    async lazy() {
      const { VulnerabilityEntrypointsPage } = await import('./pages');
      return { Component: VulnerabilityEntrypointsPage };
    }
  }
];
