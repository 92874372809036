import type { AxiosResponse } from 'axios';
import type { BasicTestSet, ExportFormat, ID, PaginationResponse, TestSet } from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';
import { SetBulkAction } from '../models';
import type { ManageTestSetPayload, TestSetConfig, TestSetDetails } from '../models';

export const loadSets = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<TestSet>> => {
  const response = await axiosInstance.get<PaginationResponse<TestSet>>(
    `/rest/v1/apps/${appId}/sets?${queryString}`
  );

  return response.data;
};

export const loadBasicSets = async (appId: string): Promise<BasicTestSet[]> => {
  const url = `/rest/v1/apps/${appId}/sets`;
  const allSets = fetchAllPages<TestSetDetails>(url);
  const containsExclusiveTests = fetchAllPages<TestSetDetails>(
    `${url}?containsExclusiveTests=true`
  );

  return Promise.all([allSets, containsExclusiveTests]).then(
    ([allSets, containsExclusiveTests]) => {
      return allSets.map(({ id, name }: TestSetDetails) => ({
        id,
        name,
        containsExclusiveTests: containsExclusiveTests.some(s => s.id === id)
      }));
    }
  );
};

export const loadSetDetails = async (setId: string): Promise<TestSetDetails> => {
  return (await axiosInstance.get<TestSetDetails>(`/rest/v1/sets/${setId}/details`)).data;
};

export const loadSet = async (setId: string): Promise<TestSetConfig> => {
  return (await axiosInstance.get<TestSetConfig>(`/rest/v1/sets/${setId}`)).data;
};

export const updateSetName = async (setId: string, data: Pick<TestSet, 'name'>): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/sets/${setId}`, data);
};

export const updateSetTests = async (setId: string, testIds: string[]): Promise<void> => {
  const setConfig = await loadSet(setId);

  await axiosInstance.patch<void>(`/rest/v1/sets/${setId}`, {
    tests: [...new Set([...setConfig.tests, ...testIds])]
  });
};

export const runSetsBulkAction = async (
  ids: string[],
  action: SetBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case SetBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/sets/delete`, {
        ids
      });
      break;
    default: {
      throw new Error(`Unknown set bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportSets = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/sets/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createSet = async (appId: string, set: ManageTestSetPayload): Promise<ID> => {
  return (await axiosInstance.post(`/rest/v1/apps/${appId}/sets`, set)).data;
};

export const updateSet = async (setId: string, set: ManageTestSetPayload): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/sets/${setId}`, {
    id: setId,
    ...set
  });
};
