import { useEffect, useState } from 'react';
import type { FC, HTMLAttributes, PropsWithChildren, UIEventHandler } from 'react';
import { FocusTrap } from '@mui/base';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import type { SxProps, Theme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import type { Placement } from '@popperjs/core';

export const CustomPopover: FC<
  {
    readonly anchorEl: HTMLElement | null;
    readonly onClose: () => void;
    readonly onScroll?: UIEventHandler<HTMLDivElement>;
    readonly sx?: SxProps<Theme>;
    readonly wrapperSx?: SxProps<Theme>;
    readonly placement?: Placement;
  } & Partial<Pick<HTMLAttributes<HTMLElement>, 'role'>> &
    PropsWithChildren
> = ({
  anchorEl,
  sx,
  children,
  onClose,
  wrapperSx,
  onScroll,
  placement = 'bottom-start',
  ...props
}) => {
  const [anchorClicks, setAnchorClicks] = useState(0);
  const [delayedFocus, setDelayedFocus] = useState<boolean>(false);

  const clickAwayHandler = (event: MouseEvent | TouchEvent): void => {
    // FIXME workaround: filtering clicks on dropdown fields (with unclear `body` target)
    if ((event.target as HTMLElement).tagName === 'BODY') {
      return;
    }

    if (anchorEl?.contains(event.target as HTMLElement)) {
      setAnchorClicks(anchorClicks + 1);
      return;
    }
    setAnchorClicks(0);
    onClose();
  };

  useEffect(() => {
    if (!anchorClicks) {
      return;
    }

    if (anchorClicks % 2 === 0) {
      onClose();
    }
  }, [anchorClicks, onClose]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDelayedFocus(!!anchorEl);
    }, 100);

    return () => clearTimeout(timerId);
  }, [anchorEl]);

  useEffect(() => {
    if (!anchorEl) {
      setAnchorClicks(0);
    }
  }, [anchorEl]);

  return (
    <Box
      sx={{ ...wrapperSx }}
      onKeyDown={event => {
        if (event.key === 'Escape') {
          onClose();
        }
      }}
    >
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <Box>
          <Popper
            onScroll={onScroll}
            placement={placement}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 2]
                }
              }
            ]}
            sx={{
              backgroundColor: theme => theme.palette.background.paper,
              borderRadius: '10px',
              maxHeight: '80%',
              overflow: 'auto',
              boxShadow: theme => `0px 2px 8px 0px ${alpha(theme.palette.common.black, 0.16)}`,
              zIndex: theme => theme.zIndex.modal + 1,
              ...sx
            }}
            open={anchorEl !== null}
            anchorEl={anchorEl}
            {...props}
          >
            {!!anchorEl && (
              <FocusTrap open={delayedFocus}>
                <Box tabIndex={-1}>{children}</Box>
              </FocusTrap>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};
