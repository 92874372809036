import type { RouteObject } from 'react-router-dom';

export const scansRoutes: RouteObject[] = [
  {
    path: 'scans',
    async lazy() {
      const { ScansPage } = await import('./pages');
      return { Component: ScansPage };
    },
    children: [
      {
        path: 'run',
        async lazy() {
          const { RunScanPage } = await import('./pages');
          return { Component: RunScanPage };
        }
      }
    ]
  },
  {
    path: 'scans/:scanId',
    async lazy() {
      const { ScanPage } = await import('./pages');
      return { Component: ScanPage };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { ScanDetailsProgressPage } = await import('./pages');
          return { Component: ScanDetailsProgressPage };
        }
      },
      {
        path: 'response-statuses',
        async lazy() {
          const { ScanResponseStatusesPage } = await import('./pages');
          return { Component: ScanResponseStatusesPage };
        }
      },
      {
        path: 'traffic',
        async lazy() {
          const { ScanDetailsTrafficMetricsPage } = await import('./pages');
          return { Component: ScanDetailsTrafficMetricsPage };
        }
      },
      {
        path: 'logs',
        async lazy() {
          const { ScanLogsPage } = await import('./pages');
          return { Component: ScanLogsPage };
        }
      }
    ]
  }
];
