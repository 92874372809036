import type { Action } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { cleanupPendingState } from '@bright/core';
import type { RoleDetails } from '../models';
import { createRoleAction, loadRoleAction, updateRoleAction } from './role.actions';

export interface RoleState {
  readonly role: RoleDetails | null;
  readonly pending: Action[];
}

const initialRoleState: RoleState = {
  role: null,
  pending: []
};

export const roleSlice = createSlice({
  name: 'role',
  initialState: initialRoleState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadRoleAction.pending, (state, action) => ({
      ...state,
      role: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadRoleAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          role: action.payload
        },
        loadRoleAction
      )
    );
    builder.addCase(loadRoleAction.rejected, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          error: action.error.message
        },
        loadRoleAction
      )
    );

    builder.addCase(updateRoleAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateRoleAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateRoleAction)
    );
    builder.addCase(updateRoleAction.rejected, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          error: action.error.message
        },
        updateRoleAction
      )
    );

    builder.addCase(createRoleAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createRoleAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createRoleAction)
    );
    builder.addCase(createRoleAction.rejected, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          error: action.error.message
        },
        createRoleAction
      )
    );
  }
});

export const roleReducer = roleSlice.reducer;
