import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, Application, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadApplications } from '../service';

export const loadPaginatedApplicationsAction = createAsyncThunk<
  PaginationResponse<Application>,
  {
    queryString: string;
  },
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'paginatedApplications/loadPaginatedApplications',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadApplications(orgId, queryString);
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().paginatedApplications, loadPaginatedApplicationsAction)
  }
);
