import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, EntrypointDetails } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { DiscoveryEntrypoint } from '../models';
import {
  loadDiscoveryEntrypointDetailsAction,
  loadDiscoveryEntrypointsAction
} from './discovery-entrypoints.actions';

export interface DiscoveryEntrypointsState {
  readonly items: DiscoveryEntrypoint[];
  readonly entrypointDetails: Record<string, EntrypointDetails | undefined>;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialDiscoveryEntrypointsState: DiscoveryEntrypointsState = {
  items: [],
  entrypointDetails: {},
  total: 0,
  pending: [],
  error: null
};

const discoveryEntrypointsSlice = createSlice({
  name: 'discoveryEntrypoints',
  initialState: initialDiscoveryEntrypointsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDiscoveryEntrypointsAction.pending, (state, action) => ({
      ...state,
      items: [],
      total: 0,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDiscoveryEntrypointsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadDiscoveryEntrypointsAction
      )
    );
    builder.addCase(loadDiscoveryEntrypointsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadDiscoveryEntrypointsAction
      )
    );

    builder.addCase(loadDiscoveryEntrypointDetailsAction.pending, (state, action) => ({
      ...state,
      entrypointDetails: {
        ...state.entrypointDetails,
        [action.meta.arg.entrypointId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDiscoveryEntrypointDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          entrypointDetails: {
            ...state.entrypointDetails,
            [action.payload.id]: action.payload
          }
        },
        loadDiscoveryEntrypointDetailsAction
      )
    );
    builder.addCase(
      loadDiscoveryEntrypointDetailsAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          loadDiscoveryEntrypointDetailsAction
        )
    );
  }
});

export const discoveryEntrypointsReducer = discoveryEntrypointsSlice.reducer;
