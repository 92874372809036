import shortUUID from 'short-uuid';

//TODO remove temporal conversion
export function toLongId(shortId: string): string {
  if (shortId.length !== 22) {
    return shortId;
  }

  return shortUUID().toUUID(shortId);
}

//TODO remove temporal conversion
export function toShortId(longId: string): string {
  if (longId.length !== 36) {
    return longId;
  }

  return shortUUID().fromUUID(longId);
}

export function generateUuid(): string {
  return shortUUID().generate();
}
