import { createAsyncThunk } from '@reduxjs/toolkit';
import type { VulnerabilityDetails, VulnerabilityStatus } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadVulnerability, updateVulnerabilityStatus } from '../services';

export const loadVulnerabilityAction = createAsyncThunk<
  VulnerabilityDetails,
  {
    vulnerabilityId: string;
  },
  { state: RootState }
>(
  'vulnerability/loadVulnerability',
  async ({ vulnerabilityId }, { rejectWithValue }) => {
    try {
      return await loadVulnerability(vulnerabilityId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(
        getState().vulnerability,
        action => action.type === loadVulnerabilityAction.pending.type
      )
  }
);

export const updateVulnerabilityStatusAction = createAsyncThunk<
  void,
  {
    vulnerabilityId: string;
    status: VulnerabilityStatus;
  },
  { state: RootState }
>(
  'vulnerability/updateVulnerabilityStatus',
  async ({ vulnerabilityId, status }, { rejectWithValue }) => {
    try {
      return await updateVulnerabilityStatus(vulnerabilityId, status);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerability, updateVulnerabilityStatusAction)
  }
);

export const clearVulnerabilityDataAction = createAsyncThunk<void, undefined, { state: RootState }>(
  'vulnerability/clearVulnerabilityData',
  () => Promise.resolve()
);
