import { axiosInstance } from '@bright/core';
import { addAccessTokenInterceptor, handleAxiosErrorInterceptor } from './services';

// IIFE ensures interceptors are added to the Axios instance for two reasons:
// 1. Maintains correct dependency order: auth module depends on core, avoiding circular dependencies.
// 2. Prevents initialization issues: Adding interceptors directly in App.tsx leads to execution errors in /auth/routes.tsx outside of Router scope.

(() => {
  axiosInstance.interceptors.request.use(addAccessTokenInterceptor, error => Promise.reject(error));

  axiosInstance.interceptors.response.use(response => response, handleAxiosErrorInterceptor);
})();

export * from './components';
export * from './routes';
