interface CustomGridClasses {
  oddRow: string;
  evenRow: string;
  columnMenu: string;
  hasHorizontalScroll: string;
  hasVerticalScroll: string;
  scrolledVertically: string;
  rowActionsColumn: string;
  cursorPointer: string;
  cursorNotAllowed: string;
}

export const customGridClasses: CustomGridClasses = {
  oddRow: 'Custom-grid-odd-row',
  evenRow: 'Custom-grid-even-row',
  columnMenu: 'Custom-grid-column-menu',
  hasHorizontalScroll: 'Custom-grid-has-horizontal-scroll',
  hasVerticalScroll: 'Custom-grid-has-vertical-scroll',
  scrolledVertically: 'Custom-grid-scrolled-vertically',
  rowActionsColumn: 'Custom-grid-row-actions-column',
  cursorPointer: 'Custom-grid-cursor-pointer',
  cursorNotAllowed: 'Custom-grid-cursor-not-allowed'
};
