import type { RouteObject } from 'react-router-dom';
import { vulnerabilityEntrypointsRoutes } from '@bright/vulnerability-entrypoints';

export const vulnerabilitiesRoutes: RouteObject[] = [
  {
    path: 'vulnerabilities',
    async lazy() {
      const { VulnerabilitiesPage } = await import('./pages');
      return { Component: VulnerabilitiesPage };
    }
  },
  {
    path: 'vulnerabilities/:vulnerabilityId',
    async lazy() {
      const { VulnerabilityPage } = await import('./pages');
      return { Component: VulnerabilityPage };
    },
    children: [...vulnerabilityEntrypointsRoutes]
  }
];
