import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useRef, useState } from 'react';
import type { ConfirmDialogProps } from './ConfirmDialog';
import { ConfirmDialog } from './ConfirmDialog';

export type ConfirmOptions = Omit<
  ConfirmDialogProps,
  'opened' | 'onConfirm' | 'onClose' | 'container'
>;

type ContainerElement = Element | null;

interface ConfirmDialogContext {
  confirm: (data: ConfirmOptions) => Promise<boolean>;
  setDialogContainer: (element: ContainerElement) => void;
  closeConfirmDialog: () => void;
}

export const ConfirmDialogContext = createContext<ConfirmDialogContext>({
  confirm: () => new Promise<boolean>(resolve => resolve(true)),
  setDialogContainer: () => undefined,
  closeConfirmDialog: () => undefined
});

export const ConfirmDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<{ opened: boolean } & Partial<ConfirmDialogProps>>({
    opened: false
  });
  const fn = useRef<(choice: boolean) => void>(() => undefined);

  const confirm = useCallback(
    (data: ConfirmOptions) => {
      return new Promise<boolean>(resolve => {
        setState({ ...data, opened: true });
        fn.current = (confirmed: boolean) => {
          resolve(confirmed);
          setState(currentState => ({ ...currentState, opened: false }));
        };
      });
    },
    [setState]
  );

  const setDialogContainer = useCallback(
    (container: ContainerElement) => setState(currentState => ({ ...currentState, container })),
    [setState]
  );

  const closeConfirmDialog = () => {
    fn.current(false);
  };

  return (
    <ConfirmDialogContext.Provider value={{ confirm, setDialogContainer, closeConfirmDialog }}>
      {children}
      <ConfirmDialog
        {...state}
        onConfirm={() => fn.current(true)}
        onClose={() => fn.current(false)}
      />
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirm = () => useContext(ConfirmDialogContext);
