import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Vulnerability, VulnerabilityTypeDetails } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportVulnerabilitiesAction,
  loadVulnerabilitiesAction,
  loadVulnerabilityTypeDetailsAction,
  runVulnerabilitiesBulkActionAction
} from './vulnerabilities.actions';

export interface VulnerabilitiesState {
  readonly items: Vulnerability[];
  readonly typeDetails: Record<string, VulnerabilityTypeDetails | undefined>;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialVulnerabilitiesState: VulnerabilitiesState = {
  items: [],
  typeDetails: {},
  total: 0,
  pending: [],
  error: null
};

export const vulnerabilitiesSlice = createSlice({
  name: 'vulnerabilities',
  initialState: initialVulnerabilitiesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadVulnerabilitiesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadVulnerabilitiesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadVulnerabilitiesAction
      )
    );
    builder.addCase(loadVulnerabilitiesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadVulnerabilitiesAction
      )
    );

    builder.addCase(loadVulnerabilityTypeDetailsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadVulnerabilityTypeDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          typeDetails: {
            ...state.typeDetails,
            [action.payload.id]: action.payload
          }
        },
        loadVulnerabilityTypeDetailsAction
      )
    );
    builder.addCase(
      loadVulnerabilityTypeDetailsAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          loadVulnerabilityTypeDetailsAction
        )
    );

    builder.addCase(runVulnerabilitiesBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runVulnerabilitiesBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runVulnerabilitiesBulkActionAction)
    );
    builder.addCase(
      runVulnerabilitiesBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runVulnerabilitiesBulkActionAction
        )
    );

    builder.addCase(exportVulnerabilitiesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportVulnerabilitiesAction.fulfilled, state =>
      cleanupPendingState(state, exportVulnerabilitiesAction)
    );
    builder.addCase(exportVulnerabilitiesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportVulnerabilitiesAction
      )
    );
  }
});

export const vulnerabilitiesReducer = vulnerabilitiesSlice.reducer;
