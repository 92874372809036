import type { RouteObject } from 'react-router-dom';

export const apiKeysRoutes: RouteObject[] = [
  {
    path: 'api-keys',
    async lazy() {
      const { ApiKeysPage } = await import('./pages');
      return { Component: ApiKeysPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateApiKeyPage } = await import('./pages');
          return { Component: CreateApiKeyPage };
        }
      },
      {
        path: ':apiKeyId',
        async lazy() {
          const { EditApiKeyPage } = await import('./pages');
          return { Component: EditApiKeyPage };
        }
      }
    ]
  }
];
