import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { DiscoveryDetails } from '../models';
import {
  exportDiscoveryEngineLogsAction,
  exportDiscoveryEntrypointsAsHarAction,
  loadDiscoveryAction,
  updateDiscoveryNameAction
} from './discovery.actions';

export interface DiscoveryState {
  readonly discovery: DiscoveryDetails | null;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialAuthState: DiscoveryState = {
  discovery: null,
  pending: [],
  error: null
};

const discoverySlice = createSlice({
  name: 'discovery',
  initialState: initialAuthState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDiscoveryAction.pending, (state, action) => ({
      ...state,
      discovery: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDiscoveryAction.fulfilled, (state, action) =>
      cleanupPendingState({ ...state, discovery: action.payload }, loadDiscoveryAction)
    );
    builder.addCase(loadDiscoveryAction.rejected, state =>
      cleanupPendingState(state, loadDiscoveryAction)
    );

    builder.addCase(updateDiscoveryNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateDiscoveryNameAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          discovery:
            state.discovery?.id === action.payload.discoveryId
              ? {
                  ...state.discovery,
                  name: action.payload.name
                }
              : state.discovery
        },
        updateDiscoveryNameAction
      )
    );
    builder.addCase(updateDiscoveryNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateDiscoveryNameAction
      )
    );

    builder.addCase(exportDiscoveryEngineLogsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportDiscoveryEngineLogsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          discovery: { ...state.discovery!, engineLogsExportStatus: action.payload.status }
        },
        exportDiscoveryEngineLogsAction
      )
    );
    builder.addCase(exportDiscoveryEngineLogsAction.rejected, state =>
      cleanupPendingState(state, exportDiscoveryEngineLogsAction)
    );

    builder.addCase(exportDiscoveryEntrypointsAsHarAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportDiscoveryEntrypointsAsHarAction.fulfilled, state =>
      cleanupPendingState(state, exportDiscoveryEntrypointsAsHarAction)
    );
    builder.addCase(exportDiscoveryEntrypointsAsHarAction.rejected, state =>
      cleanupPendingState(state, exportDiscoveryEntrypointsAsHarAction)
    );
  }
});

export const discoveryReducer = discoverySlice.reducer;
