import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { HomeIcon } from '@bright/share';
import { ErrorWidget } from '../components';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  useTitle(t('errorPages.notFound.metaTitle'));

  return (
    <ErrorWidget
      text={t('errorPages.notFound.text')}
      link={
        <>
          <HomeIcon /> {t('errorPages.notFound.linkText')}
        </>
      }
      href="/"
      errorCode="404"
      errorCodeOverlay={
        <img
          src="/not-found-overlay.svg"
          style={{
            transform: 'translateY(-14%)',
            height: 'min(29vh, 345px)'
          }}
          alt="Sad eyes"
        />
      }
    />
  );
};
