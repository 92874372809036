import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { AuthenticationDetails } from '../models';
import type { AuthenticationTestResult } from '../models/AuthenticationTestResult';
import {
  createAuthenticationAction,
  loadAuthenticationAction,
  updateAuthenticationAction
} from './authentication.actions';

export interface AuthenticationState {
  readonly authentication: AuthenticationDetails | null;
  readonly testResults: Record<string, AuthenticationTestResult | undefined>;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialAuthenticationState: AuthenticationState = {
  testResults: {},
  authentication: null,
  pending: [],
  error: null
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthenticationState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadAuthenticationAction.pending, (state, action) => ({
      ...state,
      authentication: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAuthenticationAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          authentication: action.payload
        },
        loadAuthenticationAction
      )
    );
    builder.addCase(loadAuthenticationAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAuthenticationAction
      )
    );

    builder.addCase(updateAuthenticationAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateAuthenticationAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateAuthenticationAction)
    );
    builder.addCase(updateAuthenticationAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateAuthenticationAction
      )
    );

    builder.addCase(createAuthenticationAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createAuthenticationAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createAuthenticationAction)
    );
    builder.addCase(createAuthenticationAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createAuthenticationAction
      )
    );
  }
});

export const authenticationReducer = authenticationSlice.reducer;
