import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { Integration } from '../models';
import { loadIntegrationsAction } from './integrations.actions';

export interface IntegrationsState {
  readonly items: Integration[];
  readonly total: number;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialIntegrationsState: IntegrationsState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: initialIntegrationsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadIntegrationsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadIntegrationsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total
        },
        loadIntegrationsAction
      )
    );
    builder.addCase(loadIntegrationsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadIntegrationsAction
      )
    );
  }
});

export const integrationsReducer = integrationsSlice.reducer;
