import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line import/extensions
import enTranslation from './locales/en.json';

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation
    }
  },
  lng: 'en',
  fallbackLng: 'en'
});

export const i18n = i18next;
