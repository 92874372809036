const LOWERCASE_CHARSET = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const NUMBER_CHARSET = '0123456789';
const SPECIAL_CHARSET = '!@#$%^&*()';

export const getAvatarInitials = (
  { name, email }: { name: string; email: string },
  lettersCount: 1 | 2 = 2
): string => {
  const source = name || email || '';
  const initials = source.split(' ').map(part => part[0]);
  return initials.length === 1
    ? initials[0].slice(0, lettersCount)
    : initials.join('').slice(0, lettersCount).toUpperCase();
};

export const generateTemporaryPassword = (length = 12): string => {
  // Generate at least one character from each charset
  let password = [LOWERCASE_CHARSET, UPPERCASE_CHARSET, NUMBER_CHARSET, SPECIAL_CHARSET]
    .map(charset => getRandomCharacter(charset))
    .join('');

  for (let i = 4; i < length; i++) {
    const character = getRandomCharacter(
      getRandomCharset(LOWERCASE_CHARSET, UPPERCASE_CHARSET, NUMBER_CHARSET, SPECIAL_CHARSET)
    );
    password = `${password}${character}`;
  }

  return shuffleString(password);
};

const getRandomCharacter = (value: string) => {
  const randomIndex = Math.floor(Math.random() * value.length);
  return value[randomIndex];
};

const getRandomCharset = (...charsets: string[]) => {
  const randomIndex = Math.floor(Math.random() * charsets.length);
  return charsets[randomIndex];
};

const shuffleString = (value: string) => {
  const characters = value.split('');
  for (let i = characters.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    [characters[i], characters[randomIndex]] = [characters[randomIndex], characters[i]];
  }
  return characters.join('');
};
