import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, ResponseStatus } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadScanResponseStatusesAction } from './scan-response-statuses.actions';

export interface ScanResponseStatusesState {
  readonly items: ResponseStatus[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialScanResponseStatusesState: ScanResponseStatusesState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

const scanResponseStatusesSlice = createSlice({
  name: 'scanResponseStatuses',
  initialState: initialScanResponseStatusesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadScanResponseStatusesAction.pending, (state, action) => ({
      ...state,
      items: [],
      total: 0,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadScanResponseStatusesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadScanResponseStatusesAction
      )
    );
    builder.addCase(loadScanResponseStatusesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadScanResponseStatusesAction
      )
    );
  }
});

export const scanResponseStatusesReducer = scanResponseStatusesSlice.reducer;
