import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Category } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadCategoriesAction } from './categories.actions';

export interface CategoriesState {
  readonly items: Category[];
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialCategoriesState: CategoriesState = {
  items: [],
  pending: [],
  error: null
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initialCategoriesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadCategoriesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadCategoriesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload
        },
        loadCategoriesAction
      )
    );
    builder.addCase(loadCategoriesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadCategoriesAction
      )
    );
  }
});

export const categoriesReducer = categoriesSlice.reducer;
