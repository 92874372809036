import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { AuthenticationDetails, ManageAuthenticationPayload } from '../models';
import { createAuthentication, loadAuthentication, updateAuthentication } from '../services';

export const loadAuthenticationAction = createAsyncThunk<
  AuthenticationDetails,
  {
    authenticationId: string;
  },
  { state: RootState }
>(
  'authentications/loadAuthentication',
  async ({ authenticationId }, { rejectWithValue }) => {
    try {
      return await loadAuthentication(authenticationId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authentication, loadAuthenticationAction)
  }
);

export const updateAuthenticationAction = createAsyncThunk<
  void,
  {
    authenticationId: string;
    payload: ManageAuthenticationPayload;
  },
  { state: RootState }
>(
  'authentications/updateAuthentication',
  async ({ authenticationId, payload }, { rejectWithValue }) => {
    try {
      return await updateAuthentication(authenticationId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authentication, updateAuthenticationAction)
  }
);

export const createAuthenticationAction = createAsyncThunk<
  void,
  {
    appId: string;
    payload: ManageAuthenticationPayload;
  },
  { state: RootState }
>(
  'authentications/createAuthentication',
  async ({ appId, payload }, { rejectWithValue }) => {
    try {
      return await createAuthentication(appId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authentication, createAuthenticationAction)
  }
);
