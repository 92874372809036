import type { RouteObject } from 'react-router-dom';

export const targetsRoutes: RouteObject[] = [
  {
    path: 'targets',
    async lazy() {
      const { TargetsPage } = await import('./pages');
      return { Component: TargetsPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateTargetPage } = await import('./pages');
          return { Component: CreateTargetPage };
        }
      },
      {
        path: ':targetId',
        async lazy() {
          const { EditTargetPage } = await import('./pages');
          return { Component: EditTargetPage };
        }
      }
    ]
  }
];
