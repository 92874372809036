import type { ComponentType, MouseEvent, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import type { ButtonProps } from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import type { ID } from '@bright/api';
import { CustomPopover } from './CustomPopover';
import { TextWithEllipsis } from './TextWithEllipsis';

export interface DropdownMenuProps<T extends ID> {
  readonly items: T[];
  readonly formatter: (item: T) => ReactNode;
  readonly anchorFormatter?: (item: T) => ReactNode;
  readonly onSelect: (id: string) => void;
  readonly OpenMenuButton: ComponentType<ButtonProps>;
  readonly searchComponent?: ReactNode;
  readonly emptySearchResultsMessage?: string;
  readonly activeId?: string;
  readonly children?: ReactNode;
  readonly emptyItem?: string;
  readonly ariaLabel?: string;
  readonly onClose?: () => void;
}

export const DropdownMenu = <T extends ID>({
  items,
  formatter,
  anchorFormatter,
  onSelect,
  OpenMenuButton,
  activeId,
  children,
  searchComponent,
  emptyItem,
  emptySearchResultsMessage,
  onClose,
  ariaLabel
}: DropdownMenuProps<T>) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [activeItem, setActiveItem] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (activeId) {
      setActiveItem(items.find(item => item.id === activeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const handleAnchorClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (id: string) => {
    setAnchorEl(null);
    setActiveItem(items.find(item => item.id === id));
    onSelect(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  return (
    <>
      <OpenMenuButton variant="contained" onClick={handleAnchorClick} aria-label={ariaLabel}>
        <TextWithEllipsis>
          {children
            ? children
            : activeItem
              ? anchorFormatter?.(activeItem) || formatter(activeItem)
              : emptyItem}
        </TextWithEllipsis>
        <ExpandMore sx={{ ml: 2 }} />
      </OpenMenuButton>
      <CustomPopover onClose={handleClose} anchorEl={anchorEl}>
        {searchComponent}
        {items.map(item => (
          <MenuItem
            sx={{ py: 2 }}
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Tab' && searchComponent) {
                e.stopPropagation();
              }
            }}
            selected={!!(activeItem && activeItem.id === item.id)}
            key={item.id}
            onClick={() => {
              handleSelect(item.id);
            }}
          >
            {formatter(item)}
          </MenuItem>
        ))}
        {!items.length && (
          <MenuItem
            disabled
            sx={{
              justifyContent: 'center',
              opacity: '1 !important',
              pt: 0
            }}
          >
            {emptySearchResultsMessage ?? t('common.dropdown.emptySearchResultsMessage')}
          </MenuItem>
        )}
      </CustomPopover>
    </>
  );
};
