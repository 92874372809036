import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, ID, IpRestriction } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManageIpRestrictionPayload } from '../models';
import { createIpRestriction, loadIpRestriction, updateIpRestriction } from '../services';
import { loadIpRestrictionsAction } from './ip-restrictions.actions';

export const loadIpRestrictionAction = createAsyncThunk<IpRestriction, ID, { state: RootState }>(
  'ipRestrictions/loadIpRestriction',
  async ({ id }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());

    try {
      return await loadIpRestriction(orgId, id);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().ipRestriction, loadIpRestrictionsAction)
  }
);

export const createIpRestrictionAction = createAsyncThunk<
  ID,
  {
    payload: ManageIpRestrictionPayload;
  },
  { state: RootState }
>('ipRestrictions/createIpRestriction', async ({ payload }, { getState, rejectWithValue }) => {
  const orgId = selectOwnOrganizationId(getState());

  try {
    return await createIpRestriction(orgId, payload);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const updateIpRestrictionAction = createAsyncThunk<
  void,
  {
    payload: ManageIpRestrictionPayload;
  } & ID,
  { state: RootState }
>('ipRestrictions/updateIpRestriction', async ({ id, payload }, { getState, rejectWithValue }) => {
  const orgId = selectOwnOrganizationId(getState());

  try {
    await updateIpRestriction(orgId, id, payload);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
