import { CookieStorageService } from '@bright/core';

const SESSION_COOKIE_KEY = 'user_authenticated';

export const UserSessionStorageService = (() => {
  const hasLoggedIn = (): boolean => {
    return Boolean(CookieStorageService.getCookie(SESSION_COOKIE_KEY));
  };

  const clearSession = (): void => {
    CookieStorageService.deleteCookie(SESSION_COOKIE_KEY);
  };

  const setSession = (): void => {
    CookieStorageService.setCookie(SESSION_COOKIE_KEY, true, { expiresIn: 2 }); // expires in 2 days
  };

  return {
    hasLoggedIn,
    clearSession,
    setSession
  };
})();
