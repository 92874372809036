import type { AxiosResponse } from 'axios';
import type {
  ExportFormat,
  ID,
  ImportSchemaPayload,
  PaginationResponse,
  Target,
  TargetHost,
  VerifyURLsPayload
} from '@bright/api';
import { axiosInstance } from '@bright/core';
import type { ManageTargetPayload } from '../models';
import { TargetBulkAction } from '../models';

export const loadTargets = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<Target>> => {
  const response = await axiosInstance.get<PaginationResponse<Target>>(
    `/rest/v1/apps/${appId}/targets?${queryString}`
  );
  return response.data;
};

export const loadTarget = async (targetId: string): Promise<Target> => {
  const response = await axiosInstance.get<Target>(`/rest/v1/targets/${targetId}`);
  return response.data;
};

export const updateTarget = async (
  targetId: string,
  target: ManageTargetPayload
): Promise<void> => {
  await axiosInstance.patch<Target>(`/rest/v1/targets/${targetId}`, target);
};

export const updateTargetName = async (
  targetId: string,
  data: Pick<Target, 'name'>
): Promise<void> => {
  await axiosInstance.patch<Target>(`/rest/v1/targets/${targetId}`, data);
};

export const runTargetsBulkAction = async (
  ids: string[],
  action: TargetBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case TargetBulkAction.DELETE:
      response = await axiosInstance.post<ID[] | void>(`/rest/v1/targets/delete`, {
        ids
      });
      break;
    default:
      throw new Error(`Unknown target bulk action: ${action}`);
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportTargets = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/targets/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createTarget = async (appId: string, target: ManageTargetPayload): Promise<ID> => {
  return (await axiosInstance.post<ID>(`/rest/v1/apps/${appId}/targets`, target)).data;
};

export const loadCrawlerTargets = async (
  payload: VerifyURLsPayload,
  signal: AbortSignal
): Promise<TargetHost[]> => {
  return (
    await axiosInstance.post<PaginationResponse<TargetHost>>(
      `/rest/v1/targets/urls`,
      {
        urls: payload.urls,
        ...(payload.bridgeId?.length ? { bridgeId: payload.bridgeId } : {})
      },
      { signal }
    )
  ).data.items;
};

export const importSchemaFromUrl = async (
  payload: ImportSchemaPayload
): Promise<PaginationResponse<TargetHost>> => {
  return (
    await axiosInstance.post<PaginationResponse<TargetHost>>(`/rest/v1/targets/hosts`, {
      schemaUrl: payload.schemaUrl,
      type: payload.type,
      ...(payload.bridgeId?.length ? { bridgeId: payload.bridgeId } : {})
    })
  ).data;
};
