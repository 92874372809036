import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, ID, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { Discovery, DiscoveryBulkAction, RunDiscoveryPayload } from '../models';
import {
  exportDiscoveries,
  loadDiscoveries,
  runDiscoveriesBulkAction,
  runDiscovery
} from '../services';

export const loadDiscoveriesAction = createAsyncThunk<
  PaginationResponse<Discovery>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'discoveries/loadDiscoveries',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadDiscoveries(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().discoveries, loadDiscoveriesAction)
  }
);

export const runDiscoveryAction = createAsyncThunk<
  ID,
  {
    appId: string;
    payload: RunDiscoveryPayload;
  },
  { state: RootState; rejectValue: ApiError }
>('discoveries/runDiscovery', async ({ appId, payload }, { rejectWithValue }) => {
  try {
    return await runDiscovery(appId, payload);
  } catch (err: unknown) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const runDiscoveriesBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: DiscoveryBulkAction;
  },
  { state: RootState }
>('discoveries/runDiscoveriesBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runDiscoveriesBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportDiscoveriesAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>(
  'discoveries/exportDiscoveriesAction',
  async ({ appId, ids, format }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const response = await exportDiscoveries(orgId, appId, format, ids);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
      fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
