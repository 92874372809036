import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { loadApplicationsAction, loadPropertiesAction } from './applications.actions';

export const selectApplicationsState = (state: RootState) => state.applications;

export const selectApplications = createSelector(
  (state: RootState) => state.applications.applications,
  applications =>
    applications ? [...applications].sort((a, b) => a.name.localeCompare(b.name)) : applications
);

export const selectApplicationsPendingStatus = createSelector(
  selectApplicationsState,
  state =>
    state.pending.filter(action => action.type === loadApplicationsAction.pending.type).length > 0
);

export const selectDefaultApplicationId = createSelector(selectApplicationsState, state => {
  return state.applications?.find(app => app.default)?.id ?? state.applications?.[0]?.id;
});

export const selectSessionAppId = createSelector(
  selectApplicationsState,
  state => state.sessionAppId
);

export const selectProperties = createSelector(selectApplicationsState, state => state.properties);

export const selectPropertiesPending = createSelector(
  selectApplicationsState,
  state =>
    state.pending.filter(action => action.type === loadPropertiesAction.pending.type).length > 0
);
