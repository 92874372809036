import { createSvgIcon } from '@mui/material/utils';

export const SetsIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8002 10C14.8002 10.8837 15.5166 11.6 16.4002 11.6C17.2839 11.6 18.0002 10.8837 18.0002 10C18.0002 9.11634 17.2839 8.4 16.4002 8.4C15.5166 8.4 14.8002 9.11634 14.8002 10ZM14.8002 10H10.5002M14.8002 3.6C14.8002 4.48366 15.5166 5.2 16.4002 5.2C17.2839 5.2 18.0002 4.48366 18.0002 3.6C18.0002 2.71634 17.2839 2 16.4002 2C15.5166 2 14.8002 2.71634 14.8002 3.6ZM14.8002 3.6H5.49982M14.8002 16.4C14.8002 17.2837 15.5166 18 16.4002 18C17.2839 18 18.0002 17.2837 18.0002 16.4C18.0002 15.5163 17.2839 14.8 16.4002 14.8C15.5166 14.8 14.8002 15.5163 14.8002 16.4ZM14.8002 16.4H10.2002C9.75838 16.4 9.40021 16.0418 9.40021 15.6V4.4M5.19982 3.6C5.19982 4.48366 4.48347 5.2 3.59982 5.2C2.71616 5.2 1.99982 4.48366 1.99982 3.6C1.99982 2.71634 2.71616 2 3.59982 2C4.48347 2 5.19982 2.71634 5.19982 3.6Z"
      stroke="currentcolor"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <circle cx="16.25" cy="3.75" r="1.25" fill="currentcolor" />
    <circle cx="16.25" cy="10.4167" r="1.25" fill="currentcolor" />
    <circle cx="16.25" cy="16.25" r="1.25" fill="currentcolor" />
    <circle cx="3.75" cy="3.75" r="1.25" fill="currentcolor" />
  </svg>,
  'SetsIcon'
);
