import type { RouteObject } from 'react-router-dom';

export const ipRestrictionsRoutes: RouteObject[] = [
  {
    path: 'ip-restrictions',
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateIpRestrictionPage } = await import('./pages');
          return { Component: CreateIpRestrictionPage };
        }
      },
      {
        path: ':ipRestrictionId',
        async lazy() {
          const { EditIpRestrictionPage } = await import('./pages');
          return { Component: EditIpRestrictionPage };
        }
      }
    ]
  }
];
