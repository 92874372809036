import type { FC } from 'react';
import type { DrawerProps } from '@mui/material/Drawer';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import type { CSSObject, Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { HEADER_HEIGHT } from '../AppHeader';
import { NavList } from './NavList';

const closedMixin = (theme: Theme, width: number): CSSObject => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden'
});

const openedMixin = (theme: Theme, width: number): CSSObject => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const fullDrawerWidth = 215;
const collapsedDrawerWidth = 92;

const AppDrawer = styled(Drawer, { shouldForwardProp: prop => prop !== 'open' })<DrawerProps>(({
  theme,
  open
}) => {
  const width = open ? fullDrawerWidth : collapsedDrawerWidth;

  const paperMixin = {
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    marginTop: `${HEADER_HEIGHT}px`,
    borderRightWidth: 0,
    backgroundColor: theme.palette.dark.main
  };

  return {
    width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, width),
      [`& .${drawerClasses.paper}`]: {
        ...openedMixin(theme, width),
        ...paperMixin
      }
    }),
    ...(!open && {
      ...closedMixin(theme, width),
      [`& .${drawerClasses.paper}`]: {
        ...closedMixin(theme, width),
        ...paperMixin
      }
    })
  };
});

export const AppSidebar: FC<{
  readonly appId?: string;
  readonly opened: boolean;
}> = ({ appId, opened }) => {
  return (
    <AppDrawer variant="permanent" anchor="left" open={opened}>
      <NavList appId={appId} opened={opened} />
    </AppDrawer>
  );
};
