export const API_PREFIXES = ['/api/v1', '/api/v2', '/api/v3', '/api/v4', '/rest/v1'];

export const LOGIN_ENDPOINT = new RegExp(`^${API_PREFIXES[0]}/auth/login`, 'i');

const LOGOUT_ENDPOINT = new RegExp(`^${API_PREFIXES[0]}/auth/logout/?$`, 'i');

export const TOKEN_ENDPOINT = new RegExp(`^${API_PREFIXES[0]}/auth/token`, 'i');

export const REFRESH_TOKEN_ERROR_EXCLUDED_PATHS: readonly RegExp[] = [
  new RegExp(`^${API_PREFIXES[0]}/auth/mfa/?$`, 'i'),
  new RegExp(`^${API_PREFIXES[0]}/password-reset/?$`, 'i'),
  TOKEN_ENDPOINT,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT
];

export const AUTHORIZATION_HEADER = 'Authorization';
