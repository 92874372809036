import type { Bridge, Repeater } from '@bright/api';
import { BridgeStatus, RepeaterStatus } from '@bright/api';
import { fetchAllPages } from '@bright/core';

export const loadRepeatersLookup = async (): Promise<Repeater[]> => {
  // TODO rework when backend is ready (ADM-3465)
  const bridges = await fetchAllPages<Bridge>('/rest/v1/bridges');
  return bridges.map(bridge => ({
    id: bridge.id,
    name: bridge.name,
    status: mapBridgeStatusToRepeaterStatus(bridge.status),
    active: bridge.active,
    description: bridge.description ?? '',
    editable: true
  }));
};

const mapBridgeStatusToRepeaterStatus = (status: BridgeStatus): RepeaterStatus => {
  switch (status) {
    case BridgeStatus.CONNECTED:
      return RepeaterStatus.CONNECTED;
    case BridgeStatus.DEACTIVATED:
      return RepeaterStatus.DEACTIVATED;
    case BridgeStatus.DISCONNECTED:
      return RepeaterStatus.DISCONNECTED;
    case BridgeStatus.TIMEOUT:
      return RepeaterStatus.TIMEOUT;
    default:
      return RepeaterStatus.DISCONNECTED;
  }
};
