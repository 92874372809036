import type { AxiosResponse } from 'axios';
import type {
  ExportFormat,
  ID,
  PaginationResponse,
  UserComment,
  Vulnerability,
  VulnerabilityDetails,
  VulnerabilityStatus,
  VulnerabilityTypeDetails
} from '@bright/api';
import { axiosInstance } from '@bright/core';
// eslint-disable-next-line import/extensions
import mockedVulnerabilityCommentsData from '../../../mocks/mocked-vulnerability-user-comments.json';
import { VulnerabilityBulkAction } from '../models';

export const loadVulnerabilities = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<Vulnerability>> => {
  const response = await axiosInstance.get<PaginationResponse<Vulnerability>>(
    `/rest/v1/apps/${appId}/vulnerabilities?${queryString}`
  );
  return response.data;
};

export const loadVulnerabilityTypeDetails = async (
  typeId: string
): Promise<VulnerabilityTypeDetails> => {
  const response = await axiosInstance.get<VulnerabilityTypeDetails>(
    `/rest/v1/vulnerabilities/types/${typeId}`
  );
  return response.data;
};

export const loadVulnerability = async (vulnerabilityId: string): Promise<VulnerabilityDetails> => {
  const response = await axiosInstance.get<VulnerabilityDetails>(
    `/rest/v1/vulnerabilities/${vulnerabilityId}`
  );
  return response.data;
};

export const runVulnerabilitiesBulkAction = async (
  ids: string[],
  action: VulnerabilityBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case VulnerabilityBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/vulnerabilities/delete`, {
        ids
      });
      break;
    case VulnerabilityBulkAction.RESCAN:
      response = await axiosInstance.post<void>(`/rest/v1/vulnerabilities/rescan`, {
        ids
      });
      break;
    case VulnerabilityBulkAction.IGNORED:
    case VulnerabilityBulkAction.OPEN:
    case VulnerabilityBulkAction.IN_PROGRESS:
    case VulnerabilityBulkAction.RESOLVED:
      response = await axiosInstance.post<void>(`/rest/v1/vulnerabilities/set-status`, {
        ids,
        value: action
      });
      break;
    default: {
      throw new Error(`Unknown vulnerability bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportVulnerabilities = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/vulns/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const updateVulnerabilityStatus = async (
  vulnerabilityId: string,
  status: VulnerabilityStatus
): Promise<void> => {
  await axiosInstance.post<void>(`/rest/v1/vulnerabilities/${vulnerabilityId}/set-status`, {
    value: status
  });
};

export const loadVulnerabilityComments = async (
  orgId: string,
  appId: string,
  vulnerabilityId: string
): Promise<UserComment[]> => {
  // TODO remove once vulnerability comments API (ADM-2010) will be ready
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return new Promise(resolve =>
      setTimeout(() => resolve(mockedVulnerabilityCommentsData as UserComment[]), 1500)
    );
  }

  const response = await axiosInstance.get<UserComment[]>(
    `/api/v4/orgs/${orgId}/apps/${appId}/vulns/${vulnerabilityId}/comments`
  );
  return response.data;
};

export const addVulnerabilityComment = async (
  orgId: string,
  appId: string,
  vulnerabilityId: string,
  body: string
): Promise<void> => {
  // TODO remove once vulnerability add comment API (ADM-2014) will be ready
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return new Promise<void>(resolve => setTimeout(() => resolve(), 500));
  }

  const response = await axiosInstance.post<void>(
    `/api/v4/orgs/${orgId}/apps/${appId}/vulns/${vulnerabilityId}/comments`,
    { body }
  );
  return response.data;
};

export const deleteVulnerabilityComment = async (
  orgId: string,
  appId: string,
  vulnerabilityId: string,
  commentId: string
): Promise<void> => {
  // TODO remove once vulnerability delete comment API (ADM-2018) will be ready
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return new Promise<void>(resolve => setTimeout(() => resolve(), 500));
  }

  const response = await axiosInstance.delete<void>(
    `/api/v4/orgs/${orgId}/apps/${appId}/vulns/${vulnerabilityId}/comments/${commentId}`
  );
  return response.data;
};
