import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, BasicModel } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadAuthenticationsLookupAction } from './authentications-lookup.actions';

export interface AuthenticationsLookupState {
  readonly items: BasicModel[] | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialAuthenticationsLookupState: AuthenticationsLookupState = {
  items: null,
  pending: [],
  error: null
};

export const authenticationsLookupSlice = createSlice({
  name: 'authenticationsLookup',
  initialState: initialAuthenticationsLookupState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadAuthenticationsLookupAction.pending, (state, action) => ({
      ...state,
      items: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAuthenticationsLookupAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload
        },
        loadAuthenticationsLookupAction
      )
    );
    builder.addCase(loadAuthenticationsLookupAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAuthenticationsLookupAction
      )
    );
  }
});

export const authenticationsLookupReducer = authenticationsLookupSlice.reducer;
