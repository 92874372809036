import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, LogEntry } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { exportScanLogsAction, loadScanLogsAction } from './scan-logs.actions';

const PAGE_SIZE = 100;

export interface ScanLogsState {
  readonly items: LogEntry[];
  readonly total: number;
  readonly next?: string;
  readonly limit: number;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialScanLogsState: ScanLogsState = {
  items: [],
  total: 0,
  limit: PAGE_SIZE,
  pending: [],
  error: null
};

const scanLogsSlice = createSlice({
  name: 'scanLogs',
  initialState: initialScanLogsState,
  reducers: {
    resetScanLogs: state => ({
      ...state,
      items: [],
      next: undefined,
      total: 0
    })
  },
  extraReducers: builder => {
    builder.addCase(loadScanLogsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadScanLogsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: [...state.items, ...action.payload.items],
          total: action.payload.total,
          next: action.payload.next
        },
        loadScanLogsAction
      )
    );
    builder.addCase(loadScanLogsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadScanLogsAction
      )
    );

    builder.addCase(exportScanLogsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportScanLogsAction.fulfilled, state =>
      cleanupPendingState(state, exportScanLogsAction)
    );
    builder.addCase(exportScanLogsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportScanLogsAction
      )
    );
  }
});

export const { resetScanLogs } = scanLogsSlice.actions;
export const scanLogsReducer = scanLogsSlice.reducer;
