import { createAsyncThunk } from '@reduxjs/toolkit';
import type { BasicModel } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadAuthenticationsLookup } from '../services';

export const loadAuthenticationsLookupAction = createAsyncThunk<
  BasicModel[],
  {
    appId: string;
  },
  { state: RootState }
>(
  'authentications/loadAuthenticationsLookup',
  async ({ appId }, { rejectWithValue }) => {
    try {
      return await loadAuthenticationsLookup(appId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authenticationLookup, loadAuthenticationsLookupAction)
  }
);
