import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, EngineLogsExportStatus } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { Scan, ScanDetails } from '../models';
import { exportScanEngineLogs, loadScan, updateScanName } from '../services';

export const loadScanAction = createAsyncThunk<
  ScanDetails,
  { scanId: string },
  { state: RootState }
>(
  'scan/loadScan',
  async ({ scanId }, { rejectWithValue }) => {
    try {
      return loadScan(scanId);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().scan, loadScanAction)
  }
);

export const updateScanNameAction = createAsyncThunk<
  { scanId: string; name: string },
  {
    scanId: string;
    data: Pick<Scan, 'name'>;
  },
  { state: RootState }
>('scan/updateScanName', async ({ scanId, data }, { rejectWithValue }) => {
  try {
    await updateScanName(scanId, data);
    return { scanId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportScanEngineLogsAction = createAsyncThunk<
  { status: EngineLogsExportStatus },
  { appId: string; scanId: string },
  { state: RootState }
>('scan/exportScanEngineLogs', async ({ appId, scanId }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    return exportScanEngineLogs(orgId, appId, scanId);
  } catch (err: unknown) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
