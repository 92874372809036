import type { FC } from 'react';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import type { NavItem } from './NavListItem';
import { NavListItem } from './NavListItem';
import { NavListSectionHeader } from './NavListSectionHeader';

export interface NavSection {
  readonly title?: string;
  readonly children: NavItem[];
}

interface NavListSectionProps {
  readonly section: NavSection;
  readonly collapsed: boolean;
  readonly appId?: string;
}

export const NavListSection: FC<NavListSectionProps> = ({ section, collapsed, appId }) => {
  return (
    <Stack spacing={1}>
      {section.title && <NavListSectionHeader text={section.title} collapsed={collapsed} />}
      <List
        sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: theme => theme.spacing(0.5)
        }}
        disablePadding
      >
        {section.children.map(item => (
          <NavListItem
            key={item.text}
            item={item}
            appId={appId}
            collapsed={collapsed}
            sectionTitle={section.title}
          />
        ))}
      </List>
    </Stack>
  );
};
