import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, VulnerabilityDetails } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  clearVulnerabilityDataAction,
  loadVulnerabilityAction,
  updateVulnerabilityStatusAction
} from './vulnerability.actions';

export interface VulnerabilityState {
  readonly vulnerability: VulnerabilityDetails | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialVulnerabilityState: VulnerabilityState = {
  vulnerability: null,
  pending: [],
  error: null
};

export const vulnerabilitySlice = createSlice({
  name: 'vulnerability',
  initialState: initialVulnerabilityState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadVulnerabilityAction.pending, (state, action) => ({
      ...state,
      vulnerability: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadVulnerabilityAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          vulnerability: action.payload
        },
        loadVulnerabilityAction
      )
    );
    builder.addCase(loadVulnerabilityAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadVulnerabilityAction
      )
    );

    builder.addCase(updateVulnerabilityStatusAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateVulnerabilityStatusAction.fulfilled, state =>
      cleanupPendingState(state, updateVulnerabilityStatusAction)
    );
    builder.addCase(updateVulnerabilityStatusAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateVulnerabilityStatusAction
      )
    );

    builder.addCase(clearVulnerabilityDataAction.fulfilled, () => initialVulnerabilityState);
  }
});

export const vulnerabilityReducer = vulnerabilitySlice.reducer;
