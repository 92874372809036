import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import type { ApiError, EngineLogsExportStatus } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { Discovery, DiscoveryDetails } from '../models';
import {
  exportDiscoveryEngineLogs,
  exportDiscoveryEntrypointsAsHar,
  loadDiscovery,
  updateDiscoveryName
} from '../services';

export const loadDiscoveryAction = createAsyncThunk<
  DiscoveryDetails,
  { discoveryId: string },
  { state: RootState }
>(
  'discovery/loadDiscovery',
  async ({ discoveryId }, { rejectWithValue }) => {
    try {
      return loadDiscovery(discoveryId);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().discovery, loadDiscoveryAction)
  }
);

export const updateDiscoveryNameAction = createAsyncThunk<
  { discoveryId: string; name: string },
  {
    discoveryId: string;
    data: Pick<Discovery, 'name'>;
  },
  { state: RootState }
>('discovery/updateDiscoveryName', async ({ discoveryId, data }, { rejectWithValue }) => {
  try {
    await updateDiscoveryName(discoveryId, data);
    return { discoveryId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportDiscoveryEntrypointsAsHarAction = createAsyncThunk<
  void,
  { appId: string; discoveryId: string },
  { state: RootState }
>(
  'discovery/exportEntrypointsAsHar',
  async ({ appId, discoveryId }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const res = await exportDiscoveryEntrypointsAsHar(orgId, appId, discoveryId);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(res.headers));
      fileSaver.saveAs(res.data, name);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const exportDiscoveryEngineLogsAction = createAsyncThunk<
  { status: EngineLogsExportStatus },
  { appId: string; discoveryId: string },
  { state: RootState }
>(
  'discovery/exportDiscoveryEngineLogs',
  async ({ appId, discoveryId }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return exportDiscoveryEngineLogs(orgId, appId, discoveryId);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
