import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, BasicTestSet, TestSet } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { TestSetConfig, TestSetDetails } from '../models';
import {
  createSetAction,
  exportSetsAction,
  loadBasicSetsAction,
  loadSetAction,
  loadSetDetailsAction,
  loadSetsAction,
  runSetsBulkActionAction,
  updateSetAction,
  updateSetNameAction,
  updateSetTestsAction
} from './sets.actions';

export interface SetsState {
  readonly items: TestSet[];
  readonly setDetails: Record<string, TestSetDetails | undefined>;
  readonly setConfig: TestSetConfig | null;
  readonly basicSets: BasicTestSet[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialSetsState: SetsState = {
  items: [],
  setDetails: {},
  setConfig: null,
  basicSets: [],
  total: 0,
  pending: [],
  error: null
};

export const setsSlice = createSlice({
  name: 'sets',
  initialState: initialSetsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSetsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadSetsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadSetsAction
      )
    );
    builder.addCase(loadSetsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadSetsAction
      )
    );

    builder.addCase(loadSetDetailsAction.pending, (state, action) => ({
      ...state,
      setDetails: {
        ...state.setDetails,
        [action.meta.arg.setId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadSetDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          setDetails: {
            ...state.setDetails,
            [action.payload.id]: action.payload
          }
        },
        loadSetDetailsAction
      )
    );
    builder.addCase(loadSetDetailsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadSetDetailsAction
      )
    );

    builder.addCase(loadSetAction.pending, (state, action) => ({
      ...state,
      setConfig: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadSetAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          setConfig: action.payload
        },
        loadSetAction
      )
    );
    builder.addCase(loadSetAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadSetAction
      )
    );

    builder.addCase(loadBasicSetsAction.pending, (state, action) => ({
      ...state,
      basicSets: [],
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBasicSetsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          basicSets: action.payload
        },
        loadBasicSetsAction
      )
    );
    builder.addCase(loadBasicSetsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBasicSetsAction
      )
    );

    builder.addCase(updateSetNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateSetNameAction.fulfilled, state =>
      cleanupPendingState(state, updateSetNameAction)
    );
    builder.addCase(updateSetNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateSetNameAction
      )
    );

    builder.addCase(updateSetTestsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateSetTestsAction.fulfilled, state =>
      cleanupPendingState(state, updateSetTestsAction)
    );
    builder.addCase(updateSetTestsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateSetTestsAction
      )
    );

    builder.addCase(runSetsBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runSetsBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runSetsBulkActionAction)
    );
    builder.addCase(runSetsBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runSetsBulkActionAction
      )
    );

    builder.addCase(exportSetsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportSetsAction.fulfilled, state =>
      cleanupPendingState(state, exportSetsAction)
    );
    builder.addCase(exportSetsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportSetsAction
      )
    );

    builder.addCase(createSetAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createSetAction.fulfilled, state =>
      cleanupPendingState(state, createSetAction)
    );
    builder.addCase(createSetAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createSetAction
      )
    );

    builder.addCase(updateSetAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateSetAction.fulfilled, state =>
      cleanupPendingState(state, updateSetAction)
    );
    builder.addCase(updateSetAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateSetAction
      )
    );
  }
});

export const setsReducer = setsSlice.reducer;
