import type { RouteObject } from 'react-router-dom';

export const authenticationPoolsRoutes: RouteObject[] = [
  {
    path: 'pools',
    async lazy() {
      const { AuthenticationPoolsPage } = await import('./pages');
      return { Component: AuthenticationPoolsPage };
    }
  }
];
