import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Group } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { exportGroupsAction, loadAllGroupsAction, loadGroupsAction } from './groups.actions';

export interface GroupsState {
  readonly items: Group[];
  readonly all: Group[];
  readonly total: number;
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
}

const initialGroupsState: GroupsState = {
  items: [],
  all: [],
  total: 0,
  pending: [],
  error: null
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState: initialGroupsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadGroupsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    }));
    builder.addCase(loadGroupsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadGroupsAction
      )
    );
    builder.addCase(loadGroupsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadGroupsAction
      )
    );

    builder.addCase(loadAllGroupsAction.pending, (state, action) => ({
      ...state,
      all: [],
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAllGroupsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          all: action.payload
        },
        loadAllGroupsAction
      )
    );
    builder.addCase(loadAllGroupsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAllGroupsAction
      )
    );

    builder.addCase(exportGroupsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportGroupsAction.fulfilled, state =>
      cleanupPendingState(state, exportGroupsAction)
    );
    builder.addCase(exportGroupsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportGroupsAction
      )
    );
  }
});

export const groupsReducer = groupsSlice.reducer;
