import type { FC } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import type { Application } from '@bright/api';
import { AppHomeLink, CustomTooltip } from '@bright/share';
import { AddApplicationButton } from './AddApplicationButton';
import { ApplicationMenu } from './ApplicationMenu';
import { HeaderIconButton } from './HeaderIconButton';
import { IconHelpMenu } from './IconHelpMenu';
import { UserProfileMenu } from './UserProfileMenu/UserProfileMenu';

export const HEADER_HEIGHT = 72;

export const AppHeader: FC<{
  readonly appId?: string;
  readonly apps: Application[];
  readonly opened: boolean;
  readonly onToggleSidebar: () => void;
}> = ({ appId, apps, opened, onToggleSidebar }) => {
  const { t } = useTranslation();

  return (
    <AppBar
      sx={{
        height: HEADER_HEIGHT,
        boxShadow: 'none',
        backgroundColor: theme => theme.palette.dark.main
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 1,
          py: 1
        }}
      >
        <Stack spacing={0.5} alignItems="center" direction="row" sx={{ pl: 3, pr: 8.75 }}>
          <CustomTooltip
            text={opened ? t('sidebar.collapse') : t('sidebar.expand')}
            variant="light"
          >
            <HeaderIconButton onClick={onToggleSidebar}>
              <MenuIcon />
            </HeaderIconButton>
          </CustomTooltip>
          <AppHomeLink />
        </Stack>
        {appId ? <ApplicationMenu apps={apps} appId={appId} /> : <AddApplicationButton />}
        <Stack direction="row" justifyContent="end" alignItems="center" flexGrow={1}>
          <IconHelpMenu />
        </Stack>

        <Box sx={{ height: 1, mx: 2, py: 1 }}>
          <Divider
            orientation="vertical"
            sx={{ height: 1, borderColor: theme => theme.palette.custom.header.divider }}
          />
        </Box>

        <Box sx={{ mr: 2 }}>
          <UserProfileMenu />
        </Box>
      </Box>
    </AppBar>
  );
};
