import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type {
  ApiError,
  Application,
  ApplicationProperties,
  ExportFormat,
  MemberSettings
} from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders,
  toLongId
} from '@bright/core';
import type { RootState } from '@bright/core';
import {
  destroyAppIdSession,
  exportApplications,
  loadApplications,
  loadProperties,
  setSessionAppId,
  updateMemberSettings,
  updateProperties
} from '../service';

export const loadApplicationsAction = createAsyncThunk<
  Application[] | undefined,
  void,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'applications/loadApplications',
  async (_, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return (await loadApplications(orgId))?.items;
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().applications, loadApplicationsAction)
  }
);

export const updateMemberSettingsAction = createAsyncThunk<
  void,
  MemberSettings,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('applications/updateMemberSettings', async (settings, { rejectWithValue }) => {
  try {
    return await updateMemberSettings(settings);
  } catch (error) {
    return rejectWithValue(error as ApiError);
  }
});

export const setSessionAppIdAction = createAsyncThunk<
  void,
  string | null,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('applications/setSessionAppId', appId => {
  if (!appId) {
    return destroyAppIdSession();
  }
  return setSessionAppId(appId);
});

export const loadPropertiesAction = createAsyncThunk<
  ApplicationProperties,
  {
    appId: string;
  },
  { state: RootState }
>(
  'applications/loadProperties',
  async ({ appId }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadProperties(orgId, appId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().applications, loadPropertiesAction)
  }
);

export const updatePropertiesAction = createAsyncThunk<
  void,
  {
    appId: string;
    data: Omit<ApplicationProperties, 'default'>;
  },
  { state: RootState }
>(
  'applications/updateProperties',
  async ({ appId, data }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await updateProperties(orgId, appId, data);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().applications, updatePropertiesAction)
  }
);

export const exportApplicationsAction = createAsyncThunk<
  void,
  {
    format: ExportFormat;
  },
  { state: RootState }
>('applications/exportApplicationsAction', async ({ format }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    const response = await exportApplications(toLongId(orgId), format);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
