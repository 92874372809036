import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, Group, PaginationResponse } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { GroupsBulkAction } from '../models';
import {
  exportGroups,
  loadAllGroups,
  loadGroups,
  runGroupsBulkAction,
  updateGroupName
} from '../services';

export const loadGroupsAction = createAsyncThunk<
  PaginationResponse<Group>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'groups/loadGroups',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await loadGroups(orgId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().groups, loadGroupsAction)
  }
);

export const loadAllGroupsAction = createAsyncThunk<Group[], void, { state: RootState }>(
  'groups/loadAllGroups',
  async (_, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await loadAllGroups(orgId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().groups, loadAllGroupsAction)
  }
);

export const updateGroupNameAction = createAsyncThunk<
  { groupId: string; name: string },
  {
    groupId: string;
    data: Pick<Group, 'name'>;
  },
  { state: RootState }
>('groups/updateGroupName', async ({ groupId, data }, { rejectWithValue }) => {
  try {
    await updateGroupName(groupId, data);
    return { groupId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const runGroupsBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: GroupsBulkAction;
  },
  { state: RootState }
>('groups/runGroupsBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runGroupsBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportGroupsAction = createAsyncThunk<
  void,
  {
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('groups/exportGroupsAction', async ({ ids, format }, { rejectWithValue }) => {
  try {
    const response = await exportGroups(format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
