import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { ScanDetails } from '../models';
import { exportScanEngineLogsAction, loadScanAction, updateScanNameAction } from './scan.actions';

export interface ScanState {
  readonly scan: ScanDetails | null;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialAuthState: ScanState = {
  scan: null,
  pending: [],
  error: null
};

const scanSlice = createSlice({
  name: 'scan',
  initialState: initialAuthState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadScanAction.pending, (state, action) => ({
      ...state,
      scan: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadScanAction.fulfilled, (state, action) =>
      cleanupPendingState({ ...state, scan: action.payload }, loadScanAction)
    );
    builder.addCase(loadScanAction.rejected, state => cleanupPendingState(state, loadScanAction));

    builder.addCase(updateScanNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateScanNameAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          scan:
            state.scan?.id === action.payload.scanId
              ? {
                  ...state.scan,
                  name: action.payload.name
                }
              : state.scan
        },
        updateScanNameAction
      )
    );
    builder.addCase(updateScanNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateScanNameAction
      )
    );

    builder.addCase(exportScanEngineLogsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportScanEngineLogsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          scan: { ...state.scan!, engineLogsExportStatus: action.payload.status }
        },
        exportScanEngineLogsAction
      )
    );
    builder.addCase(exportScanEngineLogsAction.rejected, state =>
      cleanupPendingState(state, exportScanEngineLogsAction)
    );
  }
});

export const scanReducer = scanSlice.reducer;
