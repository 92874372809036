export enum EntrypointConnectivityStatus {
  OK = 'ok',
  PROBLEM = 'problem',
  SKIPPED = 'skipped',
  UNAUTHORIZED = 'unauthorized',
  UNREACHABLE = 'unreachable'
}

export const connectivityStatusSortPriority: Record<EntrypointConnectivityStatus, number> = {
  [EntrypointConnectivityStatus.OK]: 0,
  [EntrypointConnectivityStatus.PROBLEM]: 1,
  [EntrypointConnectivityStatus.UNAUTHORIZED]: 2,
  [EntrypointConnectivityStatus.UNREACHABLE]: 3,
  [EntrypointConnectivityStatus.SKIPPED]: 4
};
