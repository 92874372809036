import type { RouteObject } from 'react-router-dom';
import { ipRestrictionsRoutes } from '@bright/ip-restrictions';

export const securityRoutes: RouteObject[] = [
  {
    path: 'security',
    async lazy() {
      const { SecurityPage } = await import('./pages');
      return { Component: SecurityPage };
    },
    children: [...ipRestrictionsRoutes]
  }
];
