import type { CreatedByType, PaginationResponse } from '@bright/api';
import { axiosInstance } from '@bright/core';
// eslint-disable-next-line import/extensions
import auditLogEntriesMockResponse from '../../../mocks/audit-log-entries.json';
import type { AuditLogEntry, AuditLogEntryTarget } from '../models';

export const loadAuditLogEntries = async (
  orgId: string,
  queryString: string
): Promise<PaginationResponse<AuditLogEntry>> => {
  // TODO remove once get audit log API (ADM-1969) will be ready
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve({
          items: auditLogEntriesMockResponse
            .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
            .map(entry => ({
              ...entry,
              target: entry.target as AuditLogEntryTarget,
              createdBy: {
                ...entry.createdBy,
                type: entry.createdBy.type as CreatedByType
              },
              secondaryTarget: entry.secondaryTarget as AuditLogEntryTarget
            })),
          total: auditLogEntriesMockResponse.length
        });
      }, 500)
    );
  }

  const response = await axiosInstance.get<PaginationResponse<AuditLogEntry>>(
    `/api/v4/orgs/${orgId}/audit-log?${queryString}`
  );
  return response.data;
};
