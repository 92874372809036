import type { RouteObject } from 'react-router-dom';

export const authRoutes: RouteObject[] = [
  {
    async lazy() {
      const { AuthLayout } = await import('./pages');
      return { Component: AuthLayout };
    },
    children: [
      {
        path: '/login',
        async lazy() {
          const { LoginPage } = await import('./pages');
          return { Component: LoginPage };
        }
      }
    ]
  }
];
