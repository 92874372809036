import type { RouteObject } from 'react-router-dom';
import { redirect } from 'react-router-dom';
import { authenticationPoolsRoutes } from '@bright/authentication-pools';

export const authenticationsRoutes: RouteObject[] = [
  {
    path: 'authentications',
    async lazy() {
      const { AuthenticationLayout } = await import('./pages');
      return { Component: AuthenticationLayout };
    },
    children: [
      {
        index: true,
        loader: async () => redirect('./objects')
      },
      {
        path: 'objects',
        async lazy() {
          const { AuthenticationsPage } = await import('./pages');
          return { Component: AuthenticationsPage };
        },
        children: [
          {
            path: 'new',
            async lazy() {
              const { CreateAuthenticationPage } = await import('./pages');
              return { Component: CreateAuthenticationPage };
            },
            children: [
              {
                path: 'recording',
                async lazy() {
                  const { EditAuthenticationRecordingPage } = await import('./pages');
                  return { Component: EditAuthenticationRecordingPage };
                }
              },
              {
                path: 'recorder',
                async lazy() {
                  const { CreateAuthenticationRecordingPage } = await import('./pages');
                  return { Component: CreateAuthenticationRecordingPage };
                }
              }
            ]
          },
          {
            path: ':authenticationId',
            async lazy() {
              const { EditAuthenticationPage } = await import('./pages');
              return { Component: EditAuthenticationPage };
            },
            children: [
              {
                path: 'recording',
                async lazy() {
                  const { EditAuthenticationRecordingPage } = await import('./pages');
                  return { Component: EditAuthenticationRecordingPage };
                }
              },
              {
                path: 'recorder',
                async lazy() {
                  const { CreateAuthenticationRecordingPage } = await import('./pages');
                  return { Component: CreateAuthenticationRecordingPage };
                }
              }
            ]
          }
        ]
      },
      ...authenticationPoolsRoutes
    ]
  }
];
