import type { RouteObject } from 'react-router-dom';

export const sitemapRoutes: RouteObject[] = [
  {
    path: 'sitemap',
    async lazy() {
      const { SitemapPage } = await import('./pages');
      return { Component: SitemapPage };
    }
  }
];
