import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, PaginationResponse, UserListItem } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { UsersBulkAction } from '../models';
import {
  exportUsers,
  loadAllUsers,
  loadUsers,
  resetUserPassword,
  runUsersBulkAction
} from '../services';

export const loadUsersAction = createAsyncThunk<
  PaginationResponse<UserListItem>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'users/loadUsers',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await loadUsers(orgId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().users, loadUsersAction)
  }
);

export const loadAllUsersAction = createAsyncThunk<UserListItem[], void, { state: RootState }>(
  'users/loadAllUsers',
  async (_, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await loadAllUsers(orgId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().users, loadAllUsersAction)
  }
);

export const runUsersBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: UsersBulkAction;
  },
  { state: RootState }
>('users/runUsersBulkAction', async ({ ids, action }, { getState, rejectWithValue }) => {
  const orgId = selectOwnOrganizationId(getState());
  try {
    return await runUsersBulkAction(orgId, ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportUsersAction = createAsyncThunk<
  void,
  {
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('users/exportUsersAction', async ({ ids, format }, { rejectWithValue }) => {
  try {
    const response = await exportUsers(format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const resetUserPasswordAction = createAsyncThunk<
  void,
  {
    userId: string;
    password: string;
  },
  { state: RootState }
>('users/resetUserPasswordAction', async ({ userId, password }, { getState, rejectWithValue }) => {
  const orgId = selectOwnOrganizationId(getState());
  try {
    return await resetUserPassword(orgId, userId, { password });
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
