import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { Authentication, AuthenticationDetails } from '../models';
import {
  exportAuthenticationsAction,
  loadAuthenticationDetailsAction,
  loadAuthenticationsAction,
  runAuthenticationsBulkActionAction,
  updateAuthenticationNameAction
} from './authentications.actions';

export interface AuthenticationsState {
  readonly items: Authentication[];
  readonly authenticationDetails: Record<string, AuthenticationDetails | undefined>;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialAuthenticationsState: AuthenticationsState = {
  items: [],
  authenticationDetails: {},
  total: 0,
  pending: [],
  error: null
};

export const authenticationsSlice = createSlice({
  name: 'authentications',
  initialState: initialAuthenticationsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadAuthenticationsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAuthenticationsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadAuthenticationsAction
      )
    );
    builder.addCase(loadAuthenticationsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAuthenticationsAction
      )
    );

    builder.addCase(runAuthenticationsBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runAuthenticationsBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runAuthenticationsBulkActionAction)
    );
    builder.addCase(
      runAuthenticationsBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runAuthenticationsBulkActionAction
        )
    );

    builder.addCase(exportAuthenticationsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportAuthenticationsAction.fulfilled, state =>
      cleanupPendingState(state, exportAuthenticationsAction)
    );
    builder.addCase(exportAuthenticationsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportAuthenticationsAction
      )
    );

    builder.addCase(loadAuthenticationDetailsAction.pending, (state, action) => ({
      ...state,
      authenticationDetails: {
        ...state.authenticationDetails,
        [action.meta.arg.authenticationId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAuthenticationDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          authenticationDetails: {
            ...state.authenticationDetails,
            [action.meta.arg.authenticationId]: action.payload
          }
        },
        loadAuthenticationDetailsAction
      )
    );
    builder.addCase(loadAuthenticationDetailsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAuthenticationDetailsAction
      )
    );

    builder.addCase(updateAuthenticationNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateAuthenticationNameAction.fulfilled, state =>
      cleanupPendingState(state, updateAuthenticationNameAction)
    );
    builder.addCase(updateAuthenticationNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateAuthenticationNameAction
      )
    );
  }
});

export const authenticationsReducer = authenticationsSlice.reducer;
