import type { AxiosError, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import type { ApiError } from '@bright/api';
import { ApiErrorType, CustomApiErrorCode, isApiError } from '@bright/api';
import { axiosInstance } from '@bright/core';
import { REFRESH_TOKEN_ERROR_EXCLUDED_PATHS } from '../../models';
import { RequestQueueService } from '../request-queue.service';
import { TokenRefreshService } from '../token-refresh.service';
import { addAuthenticationToken, extractPathname } from './interceptor-helpers';

const UNKNOWN_ERROR_TITLE = 'An unknown error occurred';
const SESSION_EXPIRED_TITLE = 'Session expired, please log in again';

export const handleAxiosErrorInterceptor = async (error: unknown) => {
  if (!axios.isAxiosError(error) || !error.config) {
    return Promise.reject({
      type: ApiErrorType.UNKNOWN,
      title: (error as Error).message ?? UNKNOWN_ERROR_TITLE
    } as ApiError);
  }

  // Handle 401 error
  if (error.response?.status === 401 && shouldHandle401Error(error.config.url)) {
    const originalRequestConfig: InternalAxiosRequestConfig = error.config;

    if (TokenRefreshService.isRefreshing()) {
      return new Promise(resolve => {
        RequestQueueService.addRequestCallback(newAccessToken => {
          resolve(axiosInstance(addAuthenticationToken(originalRequestConfig, newAccessToken)));
        });
      });
    }

    try {
      const newAccessToken = await TokenRefreshService.getNewAccessToken();
      return axiosInstance(addAuthenticationToken(originalRequestConfig, newAccessToken));
    } catch (refreshError) {
      return Promise.reject({
        type: ApiErrorType.SESSION_EXPIRED,
        title: SESSION_EXPIRED_TITLE
      } as ApiError);
    }
  }

  return Promise.reject(mapToApiError(error));
};

const shouldHandle401Error = (url: string | undefined): boolean => {
  if (!url) {
    return true;
  }
  const pathname = extractPathname(url);

  return !REFRESH_TOKEN_ERROR_EXCLUDED_PATHS.some(pattern => pattern.test(pathname));
};

const mapToApiError = (error: AxiosError): ApiError => {
  if (isApiError(error.response?.data)) {
    return error.response.data;
  }

  if (typeof error.response?.data === 'string') {
    try {
      const parsedData = JSON.parse(error.response.data);
      if (isApiError(parsedData)) {
        return parsedData;
      }
    } catch {
      if (error.response?.status === 400) {
        return { type: ApiErrorType.VALIDATION, title: error.response.data };
      }
      return { type: ApiErrorType.UNKNOWN, title: error.response.data };
    }
  } else if (typeof error.response?.data === 'object') {
    if (Object.values(CustomApiErrorCode).includes(error.response.data?.['code'])) {
      return {
        type: ApiErrorType.VALIDATION,
        code: error.response.data?.['code'],
        title: error.response.data?.['message'] || error.message
      };
    }
  }

  return { type: ApiErrorType.UNKNOWN, title: error.message || UNKNOWN_ERROR_TITLE };
};
