import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { ScanEntrypoint, ScanEntrypointTest } from '../models';
import {
  loadScanEntrypointsAction,
  loadScanEntrypointTestsAction
} from './scan-entrypoints.actions';

export interface ScanEntrypointsState {
  readonly items: ScanEntrypoint[];
  readonly entrypointTests: Record<string, ScanEntrypointTest[]>;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialScanEntrypointsState: ScanEntrypointsState = {
  items: [],
  entrypointTests: {},
  total: 0,
  pending: [],
  error: null
};

const scanEntrypointsSlice = createSlice({
  name: 'scanEntrypoints',
  initialState: initialScanEntrypointsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadScanEntrypointsAction.pending, (state, action) => ({
      ...state,
      items: [],
      total: 0,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadScanEntrypointsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadScanEntrypointsAction
      )
    );
    builder.addCase(loadScanEntrypointsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadScanEntrypointsAction
      )
    );

    builder.addCase(loadScanEntrypointTestsAction.pending, (state, action) => ({
      ...state,
      entrypointTests: {
        ...state.entrypointTests,
        [action.meta.arg.entrypointId]: []
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadScanEntrypointTestsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          entrypointTests: {
            ...state.entrypointTests,
            [action.meta.arg.entrypointId]: action.payload
          }
        },
        loadScanEntrypointTestsAction
      )
    );
    builder.addCase(loadScanEntrypointTestsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadScanEntrypointTestsAction
      )
    );
  }
});

export const scanEntrypointsReducer = scanEntrypointsSlice.reducer;
