import type { FC, ReactNode } from 'react';
import ListItem from '@mui/material/ListItem';
import type { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { NavLink, useMatch } from 'react-router-dom';
import { CustomTooltip } from '@bright/share';

interface StyledListItemButtonProps extends ListItemButtonProps {
  to: string;
}

export interface NavItem {
  readonly text: string;
  readonly icon: ReactNode;
  readonly iconActive: ReactNode;
  readonly to: string;
  readonly global?: boolean;
}

const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(({ theme }) => ({
  height: theme.spacing(4),
  padding: theme.spacing(0.75, 1.5),
  overflow: 'hidden',
  background: 'none',
  borderRadius: theme.spacing(2),
  color: theme.palette.custom.sidebar.listItem.fg,
  [`.${listItemIconClasses.root}`]: {
    color: theme.palette.custom.sidebar.listItem.fg
  },
  [`&.active, &.active:hover, &.active.${listItemButtonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.custom.sidebar.listItem.selected.bg,
    color: theme.palette.custom.sidebar.listItem.selected.fg,

    [`.${listItemIconClasses.root}`]: {
      color: theme.palette.custom.sidebar.listItem.selected.fg
    }
  },
  '&:not(.active):hover': {
    backgroundColor: theme.palette.custom.sidebar.listItem.hover.bg,
    color: theme.palette.custom.sidebar.listItem.hover.fg,
    [`.${listItemIconClasses.root}`]: {
      color: theme.palette.custom.sidebar.listItem.hover.fg
    }
  },
  [`&:not(.active).${listItemButtonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.custom.sidebar.listItem.focus.bg,
    color: theme.palette.custom.sidebar.listItem.focus.fg,
    [`.${listItemIconClasses.root}`]: {
      color: theme.palette.custom.sidebar.listItem.focus.fg
    }
  }
}));

export const NavListItem: FC<{
  readonly appId: string | undefined;
  readonly item: NavItem;
  readonly collapsed: boolean;
  readonly sectionTitle?: string;
}> = ({ appId, item, collapsed, sectionTitle }) => {
  const target = item.to.startsWith('/') ? item.to : `/app/${appId ? `${appId}/` : ''}${item.to}`;
  const match = useMatch({ path: `${target}/*`, end: false });

  return (
    <CustomTooltip
      text={collapsed ? item.text : null}
      withHeadline
      headline={sectionTitle?.toUpperCase()}
      variant="light"
      placement="right"
    >
      <ListItem disablePadding sx={{ minHeight: 32 }}>
        <StyledListItemButton component={NavLink} to={target}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            {match !== null ? item.iconActive : item.icon}
          </ListItemIcon>
          {collapsed ? null : (
            <ListItemText
              sx={{ ml: 2, my: 0 }}
              primaryTypographyProps={{
                color: 'inherit',
                fontWeight: 500,
                lineHeight: theme => theme.spacing(2.5)
              }}
            >
              {item.text}
            </ListItemText>
          )}
        </StyledListItemButton>
      </ListItem>
    </CustomTooltip>
  );
};
