import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { GroupDetails } from '../models';
import { createGroupAction, loadGroupAction, updateGroupAction } from './group.actions';

export interface GroupState {
  readonly group: GroupDetails | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialGroupState: GroupState = {
  group: null,
  pending: [],
  error: null
};

export const groupSlice = createSlice({
  name: 'group',
  initialState: initialGroupState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadGroupAction.pending, (state, action) => ({
      ...state,
      group: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadGroupAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          group: action.payload
        },
        loadGroupAction
      )
    );
    builder.addCase(loadGroupAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadGroupAction
      )
    );

    builder.addCase(updateGroupAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateGroupAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateGroupAction)
    );
    builder.addCase(updateGroupAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateGroupAction
      )
    );

    builder.addCase(createGroupAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createGroupAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createGroupAction)
    );
    builder.addCase(createGroupAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createGroupAction
      )
    );
  }
});

export const groupReducer = groupSlice.reducer;
