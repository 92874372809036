import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ID, Target, TargetHost } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManageTargetPayload } from '../models';
import { createTarget, loadCrawlerTargets, loadTarget, updateTarget } from '../services';

export const loadTargetAction = createAsyncThunk<
  Target,
  { targetId: string },
  { state: RootState }
>(
  'targets/loadTarget',
  async ({ targetId }, { rejectWithValue }) => {
    try {
      return await loadTarget(targetId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().target, loadTargetAction)
  }
);

export const updateTargetAction = createAsyncThunk<
  void,
  {
    targetId: string;
    payload: ManageTargetPayload;
  },
  { state: RootState }
>(
  'targets/updateTarget',
  async ({ targetId, payload }, { rejectWithValue }) => {
    try {
      return await updateTarget(targetId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().target, updateTargetAction)
  }
);

export const createTargetAction = createAsyncThunk<
  ID,
  {
    appId: string;
    payload: ManageTargetPayload;
  },
  { state: RootState }
>(
  'targets/createTarget',
  async ({ appId, payload }, { rejectWithValue }) => {
    try {
      return await createTarget(appId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().target, createTargetAction)
  }
);

export const verifyCrawlerTargetAction = createAsyncThunk<
  TargetHost[],
  {
    url: string;
    bridgeId?: string;
  },
  { state: RootState }
>('targets/verifyCrawlerTarget', async ({ url, bridgeId }, { rejectWithValue, signal }) => {
  try {
    return await loadCrawlerTargets({ urls: [url], bridgeId }, signal);
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});
