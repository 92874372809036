import type { NavigationPermissions, NavigationPermissionSummary } from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';

export const loadNavigationPermissions = async (
  orgId: string,
  queryString: string = ''
): Promise<NavigationPermissions> => {
  // TODO: add infinite scroll in ADM-3613
  const url = `/rest/v1/orgs/${orgId}/permissions/navigation`;
  const items = await fetchAllPages<NavigationPermissionSummary>(`${url}?${queryString}`);
  const orgPermissions = (await axiosInstance.get<NavigationPermissions>(url)).data;

  return {
    items,
    organization: orgPermissions?.organization,
    total: items.length
  };
};
