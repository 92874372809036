import type { AuthenticationCredentials, LoggedInUser, LogoutResponse, Token } from '@bright/api';
import { axiosInstance } from '@bright/core';

export const login = async (data: AuthenticationCredentials): Promise<Token> => {
  const response = await axiosInstance.post<Token>('/api/v1/auth/login', data);
  return response.data;
};

export const refreshToken = async (): Promise<Token> => {
  const response = await axiosInstance.post<Token>('/api/v1/auth/token', {});
  return response.data;
};

export const logout = async (): Promise<LogoutResponse> => {
  const response = await axiosInstance.post<LogoutResponse>('/api/v1/auth/logout', {});
  return response.data;
};

export const loadUserInfo = async (): Promise<LoggedInUser> => {
  const response = await axiosInstance.get<LoggedInUser>('/api/v4/me');
  return response.data;
};
