export enum OtpType {
  TOTP = 'totp',
  HOTP = 'hotp',
  AUTHENTICATOR = 'authenticator',
  EMAIL = 'email'
}

export enum OtpHashAlgorithm {
  SHA1 = 'sha1',
  SHA256 = 'sha256',
  SHA512 = 'sha512'
}

export interface BaseOtpConfig {
  name: string;
  type: OtpType;
}

export interface HotpConfig extends BaseOtpConfig {
  type: OtpType.HOTP;
  secret: string;
  digits: number;
  algorithm: OtpHashAlgorithm;
}

export interface TotpConfig extends BaseOtpConfig {
  type: OtpType.TOTP;
  secret: string;
  digits: number;
  algorithm: OtpHashAlgorithm;
  step: number;
}

export interface AuthenticatorConfig extends BaseOtpConfig {
  type: OtpType.AUTHENTICATOR;
  secret: string;
  digits: number;
  algorithm: OtpHashAlgorithm;
  step: number;
}

export interface EmailOtpConfig extends BaseOtpConfig {
  type: OtpType.EMAIL;
  subjectPattern: string;
  bodyPattern: string;
  email?: string;
}

export type AuthenticationOtpConfig =
  | HotpConfig
  | TotpConfig
  | AuthenticatorConfig
  | EmailOtpConfig;

export const SORTED_OTP_TYPES = [OtpType.TOTP, OtpType.HOTP, OtpType.AUTHENTICATOR, OtpType.EMAIL];

export const SORTED_OTP_HASH_ALGORITHMS = [
  OtpHashAlgorithm.SHA1,
  OtpHashAlgorithm.SHA256,
  OtpHashAlgorithm.SHA512
];

export interface OtpToken {
  otp: string;
  keyUri: string;
}
