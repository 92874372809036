import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type {
  AuthenticationDetailsOptionalId,
  AuthenticationOtpConfig,
  AuthenticationTestResult,
  OtpToken
} from '../models';
import { testAuthentication, testAuthenticationOtp } from '../services';

export const testAuthenticationAction = createAsyncThunk<
  AuthenticationTestResult[],
  {
    appId: string;
    payload: AuthenticationDetailsOptionalId;
  },
  { state: RootState }
>(
  'authentications/testAuthentication',
  async ({ appId, payload }, { rejectWithValue }) => {
    try {
      return await testAuthentication(appId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authenticationTests, testAuthenticationAction)
  }
);

export const clearCreateAuthenticationTestResultAction = createAction(
  'authentications/clearCreateAuthenticationTestResultAction'
);

export const testAuthenticationOtpAction = createAsyncThunk<
  OtpToken,
  {
    appId: string;
    payload: AuthenticationOtpConfig;
  },
  { state: RootState; rejectValue: ApiError }
>(
  'authentications/testAuthenticationOtp',
  async ({ appId, payload }, { rejectWithValue }) => {
    try {
      return await testAuthenticationOtp(appId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authenticationTests, testAuthenticationOtpAction)
  }
);
