import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { Library, LibraryPossibleVulnerabilities } from '../models';
import { exportLibraries, loadLibraries, loadLibraryPossibleVulnerabilities } from '../services';

export const loadLibrariesAction = createAsyncThunk<
  PaginationResponse<Library>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'libraries/loadLibraries',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadLibraries(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().libraries, loadLibrariesAction)
  }
);

export const loadLibraryPossibleVulnerabilitiesAction = createAsyncThunk<
  LibraryPossibleVulnerabilities,
  {
    appId: string;
    libraryId: string;
  },
  { state: RootState }
>(
  'libraries/loadLibraryPossibleVulnerabilities',
  async ({ appId, libraryId }, { rejectWithValue }) => {
    try {
      return await loadLibraryPossibleVulnerabilities(appId, libraryId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ libraryId }, { getState }) =>
      actionNotPending(
        getState().libraries,
        action =>
          action.type === loadLibraryPossibleVulnerabilitiesAction.pending.type &&
          (action as ReturnType<typeof loadLibraryPossibleVulnerabilitiesAction.pending>).meta.arg
            .libraryId === libraryId
      )
  }
);

export const exportLibrariesAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>(
  'libraries/exportLibrariesAction',
  async ({ appId, ids, format }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const response = await exportLibraries(orgId, appId, format, ids);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
      fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
