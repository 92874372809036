import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, BasicBridgesScript, BridgesScript } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportBridgesScriptsAction,
  loadBasicBridgesScriptsAction,
  loadBridgesScriptsAction,
  runBridgesScriptsBulkActionAction,
  updateBridgesScriptNameAction
} from './bridges-scripts.actions';

export interface BridgesScriptsState {
  readonly items: BridgesScript[];
  readonly total: number;
  readonly basicScripts: BasicBridgesScript[];
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
}

const initialBridgesScriptsState: BridgesScriptsState = {
  items: [],
  basicScripts: [],
  total: 0,
  pending: [],
  error: null
};

export const bridgesScriptsSlice = createSlice({
  name: 'bridgesScripts',
  initialState: initialBridgesScriptsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadBridgesScriptsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBridgesScriptsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadBridgesScriptsAction
      )
    );
    builder.addCase(loadBridgesScriptsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBridgesScriptsAction
      )
    );

    builder.addCase(updateBridgesScriptNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateBridgesScriptNameAction.fulfilled, state =>
      cleanupPendingState(state, updateBridgesScriptNameAction)
    );
    builder.addCase(updateBridgesScriptNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateBridgesScriptNameAction
      )
    );

    builder.addCase(loadBasicBridgesScriptsAction.pending, (state, action) => ({
      ...state,
      basicScripts: [],
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBasicBridgesScriptsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          basicScripts: action.payload
        },
        loadBasicBridgesScriptsAction
      )
    );
    builder.addCase(loadBasicBridgesScriptsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBasicBridgesScriptsAction
      )
    );

    builder.addCase(runBridgesScriptsBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runBridgesScriptsBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runBridgesScriptsBulkActionAction)
    );
    builder.addCase(
      runBridgesScriptsBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runBridgesScriptsBulkActionAction
        )
    );

    builder.addCase(exportBridgesScriptsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportBridgesScriptsAction.fulfilled, state =>
      cleanupPendingState(state, exportBridgesScriptsAction)
    );
    builder.addCase(exportBridgesScriptsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportBridgesScriptsAction
      )
    );
  }
});

export const bridgesScriptsReducer = bridgesScriptsSlice.reducer;
