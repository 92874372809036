import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const TextWithEllipsis = styled(Box)<{ clamp?: number } & BoxProps>(({ clamp }) => ({
  textOverflow: 'ellipsis',
  textAlign: 'left',
  overflow: 'hidden',
  width: '100%',
  ...(clamp
    ? {
        whiteSpace: 'normal',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: clamp
      }
    : {
        whiteSpace: 'nowrap'
      })
}));
