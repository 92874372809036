import { Navigate, Outlet, useParams } from 'react-router-dom';
import { selectApplications, selectApplicationsPendingStatus } from '@bright/applications/store';
import { useAppSelector } from '@bright/core';
import { Page } from '@bright/share';

export const ApplicationsGuard = () => {
  const { appId } = useParams<{ appId: string }>();
  const loading = useAppSelector(selectApplicationsPendingStatus);

  const applications = useAppSelector(selectApplications);
  const hasAccess = applications?.some(app => app.id === appId);

  if (loading) {
    return <Page title={'...'} pending={true} />;
  }

  if (hasAccess) {
    return <Outlet />;
  }

  return <Navigate to={`/`} replace />;
};
