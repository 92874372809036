import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { loadScanLogsAction } from './scan-logs.actions';

const selectScanLogsStore = (state: RootState) => state.scanLogs;

export const selectScanLogsItems = createSelector(selectScanLogsStore, state => state.items);

export const selectLoadScanLogsPendingStatus = createSelector(selectScanLogsStore, state =>
  state.pending.some(action => action.type.startsWith(loadScanLogsAction.typePrefix))
);

export const selectScanLogsNextPageAvailable = createSelector(selectScanLogsStore, state =>
  Boolean(state.next)
);

export const selectScanLogsNextPageParams = createSelector(selectScanLogsStore, state => ({
  next: state.next,
  limit: state.limit
}));
