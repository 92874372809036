import { createSvgIcon } from '@mui/material/utils';

export const ArrowBackIcon = createSvgIcon(
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.198 15.4478L24.6431 15.4478C26.9401 15.4478 28.8022 17.3099 28.8022 19.6068L28.8022 21.1325C28.8022 23.4294 26.9401 25.2915 24.6431 25.2915L10.2084 25.2915"
      stroke="currentcolor"
      strokeWidth="2.08134"
      strokeLinejoin="round"
    />
    <path
      d="M9.84375 11.0728C8.13521 12.7813 7.17729 13.7392 5.46875 15.4478L9.84375 19.8228"
      stroke="currentcolor"
      strokeWidth="2.08134"
      strokeLinejoin="round"
    />
  </svg>,
  'ArrowBackIcon'
);
