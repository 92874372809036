import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AuthenticationCredentials, LoggedInUser } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending, RouterNavigationService } from '@bright/core';
import {
  loadUserInfo,
  login,
  logout,
  TokenStorageService,
  UserSessionStorageService
} from '../services';

export const loginAction = createAsyncThunk<void, AuthenticationCredentials>(
  'auth/login',
  async data => {
    const token = await login(data);
    UserSessionStorageService.setSession();
    TokenStorageService.setAccessToken(token);
  }
);

export const logoutAction = createAsyncThunk<void, void, { state: RootState }>(
  'auth/logout',
  async () => {
    await logout();

    UserSessionStorageService.clearSession();
    TokenStorageService.clear();
  }
);

export const loadUserInfoAction = createAsyncThunk<LoggedInUser, void, { state: RootState }>(
  'auth/loadUserInfo',
  async () => await loadUserInfo(),
  {
    condition: (_, { getState }) => actionNotPending(getState().auth, loadUserInfoAction)
  }
);

export const loginRedirect = createAsyncThunk<void, void>('auth/loginRedirect', () =>
  RouterNavigationService.getNavigateFunction()('/login')
);
