import type { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import type {
  Application,
  ApplicationProperties,
  ExportFormat,
  ID,
  MemberSettings,
  PaginationResponse
} from '@bright/api';
import { axiosInstance } from '@bright/core';
import type { ManageApplicationPayload } from '../models';

const SELECTED_APPLICATION_COOKIE = 'appId';

export const loadApplications = async (
  orgId: string,
  queryString: string = ''
): Promise<PaginationResponse<Application>> => {
  const appsResponse = await axiosInstance.get<PaginationResponse<Application>>(
    `/api/v4/orgs/${orgId}/apps?${queryString}`
  );

  return appsResponse.data;
};

export const updateMemberSettings = async (settings: MemberSettings): Promise<void> => {
  await axiosInstance.put<void>(`/api/v4/me/settings`, settings);
};

export const loadProperties = async (
  orgId: string,
  appId: string
): Promise<ApplicationProperties> => {
  const response = await axiosInstance.get<ApplicationProperties>(
    `/api/v4/orgs/${orgId}/apps/${appId}`
  );
  return response.data;
};

export const updateProperties = async (
  orgId: string,
  appId: string,
  data: Omit<ApplicationProperties, 'default'>
): Promise<void> => {
  await axiosInstance.put<void>(`/api/v4/orgs/${orgId}/apps/${appId}`, data);
};

export const setSessionAppId = (appId: string): void => {
  Cookies.set(SELECTED_APPLICATION_COOKIE, appId, { expires: 30, sameSite: 'strict' });
};

export const getSessionAppId = (): string | undefined => {
  return Cookies.get(SELECTED_APPLICATION_COOKIE);
};

export const destroyAppIdSession = (): void => {
  Cookies.remove(SELECTED_APPLICATION_COOKIE);
};

export const exportApplications = async (
  orgId: string,
  format: ExportFormat
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/orgs/${orgId}/apps/export`,
    {
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createApplication = async (orgId: ID['id'], payload: ManageApplicationPayload) => {
  const response = await axiosInstance.post<ID>(`/rest/v1/orgs/${orgId}/apps`, payload);
  return response.data;
};

export const loadApplication = async (appId: string) => {
  const response = await axiosInstance.get<Application>(`/rest/v1/apps/${appId}`);
  return response.data;
};

export const updateApplication = async (appId: ID['id'], payload: ManageApplicationPayload) => {
  await axiosInstance.patch(`/rest/v1/apps/${appId}`, payload);
};

export const deleteApplication = async (appId: ID['id']) => {
  await axiosInstance.delete(`/rest/v1/apps/${appId}`);
};

export const exportApplication = async (
  appId: string,
  format: ExportFormat
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/apps/${appId}/export`,
    {
      format
    },
    {
      responseType: 'blob'
    }
  );
};
