import type { PaginationResponse } from '@bright/api';
import { axiosInstance } from '@bright/core';
// eslint-disable-next-line import/extensions
import mockedIntegrationsData from '../../../mocks/integrations.json';
import type { Integration } from '../models';

export const loadIntegrations = async (orgId: string): Promise<PaginationResponse<Integration>> => {
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockedIntegrationsData as PaginationResponse<Integration>);
      }, 500);
    });
  }
  const response = await axiosInstance.get<PaginationResponse<Integration>>(
    `/api/v4/orgs/${orgId}/integrations`
  );

  return response.data;
};
