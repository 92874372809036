import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type {
  ApiError,
  BasicBridgesScript,
  BridgesScript,
  ExportFormat,
  PaginationResponse
} from '@bright/api';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { BridgesScriptBulkAction } from '../models';
import {
  exportBridgesScripts,
  loadBasicBridgesScripts,
  loadBridgesScripts,
  runBridgesScriptsBulkAction,
  updateBridgesScriptName
} from '../services';

export const loadBridgesScriptsAction = createAsyncThunk<
  PaginationResponse<BridgesScript>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'bridgesScripts/loadBridgesScripts',
  async ({ queryString }, { rejectWithValue }) => {
    try {
      return await loadBridgesScripts(queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().bridgesScripts, loadBridgesScriptsAction)
  }
);

export const updateBridgesScriptNameAction = createAsyncThunk<
  { scriptId: string; name: string },
  {
    scriptId: string;
    data: Pick<BridgesScript, 'name'>;
  },
  { state: RootState }
>('bridgesScripts/updateBridgesScriptName', async ({ scriptId, data }, { rejectWithValue }) => {
  try {
    await updateBridgesScriptName(scriptId, data);
    return { scriptId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const runBridgesScriptsBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: BridgesScriptBulkAction;
  },
  { state: RootState }
>('bridgesScripts/runBridgesScriptsBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runBridgesScriptsBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportBridgesScriptsAction = createAsyncThunk<
  void,
  {
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('bridgesScripts/exportBridgesScriptsAction', async ({ ids, format }, { rejectWithValue }) => {
  try {
    const response = await exportBridgesScripts(format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const loadBasicBridgesScriptsAction = createAsyncThunk<
  BasicBridgesScript[],
  void,
  { state: RootState }
>(
  'bridgesScripts/loadBasicBridgesScripts',
  async (_, { rejectWithValue }) => {
    try {
      return await loadBasicBridgesScripts();
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().bridgesScripts, loadBasicBridgesScriptsAction)
  }
);
