import type { InternalAxiosRequestConfig } from 'axios';
import { store } from '@bright/core';
import { API_PREFIXES, AUTHORIZATION_HEADER, LOGIN_ENDPOINT, TOKEN_ENDPOINT } from '../../models';
import { loginRedirect } from '../../store';
import { RequestQueueService } from '../request-queue.service';
import { TokenRefreshService } from '../token-refresh.service';
import { TokenStorageService } from '../token-storage.service';
import { UserSessionStorageService } from '../user-session-storage.service';
import { addAuthenticationToken, extractPathname } from './interceptor-helpers';

export const addAccessTokenInterceptor = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  if (!shouldAttachToken(config)) {
    return config;
  }

  const accessToken = TokenStorageService.getAccessToken();
  if (accessToken && TokenStorageService.isTokenValid()) {
    return addAuthenticationToken(config, accessToken);
  }

  if (TokenRefreshService.isRefreshing()) {
    return new Promise(resolve => {
      RequestQueueService.addRequestCallback(newAccessToken => {
        resolve(addAuthenticationToken(config, newAccessToken));
      });
    });
  }

  const SESSION_EXPIRED_MESSAGE = 'Session expired, re-login required';

  if (!UserSessionStorageService.hasLoggedIn()) {
    store.dispatch(loginRedirect());
    return Promise.reject(new Error(SESSION_EXPIRED_MESSAGE));
  }

  try {
    const newAccessToken = await TokenRefreshService.getNewAccessToken();
    return addAuthenticationToken(config, newAccessToken);
  } catch (error) {
    return Promise.reject(new Error(SESSION_EXPIRED_MESSAGE));
  }
};

const shouldAttachToken = (requestConfig: InternalAxiosRequestConfig): boolean => {
  if (requestConfig.headers.has(AUTHORIZATION_HEADER)) {
    return false;
  }

  if (!requestConfig.url) {
    return false;
  }

  const pathname = extractPathname(requestConfig.url);

  if (TOKEN_ENDPOINT.test(pathname) || LOGIN_ENDPOINT.test(pathname)) {
    return false;
  }

  return API_PREFIXES.some((prefix: string) => pathname.startsWith(prefix));
};
