import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { AuditLogEntry } from '../models';
import { loadAuditLogEntries } from '../services';

export const loadAuditLogEntriesAction = createAsyncThunk<
  PaginationResponse<AuditLogEntry>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'audit-log/loadAuditLogEntries',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadAuditLogEntries(orgId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().auditLog, loadAuditLogEntriesAction)
  }
);
