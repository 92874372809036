import { createSvgIcon } from '@mui/material/utils';

export const AuthenticationsOutlinedIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.375 6.5H8.25V4.75C8.25 4.28587 8.43437 3.84075 8.76256 3.51256C9.09075 3.18437 9.53587 3 10 3C10.4641 3 10.9092 3.18437 11.2374 3.51256C11.5656 3.84075 11.75 4.28587 11.75 4.75C11.75 4.98206 11.8422 5.20462 12.0063 5.36872C12.1704 5.53281 12.3929 5.625 12.625 5.625C12.8571 5.625 13.0796 5.53281 13.2437 5.36872C13.4078 5.20462 13.5 4.98206 13.5 4.75C13.5 3.82174 13.1312 2.9315 12.4749 2.27513C11.8185 1.61875 10.9283 1.25 10 1.25C9.07174 1.25 8.1815 1.61875 7.52513 2.27513C6.86875 2.9315 6.5 3.82174 6.5 4.75V6.5H5.625C4.92881 6.5 4.26113 6.77656 3.76884 7.26884C3.27656 7.76113 3 8.42881 3 9.125V16.125C3 16.8212 3.27656 17.4889 3.76884 17.9812C4.26113 18.4734 4.92881 18.75 5.625 18.75H14.375C15.0712 18.75 15.7389 18.4734 16.2312 17.9812C16.7234 17.4889 17 16.8212 17 16.125V9.125C17 8.42881 16.7234 7.76113 16.2312 7.26884C15.7389 6.77656 15.0712 6.5 14.375 6.5ZM15.25 16.125C15.25 16.3571 15.1578 16.5796 14.9937 16.7437C14.8296 16.9078 14.6071 17 14.375 17H5.625C5.39294 17 5.17038 16.9078 5.00628 16.7437C4.84219 16.5796 4.75 16.3571 4.75 16.125V9.125C4.75 8.89293 4.84219 8.67037 5.00628 8.50628C5.17038 8.34219 5.39294 8.25 5.625 8.25H14.375C14.6071 8.25 14.8296 8.34219 14.9937 8.50628C15.1578 8.67037 15.25 8.89293 15.25 9.125V16.125Z" />
    <path d="M10 10C9.46601 10 8.94399 10.1584 8.49998 10.455C8.05597 10.7517 7.7099 11.1734 7.50554 11.6668C7.30119 12.1601 7.24772 12.703 7.3519 13.2267C7.45608 13.7505 7.71323 14.2316 8.09083 14.6092C8.46843 14.9868 8.94953 15.2439 9.47328 15.3481C9.99702 15.4523 10.5399 15.3988 11.0333 15.1945C11.5266 14.9901 11.9483 14.6441 12.245 14.2C12.5417 13.756 12.7 13.234 12.7 12.7C12.7 11.9839 12.4156 11.2972 11.9092 10.7908C11.4029 10.2845 10.7161 10 10 10ZM10 13.6C9.82202 13.6 9.64801 13.5472 9.50001 13.4483C9.352 13.3494 9.23665 13.2089 9.16853 13.0444C9.10041 12.88 9.08259 12.699 9.11731 12.5244C9.15204 12.3498 9.23776 12.1895 9.36362 12.0636C9.48949 11.9377 9.64986 11.852 9.82444 11.8173C9.99902 11.7826 10.18 11.8004 10.3444 11.8685C10.5089 11.9366 10.6494 12.052 10.7483 12.2C10.8472 12.348 10.9 12.522 10.9 12.7C10.9 12.9387 10.8052 13.1676 10.6364 13.3364C10.4676 13.5052 10.2387 13.6 10 13.6Z" />
  </svg>,
  'AuthenticationsOutlinedIcon'
);
