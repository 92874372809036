import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import type { MenuItemProps } from '@mui/material/MenuItem';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { typographyClasses } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setSessionAppIdAction } from '@bright/applications/store';
import { logoutAction, selectOwnOrganizationName, selectUserInfo } from '@bright/auth/store';
import { useAppDispatch, useAppSelector } from '@bright/core';
import {
  CustomPopover,
  CustomTooltip,
  SettingsOutlinedIcon,
  SignOutIcon,
  TextWithEllipsis
} from '@bright/share';
import { DarkModeToggle } from './DarkModeToggle';
import { UserProfileMenuAnchor } from './UserProfileMenuAnchor';

const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  height: 40,
  maxHeight: 40,
  padding: theme.spacing(1.25, 2),
  color: theme.palette.custom.header.menu.fg,
  backgroundColor: theme.palette.custom.header.menu.bg,
  '&:hover': {
    color: theme.palette.custom.header.menu.hover.fg,
    backgroundColor: theme.palette.custom.header.menu.hover.bg
  }
}));

export const UserProfileMenu: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [darkModeEnabled, setDarkModeEnabled] = useState<boolean>(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [ref, setRef] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUserInfo);
  const organizationName = useAppSelector(selectOwnOrganizationName);

  const handleAnchorClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    dispatch(setSessionAppIdAction(null));
    dispatch(logoutAction());
  };

  const toggleDarkMode = useCallback(() => {
    // TODO: Implement real dark mode toggle. https://brightsec.atlassian.net/browse/ADM-951
    setDarkModeEnabled(!darkModeEnabled);
  }, [darkModeEnabled]);

  useEffect(() => {
    if (ref) {
      setIsTruncated(ref.scrollHeight > ref.clientHeight);
    }
  }, [ref]);

  return (
    <>
      <UserProfileMenuAnchor
        user={user!}
        organizationName={organizationName}
        onClick={handleAnchorClick}
      />
      <CustomPopover anchorEl={anchorEl} onClose={handleClose}>
        <List disablePadding>
          <CustomTooltip text={organizationName} disableHoverListener={!isTruncated}>
            <StyledMenuItem
              disableRipple
              divider
              aria-disabled={'true'}
              sx={{
                height: 48,
                maxHeight: 52,
                padding: theme => theme.spacing(1, 2),
                cursor: 'default',
                [`&:hover, &.${menuItemClasses.focusVisible}`]: {
                  backgroundColor: theme => theme.palette.custom.header.menu.bg
                },
                [`&.${menuItemClasses.disabled}`]: {
                  opacity: 1
                },
                [`& .${typographyClasses.body2}`]: {
                  lineHeight: 1.3
                }
              }}
            >
              <ListItemText>
                <TextWithEllipsis ref={ref => setRef(ref as HTMLElement)} clamp={2}>
                  {organizationName}
                </TextWithEllipsis>
              </ListItemText>
            </StyledMenuItem>
          </CustomTooltip>
          <StyledMenuItem tabIndex={0}>
            <DarkModeToggle checked={darkModeEnabled} onChange={toggleDarkMode} />
          </StyledMenuItem>
          <StyledMenuItem tabIndex={0} onClick={() => navigate('/user-settings')}>
            <SettingsOutlinedIcon sx={{ mr: 1 }} />
            {t('topBar.user.items.userSettings')}
          </StyledMenuItem>
          <StyledMenuItem tabIndex={0} onClick={handleSignOut}>
            <SignOutIcon sx={{ mr: 1 }} />
            {t('topBar.user.items.signOut')}
          </StyledMenuItem>
        </List>
      </CustomPopover>
    </>
  );
};
