import Button, { buttonClasses } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const ApplicationButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 1.5),
  backgroundColor: theme.palette.custom.header.appMenu.selected.bg,
  '&:hover': {
    backgroundColor: theme.palette.custom.header.appMenu.hover.bg
  },
  [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.custom.header.appMenu.focus.bg
  }
}));
