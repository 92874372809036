import type { AxiosProgressEvent, AxiosResponse } from 'axios';
import type {
  Entrypoint,
  EntrypointDetails,
  ExportFormat,
  ID,
  PaginationResponse,
  TargetHost
} from '@bright/api';
import { EntrypointConnectivityStatus } from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';
import type {
  EntrypointsImportPayload,
  EntrypointsImportUploadPayload,
  IntrospectSchemaPayload
} from '../models';
import { EntrypointBulkAction } from '../models';

export const loadEntrypoints = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<Entrypoint>> => {
  // TODO remove once entrypoints connectivity filter API (ADM-2137) will be ready
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    let updatedQueryString = queryString;
    const params = new URLSearchParams(decodeURIComponent(queryString));
    const connectivityFilterValues: string[] = params.getAll('connectivity[ne]');

    if (connectivityFilterValues.length > 0) {
      params.delete('connectivity[ne]');

      Object.values(EntrypointConnectivityStatus)
        .filter(status => !connectivityFilterValues.includes(status))
        .forEach(status => {
          params.append('connectivity[]', status);
        });

      updatedQueryString = params.toString();
    }

    const response = await axiosInstance.get<PaginationResponse<Entrypoint>>(
      `/rest/v1/apps/${appId}/entrypoints?${updatedQueryString}`
    );
    return response.data;
  }

  const response = await axiosInstance.get<PaginationResponse<Entrypoint>>(
    `/rest/v1/apps/${appId}/entrypoints?${queryString}`
  );
  return response.data;
};

export const loadAllEntrypoints = async (
  appId: string,
  signal: AbortSignal,
  params: URLSearchParams,
  limit?: number
): Promise<Entrypoint[]> => {
  const url = `/rest/v1/apps/${appId}/entrypoints?${params.toString()}`;
  return fetchAllPages<Entrypoint>(url, limit, signal);
};

export const loadEntrypointDetails = async (entrypointId: string): Promise<EntrypointDetails> => {
  const response = await axiosInstance.get<EntrypointDetails>(
    `/rest/v1/entrypoints/${entrypointId}/details`
  );

  return response.data;
};

export const runEntrypointsBulkAction = async (
  ids: string[],
  action: EntrypointBulkAction
): Promise<string | void> => {
  let response: AxiosResponse<ID | void>;
  switch (action) {
    case EntrypointBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/entrypoints/delete`, {
        ids
      });
      break;
    default:
      throw new Error(`Unknown entrypoint bulk action: ${action}`);
  }
  return response.data?.id;
};

export const exportEntrypoints = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/entrypoints/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const importEntrypoints = async (
  appId: string,
  payload: EntrypointsImportPayload
): Promise<ID> => {
  const { schemaUrl, file, ...config } = payload;
  const formData = new FormData();
  formData.append('config', JSON.stringify(config));

  if (schemaUrl) {
    formData.append('url', schemaUrl);
  }
  if (file) {
    formData.append('file', file);
  }

  return (await axiosInstance.post<ID>(`/rest/v1/apps/${appId}/specification/import`, formData))
    .data;
};

export const introspectSchema = async (
  payload: IntrospectSchemaPayload
): Promise<PaginationResponse<TargetHost>> => {
  const formData = new FormData();
  formData.append('url', payload.url);
  formData.append('type', payload.type);
  if (payload.bridgeId) {
    formData.append('bridgeId', payload.bridgeId);
  }

  const items =
    (
      await axiosInstance.post<{ targets?: TargetHost[] }>(
        `/rest/v1/specification/introspection`,
        formData
      )
    ).data?.targets ?? [];

  return { items, total: items.length };
};

export const introspectSchemaFile = (
  { file, type, bridgeId }: EntrypointsImportUploadPayload,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
): {
  axiosRequest: Promise<AxiosResponse<{ targets?: TargetHost[] }, any>>;
  abortController: AbortController;
} => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);

  if (bridgeId) {
    formData.append('bridgeId', bridgeId);
  }

  const abortController = new AbortController();

  const axiosRequest = axiosInstance.post<{ targets?: TargetHost[] }>(
    `/rest/v1/specification/introspection`,
    formData,
    {
      onUploadProgress,
      signal: abortController.signal
    }
  );

  return { axiosRequest, abortController };
};
