import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { Scan } from '../models';
import { updateScanNameAction } from './scan.actions';
import {
  exportScansAction,
  loadScansAction,
  runScanAction,
  runScansBulkActionAction
} from './scans.actions';

export interface ScansState {
  readonly items: Scan[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialScansState: ScansState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const scansSlice = createSlice({
  name: 'scans',
  initialState: initialScansState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadScansAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadScansAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadScansAction
      )
    );
    builder.addCase(loadScansAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadScansAction
      )
    );

    builder.addCase(updateScanNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateScanNameAction.fulfilled, state =>
      cleanupPendingState(state, updateScanNameAction)
    );
    builder.addCase(updateScanNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateScanNameAction
      )
    );

    builder.addCase(runScanAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runScanAction.fulfilled, state => cleanupPendingState(state, runScanAction));
    builder.addCase(runScanAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runScanAction
      )
    );

    builder.addCase(runScansBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runScansBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runScansBulkActionAction)
    );
    builder.addCase(runScansBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runScansBulkActionAction
      )
    );

    builder.addCase(exportScansAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportScansAction.fulfilled, state =>
      cleanupPendingState(state, exportScansAction)
    );
    builder.addCase(exportScansAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportScansAction
      )
    );
  }
});

export const scansReducer = scansSlice.reducer;
