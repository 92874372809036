import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { AuthPool } from '../models';
import {
  exportAuthenticationPoolsAction,
  loadAuthenticationPoolsAction,
  runAuthenticationPoolsBulkActionAction
} from './authentication-pools.actions';

export interface AuthenticationPoolsState {
  readonly items: AuthPool[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialAuthenticationPoolsState: AuthenticationPoolsState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const authenticationPoolsSlice = createSlice({
  name: 'authentication-pools',
  initialState: initialAuthenticationPoolsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadAuthenticationPoolsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAuthenticationPoolsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadAuthenticationPoolsAction
      )
    );
    builder.addCase(loadAuthenticationPoolsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAuthenticationPoolsAction
      )
    );

    builder.addCase(runAuthenticationPoolsBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runAuthenticationPoolsBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runAuthenticationPoolsBulkActionAction)
    );
    builder.addCase(
      runAuthenticationPoolsBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runAuthenticationPoolsBulkActionAction
        )
    );

    builder.addCase(exportAuthenticationPoolsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportAuthenticationPoolsAction.fulfilled, state =>
      cleanupPendingState(state, exportAuthenticationPoolsAction)
    );
    builder.addCase(exportAuthenticationPoolsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportAuthenticationPoolsAction
      )
    );
  }
});

export const authenticationPoolsReducer = authenticationPoolsSlice.reducer;
