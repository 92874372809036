import type { FC, MouseEvent } from 'react';
import { useState } from 'react';
import Link from '@mui/material/Link';
import type { MenuItemProps } from '@mui/material/MenuItem';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { CustomPopover, CustomTooltip, HelpMenuIcon } from '@bright/share';
import { HeaderIconButton } from './HeaderIconButton';

const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  lineHeight: '1rem',
  height: 40,
  maxHeight: 40,
  color: theme.palette.custom.header.menu.fg,
  backgroundColor: theme.palette.custom.header.menu.bg,
  '&:hover': {
    color: theme.palette.custom.header.menu.hover.fg,
    backgroundColor: theme.palette.custom.header.menu.hover.bg
  }
}));

export const IconHelpMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const items = [
    {
      url: t('topBar.help.items.kb.link'),
      name: t('topBar.help.items.kb.title')
    },
    {
      url: t('topBar.help.items.api.link'),
      name: t('topBar.help.items.api.title')
    },
    {
      url: t('topBar.help.items.support.link'),
      name: t('topBar.help.items.support.title')
    }
  ];

  const handleAnchorClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomTooltip text={t('topBar.help.iconTitle')} variant="light">
        <HeaderIconButton onClick={handleAnchorClick}>
          <HelpMenuIcon />
        </HeaderIconButton>
      </CustomTooltip>
      <CustomPopover anchorEl={anchorEl} onClose={handleClose}>
        {items.map(item => (
          <StyledMenuItem key={item.name} onClick={handleClose} tabIndex={0}>
            <Link
              tabIndex={-1}
              underline="none"
              rel="noopener"
              target="_blank"
              href={item.url}
              sx={{ color: 'inherit' }}
            >
              {item.name}
            </Link>
          </StyledMenuItem>
        ))}
      </CustomPopover>
    </>
  );
};
