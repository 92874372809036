import { createSelector } from '@reduxjs/toolkit';
import type { LoggedInUser } from '@bright/api';
import type { RootState } from '@bright/core';
import { toLongId } from '@bright/core';

export const selectAuthState = (state: RootState) => state.auth;

export const selectUserInfo = createSelector(selectAuthState, state => state.userInfo);

export const selectAuthPendingStatus = createSelector(
  selectAuthState,
  state => state.pending.length > 0
);

/** @deprecated */
export const legacySelectOwnOrganizationId = createSelector(
  selectUserInfo,
  (user: LoggedInUser | null): string => user?.orgs[0].id ?? ''
);

export const selectOwnOrganizationId = createSelector(
  selectUserInfo,
  (user: LoggedInUser | null): string => (user?.orgs[0].id ? toLongId(user?.orgs[0].id) : '')
);

export const selectOwnOrganizationMemberId = createSelector(
  selectUserInfo,
  (user: LoggedInUser | null): string => user?.orgs[0].memberId ?? ''
);

export const selectOwnOrganizationName = createSelector(
  selectUserInfo,
  (user: LoggedInUser | null): string => user?.orgs[0].name ?? ''
);
