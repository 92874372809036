import type { RouteObject } from 'react-router-dom';

export const bridgesScriptsRoutes: RouteObject[] = [
  {
    path: 'bridges-scripts',
    async lazy() {
      const { BridgesScriptsPage } = await import('./pages');
      return { Component: BridgesScriptsPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateBridgesScriptPage } = await import('./pages');
          return { Component: CreateBridgesScriptPage };
        }
      },
      {
        path: ':scriptId',
        async lazy() {
          const { EditBridgesScriptPage } = await import('./pages');
          return { Component: EditBridgesScriptPage };
        }
      }
    ]
  }
];
