import type { InternalAxiosRequestConfig } from 'axios';
import { AUTHORIZATION_HEADER } from '../../models';

export const addAuthenticationToken = <T>(
  requestConfig: InternalAxiosRequestConfig<T>,
  accessToken: string
): InternalAxiosRequestConfig<T> => {
  requestConfig.headers[AUTHORIZATION_HEADER] = `Bearer ${accessToken}`;

  return requestConfig;
};

export const extractPathname = (url: string): string => {
  let pathname: string;

  try {
    ({ pathname } = new URL(url));
  } catch {
    pathname = url;
  }

  return pathname;
};
