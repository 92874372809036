import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ID } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManageRolePayload, RoleDetails } from '../models';
import { createRole, loadRole, updateRole } from '../services';

export const loadRoleAction = createAsyncThunk<
  RoleDetails,
  {
    roleId: string;
  },
  { state: RootState }
>(
  'role/loadRole',
  async ({ roleId }, { rejectWithValue }) => {
    try {
      return await loadRole(roleId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().role, loadRoleAction)
  }
);

export const updateRoleAction = createAsyncThunk<
  void,
  {
    roleId: string;
    payload: ManageRolePayload;
  },
  { state: RootState }
>('role/updateRole', async ({ roleId, payload }, { rejectWithValue }) => {
  try {
    return await updateRole(roleId, payload);
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});

export const createRoleAction = createAsyncThunk<
  ID,
  {
    payload: ManageRolePayload;
  },
  { state: RootState }
>(
  'role/createRole',
  async ({ payload }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await createRole(orgId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().role, createRoleAction)
  }
);
