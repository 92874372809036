import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Application } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  deleteApplicationAction,
  exportApplicationAction,
  loadApplicationAction
} from './application.actions';

export interface ApplicationState {
  readonly application: Application | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialApplicationState: ApplicationState = {
  application: null,
  pending: [],
  error: null
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState: initialApplicationState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadApplicationAction.pending, (state, action) => ({
      ...state,
      application: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadApplicationAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          application: action.payload
        },
        loadApplicationAction
      )
    );
    builder.addCase(loadApplicationAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadApplicationAction
      )
    );

    builder.addCase(deleteApplicationAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));

    builder.addCase(deleteApplicationAction.fulfilled, state =>
      cleanupPendingState(state, deleteApplicationAction)
    );

    builder.addCase(deleteApplicationAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        deleteApplicationAction
      )
    );

    builder.addCase(exportApplicationAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportApplicationAction.fulfilled, state =>
      cleanupPendingState(state, exportApplicationAction)
    );
    builder.addCase(exportApplicationAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportApplicationAction
      )
    );
  }
});

export const applicationReducer = applicationSlice.reducer;
