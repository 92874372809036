import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { BridgeNetworkPayload, BridgeNetworkResult } from '../models';
import { loadBridgeNetworkResult, runBridgeNetworkTest } from '../services';

export const loadBridgeNetworkResultAction = createAsyncThunk<
  BridgeNetworkResult | void,
  {
    bridgeId: string;
  },
  { state: RootState }
>(
  'bridgeNetwork/loadBridgeNetworkResult',
  async ({ bridgeId }, { rejectWithValue }) => {
    try {
      return await loadBridgeNetworkResult(bridgeId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().bridgeNetwork, loadBridgeNetworkResultAction)
  }
);

export const pollBridgeNetworkResultAction = createAsyncThunk<
  BridgeNetworkResult | void,
  {
    bridgeId: string;
  },
  { state: RootState }
>('bridgeNetwork/pollBridgeNetworkResult', async ({ bridgeId }, { rejectWithValue }) => {
  try {
    return await loadBridgeNetworkResult(bridgeId);
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});

export const runBridgeNetworkTestAction = createAsyncThunk<
  BridgeNetworkResult,
  {
    bridgeId: string;
    payload: BridgeNetworkPayload;
  },
  { state: RootState }
>('bridgeNetwork/runBridgeNetworkTest', async ({ bridgeId, payload }, { rejectWithValue }) => {
  try {
    return await runBridgeNetworkTest(bridgeId, payload);
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});
