import type { PropsWithChildren, ReactNode } from 'react';
import { forwardRef } from 'react';
import Alert, { alertClasses } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import type { CustomContentProps } from 'notistack';
import { SnackbarContent, SnackbarProvider, useSnackbar } from 'notistack';

export function FloatingSnackbarProvider({
  children,
  domRoot
}: { readonly domRoot?: HTMLElement } & PropsWithChildren): ReactNode {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      domRoot={domRoot}
      maxSnack={1}
      classes={{
        containerRoot: 'SnackbarContainer-root'
      }}
      autoHideDuration={7000}
      Components={{
        success: SuccessSnackbar,
        error: ErrorSnackbar,
        info: InfoSnackbar,
        warning: WarningSnackbar
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

const StyledAlert = styled(Alert)(({ theme, severity = 'success' }) => ({
  boxShadow: `-1px 1px 6px 0px ${theme.palette.custom.snackbar.floating.shadow}`,
  backgroundColor: theme.palette.custom.snackbar.floating.bg[severity],
  color: theme.palette.custom.snackbar.floating.fg,
  [`& .${alertClasses.message}`]: {
    fontSize: theme.typography.body2Medium.fontSize,
    lineHeight: theme.typography.body2Medium.lineHeight,
    fontWeight: theme.typography.body2Medium.fontWeight
  },
  [`& .${alertClasses.action}`]: {
    paddingTop: 0
  }
}));

const SuccessSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(function SuccessSnackbar(
  { id, message, style, action },
  ref
) {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref} role="alert" style={style}>
      <StyledAlert
        severity="success"
        variant="filled"
        onClose={() => closeSnackbar(id)}
        action={typeof action === 'function' ? action(id) : action}
      >
        {message}
      </StyledAlert>
    </SnackbarContent>
  );
});

const ErrorSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(function ErrorSnackbar(
  { id, message, style, action },
  ref
) {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref} role="alert" style={style}>
      <StyledAlert
        severity="error"
        variant="filled"
        onClose={() => closeSnackbar(id)}
        action={typeof action === 'function' ? action(id) : action}
      >
        {message}
      </StyledAlert>
    </SnackbarContent>
  );
});

const InfoSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(function ErrorSnackbar(
  { id, message, style, action },
  ref
) {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref} role="alert" style={style}>
      <StyledAlert
        severity="info"
        variant="filled"
        onClose={() => closeSnackbar(id)}
        action={typeof action === 'function' ? action(id) : action}
      >
        {message}
      </StyledAlert>
    </SnackbarContent>
  );
});

const WarningSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(function ErrorSnackbar(
  { id, message, style, action },
  ref
) {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref} role="alert" style={style}>
      <StyledAlert
        severity="warning"
        variant="filled"
        onClose={() => closeSnackbar(id)}
        action={typeof action === 'function' ? action(id) : action}
      >
        {message}
      </StyledAlert>
    </SnackbarContent>
  );
});
