import { createSvgIcon } from '@mui/material/utils';

export const HomeIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.61603 22.3266V14.5034C8.61603 13.7833 9.22987 13.1995 9.98709 13.1995H14.1003C14.8575 13.1995 15.4713 13.7833 15.4713 14.5034V22.3266M11.2491 2.00202L2.33724 8.02879C1.97556 8.27338 1.76074 8.66933 1.76074 9.09139V20.3708C1.76074 21.451 2.68151 22.3266 3.81733 22.3266H20.27C21.4058 22.3266 22.3266 21.451 22.3266 20.3708V9.09139C22.3266 8.66933 22.1118 8.27338 21.7501 8.02879L12.8382 2.00202C12.3625 1.68032 11.7248 1.68032 11.2491 2.00202Z"
      stroke="currentcolor"
      strokeWidth="1.71382"
      strokeLinecap="round"
    />
  </svg>,
  'HomeIcon'
);
