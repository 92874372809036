import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Outlet, useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import {
  selectApplications,
  selectDefaultApplicationId,
  selectSessionAppId
} from '@bright/applications/store';
import { useAppSelector } from '@bright/core';
import {
  ConfirmDialogProvider,
  EntityPageHistoryContext,
  FloatingSnackbarProvider,
  useAppEntityPageHistory
} from '@bright/share';
import { AppHeader } from './AppHeader';
import { AppSidebar } from './AppSidebar/AppSidebar';

const HEADER_HEIGHT = 72;

export const MainLayout: FC<{
  readonly initialOpened: boolean;
}> = ({ initialOpened }) => {
  const [opened, toggleOpened] = useBoolean(initialOpened);
  const { appId: pathAppId } = useParams<{ appId: string }>();
  const defaultAppId = useAppSelector(selectDefaultApplicationId);
  const sessionAppId = useAppSelector(selectSessionAppId);
  const apps = useAppSelector(selectApplications);

  const appId = pathAppId ?? sessionAppId ?? defaultAppId;

  const history = useAppEntityPageHistory();

  const onToggleSidebar = () => {
    toggleOpened();
  };

  const [snackbarDomRoot, setSnackbarDomRoot] = useState<HTMLElement | undefined>(undefined);
  const snackbarContainerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setSnackbarDomRoot(snackbarContainerRef.current ?? undefined);
  }, [snackbarContainerRef]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ConfirmDialogProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FloatingSnackbarProvider domRoot={snackbarDomRoot}>
            <AppHeader
              appId={appId}
              apps={apps ?? []}
              onToggleSidebar={onToggleSidebar}
              opened={opened}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <AppSidebar appId={appId} opened={opened} />
              <Box
                sx={{
                  flex: '1 0 0',
                  overflowX: 'hidden',
                  backgroundColor: theme => theme.palette.dark.main
                }}
              >
                <Box
                  ref={snackbarContainerRef}
                  sx={{
                    backgroundColor: theme => theme.palette.dark.main,
                    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
                    overflow: 'hidden',
                    mt: `${HEADER_HEIGHT}px`,
                    position: 'relative'
                  }}
                >
                  <EntityPageHistoryContext.Provider value={history}>
                    <Outlet />
                  </EntityPageHistoryContext.Provider>
                </Box>
              </Box>
            </Box>
          </FloatingSnackbarProvider>
        </LocalizationProvider>
      </ConfirmDialogProvider>
    </DndProvider>
  );
};
