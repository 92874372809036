import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Repeater } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadRepeatersLookup } from '../services';

export const loadRepeatersLookupAction = createAsyncThunk<Repeater[], void, { state: RootState }>(
  'repeaters/loadRepeatersLookup',
  async (_, { rejectWithValue }) => {
    try {
      return await loadRepeatersLookup();
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().repeatersLookup, loadRepeatersLookupAction)
  }
);
