import type { ChangeEventHandler, FC } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';

interface DarkModeToggleProps {
  readonly checked: boolean;
  readonly onChange: ChangeEventHandler;
}

export const DarkModeToggle: FC<DarkModeToggleProps> = ({ checked, onChange }) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      control={
        <Switch
          id={'theme-toggle'}
          checked={checked}
          onClick={e => e.stopPropagation()}
          onChange={onChange}
          sx={{
            ml: '-6px',
            mr: '-14px',
            transform: 'scale(0.6315)'
          }}
        />
      }
      label={t('topBar.user.items.darkMode')}
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        padding: '10px 5px'
      }}
    />
  );
};
