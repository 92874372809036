import { createBrowserRouter } from 'react-router-dom';
import { ApplicationsGuard } from '@bright/applications';
import { auditLogRoutes } from '@bright/audit-log';
import { authRoutes, ProtectedRoute } from '@bright/auth';
import { authenticationsRoutes } from '@bright/authentications';
import { dashboardRoutes } from '@bright/dashboard';
import { discoveriesRoutes } from '@bright/discoveries';
import { entrypointsRoutes } from '@bright/entrypoints';
import { integrationsRoutes } from '@bright/integrations';
import { librariesRoutes } from '@bright/libraries';
import { propertiesRoutes } from '@bright/properties';
import { scansRoutes } from '@bright/scans';
import { setsRoutes } from '@bright/sets';
import { settingsRoutes } from '@bright/settings';
import {
  GlobalErrorElement,
  MainLayout,
  NavigationServiceInitializer,
  NotFoundPage,
  RootPageRedirect
} from '@bright/shell';
import { sitemapRoutes } from '@bright/sitemap';
import { userSettingsRoutes } from '@bright/user-settings';
import { vulnerabilitiesRoutes } from '../features/vulnerabilities/routes';

export const router = createBrowserRouter([
  {
    errorElement: <GlobalErrorElement />,
    children: [
      {
        element: <NavigationServiceInitializer />,
        children: [
          ...authRoutes,
          {
            element: <ProtectedRoute />,
            children: [
              {
                index: true,
                element: <RootPageRedirect />
              },
              {
                element: <MainLayout initialOpened={true} />,
                children: [
                  {
                    path: 'app/:appId',
                    element: <ApplicationsGuard />,
                    children: [
                      ...authenticationsRoutes,
                      ...dashboardRoutes,
                      ...discoveriesRoutes,
                      ...entrypointsRoutes,
                      ...librariesRoutes,
                      ...scansRoutes,
                      ...setsRoutes,
                      ...sitemapRoutes,
                      ...propertiesRoutes,
                      ...vulnerabilitiesRoutes
                    ]
                  },
                  ...integrationsRoutes,
                  ...auditLogRoutes,
                  ...settingsRoutes,
                  ...userSettingsRoutes
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
]);
