import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { loadScanAction } from './scan.actions';

const selectScanStore = (state: RootState) => state.scan;

export const selectScan = createSelector(selectScanStore, state => state.scan);
export const selectScanPendingStatus = createSelector(
  selectScanStore,
  state => state.pending.filter(action => action.type === loadScanAction.pending.type)?.length > 0
);
