import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { PersonalSettings, PersonalSettingsPayload } from '../models';
import { loadPersonalSettings, updatePersonalSettings } from '../services';

export const loadPersonalSettingsAction = createAsyncThunk<
  PersonalSettings,
  void,
  { state: RootState }
>(
  'personalSetings/loadPersonalSettings',
  async (_, { rejectWithValue }) => {
    try {
      return await loadPersonalSettings();
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().personalSettings, loadPersonalSettingsAction)
  }
);

export const updatePersonalSettingsAction = createAsyncThunk<
  void,
  {
    payload: PersonalSettingsPayload;
  },
  { state: RootState }
>(
  'personalSetings/updatePersonalSettings',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await updatePersonalSettings(payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().personalSettings, updatePersonalSettingsAction)
  }
);
