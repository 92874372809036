import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { UserDetails } from '../models';
import { inviteUserAction, loadUserAction, updateUserAction } from './user.actions';

export interface UserState {
  readonly user: UserDetails | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialUserState: UserState = {
  user: null,
  pending: [],
  error: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadUserAction.pending, (state, action) => ({
      ...state,
      user: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadUserAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          user: action.payload
        },
        loadUserAction
      )
    );
    builder.addCase(loadUserAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadUserAction
      )
    );

    builder.addCase(updateUserAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateUserAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateUserAction)
    );
    builder.addCase(updateUserAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateUserAction
      )
    );

    builder.addCase(inviteUserAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(inviteUserAction.fulfilled, state =>
      cleanupPendingState({ ...state }, inviteUserAction)
    );
    builder.addCase(inviteUserAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        inviteUserAction
      )
    );
  }
});

export const userReducer = userSlice.reducer;
