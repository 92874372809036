import type { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import { styled, useTheme } from '@mui/material/styles';
import type { SxProps, Theme } from '@mui/material/styles';

type AvatarSizeToken = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface UserAvatarProps {
  readonly children: string;
  readonly size?: AvatarSizeToken;
  readonly color?: string;
  readonly bgColor?: string;
  readonly sx?: SxProps<Theme>;
  readonly className?: string;
}

const sizeProps: Record<AvatarSizeToken, { width: number; height: number; fontSize: number }> = {
  xs: {
    width: 24,
    height: 24,
    fontSize: 12
  },
  sm: {
    width: 32,
    height: 32,
    fontSize: 13
  },
  md: {
    width: 40,
    height: 40,
    fontSize: 14
  },
  lg: {
    width: 48,
    height: 48,
    fontSize: 16
  },
  xl: {
    width: 72,
    height: 72,
    fontSize: 24
  }
};

const getAvatarColors = (theme: Theme, text: string): { bgcolor: string; color: string } => {
  const colors: string[] = Array.from(
    { length: 13 },
    (_, index) => theme.palette.custom.avatar[index + 1]
  );

  const firstLetterCharCode = text.charAt(0).toUpperCase().charCodeAt(0);
  const basisCharCode = 'A'.charCodeAt(0);

  const index = (firstLetterCharCode - basisCharCode) % colors.length;
  return {
    bgcolor: colors[index],
    color: theme.palette.getContrastText(colors[index])
  };
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium
}));

export const UserAvatar: FC<UserAvatarProps> = ({ children, size = 'xs', sx, className }) => {
  const theme = useTheme();

  return (
    <StyledAvatar
      sx={{ ...getAvatarColors(theme, children), ...sizeProps[size], ...(sx ?? {}) }}
      className={className}
    >
      {children}
    </StyledAvatar>
  );
};
