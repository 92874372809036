import { useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { orgApiKeyReducer, orgApiKeysReducer } from '@bright/api-keys/store';
import {
  applicationReducer,
  applicationsReducer,
  navigationPermissionsReducer,
  paginatedApplicationsReducer
} from '@bright/applications/store';
import { auditLogReducer } from '@bright/audit-log/store';
import { authReducer } from '@bright/auth/store';
import { authenticationPoolsReducer } from '@bright/authentication-pools/store';
import {
  authenticationReducer,
  authenticationsLookupReducer,
  authenticationsReducer,
  authenticationTestsReducer
} from '@bright/authentications/store';
import { bridgeNetworkReducer, bridgeReducer, bridgesReducer } from '@bright/bridges/store';
import { bridgesScriptReducer, bridgesScriptsReducer } from '@bright/bridges-scripts/store';
import { dashboardReducer } from '@bright/dashboard/store';
import {
  discoveriesReducer,
  discoveryEntrypointsReducer,
  discoveryLogsReducer,
  discoveryReducer,
  discoveryResponseStatusesReducer,
  discoveryTrafficMetricsReducer
} from '@bright/discoveries/store';
import { entrypointsReducer } from '@bright/entrypoints/store';
import { globalVariablesReducer } from '@bright/global-variables/store';
import { groupReducer, groupsReducer } from '@bright/groups/store';
import { integrationsReducer } from '@bright/integrations/store';
import { ipRestrictionReducer, ipRestrictionsReducer } from '@bright/ip-restrictions/store';
import { librariesReducer } from '@bright/libraries/store';
import { repeatersLookupReducer } from '@bright/repeaters/store';
import { roleReducer, rolesReducer } from '@bright/roles/store';
import {
  scanEntrypointsReducer,
  scanLogsReducer,
  scanReducer,
  scanResponseStatusesReducer,
  scansReducer,
  scanTrafficMetricsReducer
} from '@bright/scans/store';
import { categoriesReducer, setsReducer } from '@bright/sets/store';
import { sitemapReducer } from '@bright/sitemap/store';
import { targetReducer, targetsReducer } from '@bright/targets/store';
import { personalSettingsReducer, securitySettingsReducer } from '@bright/user-settings/store';
import { userReducer, usersReducer } from '@bright/users/store';
import {
  vulnerabilitiesReducer,
  vulnerabilityCommentsReducer,
  vulnerabilityReducer
} from '@bright/vulnerabilities/store';
import { vulnerabilityEntrypointsReducer } from '@bright/vulnerability-entrypoints/store';

const rootReducer = combineReducers({
  applications: applicationsReducer,
  application: applicationReducer,
  paginatedApplications: paginatedApplicationsReducer,
  auditLog: auditLogReducer,
  auth: authReducer,
  authentications: authenticationsReducer,
  authentication: authenticationReducer,
  authenticationTests: authenticationTestsReducer,
  authenticationPools: authenticationPoolsReducer,
  authenticationLookup: authenticationsLookupReducer,
  bridge: bridgeReducer,
  bridges: bridgesReducer,
  bridgeNetwork: bridgeNetworkReducer,
  bridgesScript: bridgesScriptReducer,
  bridgesScripts: bridgesScriptsReducer,
  categories: categoriesReducer,
  dashboard: dashboardReducer,
  discoveries: discoveriesReducer,
  discovery: discoveryReducer,
  discoveryEntrypoints: discoveryEntrypointsReducer,
  discoveryLogs: discoveryLogsReducer,
  discoveryResponseStatuses: discoveryResponseStatusesReducer,
  discoveryTrafficMetrics: discoveryTrafficMetricsReducer,
  entrypoints: entrypointsReducer,
  globalVariables: globalVariablesReducer,
  group: groupReducer,
  groups: groupsReducer,
  integrations: integrationsReducer,
  libraries: librariesReducer,
  orgApiKey: orgApiKeyReducer,
  orgApiKeys: orgApiKeysReducer,
  navigationPermissions: navigationPermissionsReducer,
  repeatersLookup: repeatersLookupReducer,
  role: roleReducer,
  roles: rolesReducer,
  scan: scanReducer,
  scanEntrypoints: scanEntrypointsReducer,
  scanLogs: scanLogsReducer,
  scanResponseStatuses: scanResponseStatusesReducer,
  scanTrafficMetrics: scanTrafficMetricsReducer,
  scans: scansReducer,
  sets: setsReducer,
  sitemap: sitemapReducer,
  targets: targetsReducer,
  target: targetReducer,
  vulnerability: vulnerabilityReducer,
  vulnerabilities: vulnerabilitiesReducer,
  vulnerabilityComments: vulnerabilityCommentsReducer,
  vulnerabilityEntrypoints: vulnerabilityEntrypointsReducer,
  user: userReducer,
  personalSettings: personalSettingsReducer,
  securitySettings: securitySettingsReducer,
  users: usersReducer,
  ipRestrictions: ipRestrictionsReducer,
  ipRestriction: ipRestrictionReducer
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [/.*\.originalRequest(\..*)?/],
        ignoredActionPaths: [/.*\.originalRequest(\..*)?/]
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
