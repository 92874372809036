import { createSvgIcon } from '@mui/material/utils';

export const PropertiesOutlinedIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5354 5.66621L17.55 5.65156V5.63085V2.50002C17.55 2.01241 17.1543 1.61669 16.6667 1.61669H3.33333C2.84572 1.61669 2.45 2.01241 2.45 2.50002V17.5C2.45 17.9876 2.84572 18.3834 3.33333 18.3834H16.6667C17.1543 18.3834 17.55 17.9876 17.55 17.5V12.7017V12.581L17.4646 12.6663L15.798 14.333L15.7833 14.3476V14.3684V16.6167H4.21667V3.38335H15.7833V7.29752V7.41823L15.8687 7.33288L17.5354 5.66621ZM18.1837 7.30466L18.1483 7.26931L18.113 7.30466L11.6313 13.7863L11.6167 13.8009L11.6167 13.8216L11.615 14.9983L11.6149 15.0483L11.6649 15.0484L12.8449 15.05L12.8657 15.05L12.8804 15.0354L19.362 8.55371L19.3974 8.51835L19.362 8.483L18.1837 7.30466ZM10.8833 10V9.95002H10.8333H6.66667H6.61667V10V11.6667V11.7167H6.66667H10.8333H10.8833V11.6667V10ZM13.3833 6.66669V6.61669H13.3333H6.66667H6.61667V6.66669V8.33335V8.38335H6.66667H13.3333H13.3833V8.33335V6.66669Z"
      fill="currentcolor"
      stroke="currentcolor"
      strokeWidth="0.1"
    />
  </svg>,
  'PropertiesOutlinedIcon'
);
