import type { AxiosResponse } from 'axios';
import type {
  EntrypointDetails,
  ExportFormat,
  ID,
  LogEntry,
  PaginationResponse,
  ResponseStatus,
  TrafficMetric
} from '@bright/api';
import { EngineLogsExportStatus } from '@bright/api';
import { axiosInstance, toShortId } from '@bright/core';
import type {
  Discovery,
  DiscoveryDetails,
  DiscoveryEntrypoint,
  RunDiscoveryPayload
} from '../models';
import { DiscoveryBulkAction } from '../models';

export const loadDiscoveries = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<Discovery>> => {
  const response = await axiosInstance.get<PaginationResponse<Discovery>>(
    `/rest/v1/apps/${appId}/discoveries?${queryString}`
  );
  return response.data;
};

export const updateDiscoveryName = async (
  discoveryId: string,
  data: Pick<Discovery, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/discoveries/${discoveryId}`, data);
};

export const runDiscovery = async (appId: string, payload: RunDiscoveryPayload): Promise<ID> => {
  const response = await axiosInstance.post<ID>(`/rest/v1/apps/${appId}/discoveries`, payload);
  return response.data;
};

export const runDiscoveriesBulkAction = async (
  ids: string[],
  action: DiscoveryBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case DiscoveryBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/discoveries/delete`, {
        ids
      });
      break;
    case DiscoveryBulkAction.REDISCOVER:
      response = await axiosInstance.post<ID[]>(`/rest/v1/discoveries/rediscover`, {
        ids
      });
      break;
    case DiscoveryBulkAction.STOP:
      response = await axiosInstance.post<void>(`/rest/v1/discoveries/stop`, {
        ids
      });
      break;
    default:
      throw new Error(`Unknown discovery bulk action: ${action}`);
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportDiscoveries = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/discoveries/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const loadDiscovery = async (discoveryId: string): Promise<DiscoveryDetails> => {
  const response = await axiosInstance.get<DiscoveryDetails>(`/rest/v1/discoveries/${discoveryId}`);
  return response.data;
};

export const exportDiscoveryEntrypointsAsHar = async (
  orgId: string,
  appId: string,
  discoveryId: string
): Promise<AxiosResponse<Blob, any>> => {
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return await axiosInstance.get<Blob>(
      `/api/v2/projects/${toShortId(appId)}/discoveries/${discoveryId}/entry-points/har`,
      {
        responseType: 'blob'
      }
    );
  }

  return await axiosInstance.get<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/discoveries/${discoveryId}/entrypoints/har`,
    {
      responseType: 'blob'
    }
  );
};

export const exportDiscoveryEngineLogs = async (
  orgId: string,
  appId: string,
  discoveryId: string
): Promise<{ status: EngineLogsExportStatus }> => {
  // TODO: remove once discovery engine logs API (ADM-1563) will be ready
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return {
      status: EngineLogsExportStatus.PENDING
    };
  }

  return (
    await axiosInstance.put<{ status: EngineLogsExportStatus }>(
      `/api/v4/orgs/${orgId}/apps/${appId}/discoveries/${discoveryId}/engine-logs`
    )
  ).data;
};

export const loadDiscoveryEntrypoints = async (
  discoveryId: string,
  queryString: string
): Promise<PaginationResponse<DiscoveryEntrypoint>> => {
  const response = await axiosInstance.get<PaginationResponse<DiscoveryEntrypoint>>(
    `/rest/v1/discoveries/${discoveryId}/entrypoints?${queryString}`
  );
  return response.data;
};

export const loadDiscoveryEntrypointDetails = async (
  discoveryId: string,
  entrypointId: string
): Promise<EntrypointDetails> => {
  const response = await axiosInstance.get<EntrypointDetails>(
    `/rest/v1/discoveries/${discoveryId}/entrypoints/${entrypointId}/details`
  );

  return response.data;
};

export const loadDiscoveryTrafficMetrics = async (
  discoveryId: string,
  queryString: string
): Promise<PaginationResponse<TrafficMetric>> => {
  const response = await axiosInstance.get<PaginationResponse<Omit<TrafficMetric, 'id'>>>(
    `/rest/v1/discoveries/${discoveryId}/traffic-metrics?${queryString}`
  );
  return {
    ...response.data,
    items: response.data.items.map(item => ({ ...item, id: item.host! }))
  };
};

export const loadDiscoveryResponseStatuses = async (
  discoveryId: string,
  queryString: string
): Promise<PaginationResponse<ResponseStatus>> => {
  const response = await axiosInstance.get<PaginationResponse<ResponseStatus>>(
    `/rest/v1/discoveries/${discoveryId}/response-statuses?${queryString}`
  );
  return response.data;
};

export const loadDiscoveryLogs = async (
  discoveryId: string,
  queryString: string
): Promise<PaginationResponse<LogEntry>> => {
  const response = await axiosInstance.get<PaginationResponse<LogEntry>>(
    `/rest/v1/discoveries/${discoveryId}/logs?${queryString}`
  );
  return response.data;
};

export const exportDiscoveryLogs = async (
  orgId: string,
  appId: string,
  discoveryId: string
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.get<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/discoveries/${discoveryId}/logs/export`,
    {
      responseType: 'blob'
    }
  );
};
