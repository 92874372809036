import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { Discovery } from '../models';
import {
  exportDiscoveriesAction,
  loadDiscoveriesAction,
  runDiscoveriesBulkActionAction,
  runDiscoveryAction
} from './discoveries.actions';
import { updateDiscoveryNameAction } from './discovery.actions';

export interface DiscoveriesState {
  readonly items: Discovery[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialDiscoveriesState: DiscoveriesState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const discoveriesSlice = createSlice({
  name: 'discoveries',
  initialState: initialDiscoveriesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDiscoveriesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDiscoveriesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadDiscoveriesAction
      )
    );
    builder.addCase(loadDiscoveriesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadDiscoveriesAction
      )
    );

    builder.addCase(updateDiscoveryNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateDiscoveryNameAction.fulfilled, state =>
      cleanupPendingState(state, updateDiscoveryNameAction)
    );
    builder.addCase(updateDiscoveryNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateDiscoveryNameAction
      )
    );

    builder.addCase(runDiscoveryAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runDiscoveryAction.fulfilled, state =>
      cleanupPendingState(state, runDiscoveryAction)
    );
    builder.addCase(runDiscoveryAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runDiscoveriesBulkActionAction
      )
    );

    builder.addCase(runDiscoveriesBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runDiscoveriesBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runDiscoveriesBulkActionAction)
    );
    builder.addCase(runDiscoveriesBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runDiscoveriesBulkActionAction
      )
    );

    builder.addCase(exportDiscoveriesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportDiscoveriesAction.fulfilled, state =>
      cleanupPendingState(state, exportDiscoveriesAction)
    );
    builder.addCase(exportDiscoveriesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportDiscoveriesAction
      )
    );
  }
});

export const discoveriesReducer = discoveriesSlice.reducer;
