import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, PaginationResponse, Target } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { TargetBulkAction, TargetDetails } from '../models';
import {
  exportTargets,
  loadTarget,
  loadTargets,
  runTargetsBulkAction,
  updateTargetName
} from '../services';

export const loadTargetsAction = createAsyncThunk<
  PaginationResponse<Target>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'targets/loadTargets',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadTargets(appId, queryString);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().targets, loadTargetsAction)
  }
);

export const updateTargetNameAction = createAsyncThunk<
  { targetId: string; name: string },
  {
    targetIt: string;
    data: Pick<Target, 'name'>;
  },
  { state: RootState }
>(
  'targets/updateTargetName',
  async ({ targetIt, data }, { rejectWithValue }) => {
    try {
      await updateTargetName(targetIt, data);
      return { targetId: targetIt, name: data.name };
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().targets, updateTargetNameAction)
  }
);

export const runTargetsBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: TargetBulkAction;
  },
  { state: RootState }
>('targets/runTargetsBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runTargetsBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportTargetsAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('targets/exportTargetsAction', async ({ appId, ids, format }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    const response = await exportTargets(orgId, appId, format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const loadTargetDetailsAction = createAsyncThunk<
  TargetDetails,
  { targetId: string },
  { state: RootState }
>(
  'targets/loadTargetDetails',
  async ({ targetId }, { rejectWithValue }) => {
    try {
      return await loadTarget(targetId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ targetId }, { getState }) =>
      actionNotPending(
        getState().targets,
        action =>
          action.type === loadTargetDetailsAction.pending.type &&
          (action as ReturnType<typeof loadTargetDetailsAction.pending>).meta.arg.targetId ===
            targetId
      )
  }
);
