import type { Token } from '@bright/api';

export const TokenStorageService = (() => {
  let token: string | null = null;
  let tokenExpiryDate: Date | null = null;

  return {
    getAccessToken: (): string | null => {
      return token;
    },
    setAccessToken: (value: Token): void => {
      token = value.accessToken;

      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + value.expiresIn * 1000);
      tokenExpiryDate = expiryDate;
    },
    clear: (): void => {
      token = null;
      tokenExpiryDate = null;
    },
    isTokenValid: (): boolean => {
      if (!tokenExpiryDate) {
        return false;
      }
      return new Date() < tokenExpiryDate;
    }
  };
})();
