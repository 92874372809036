export const RequestQueueService = (() => {
  let refreshSubscribers: ((token: string) => void)[] = [];

  return {
    flushRequestQueue: (token: string) => {
      refreshSubscribers.forEach(callback => callback(token));
      refreshSubscribers = [];
    },
    addRequestCallback: (callback: (token: string) => void) => {
      refreshSubscribers.push(callback);
    }
  };
})();
