import { createSvgIcon } from '@mui/material/utils';

export const AuditLogOutlinedIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9417 16.6667H11.7667C11.414 16.6317 11.0816 16.4851 10.8178 16.2484C10.5541 16.0116 10.3727 15.6969 10.3 15.35L8.06666 5.00001L5.76666 10.3333C5.7016 10.4824 5.59435 10.6092 5.45811 10.698C5.32188 10.7869 5.16263 10.8339 4.99999 10.8333H2.49999C2.27898 10.8333 2.06701 10.7455 1.91073 10.5893C1.75445 10.433 1.66666 10.221 1.66666 10C1.66666 9.77899 1.75445 9.56703 1.91073 9.41075C2.06701 9.25447 2.27898 9.16667 2.49999 9.16667H4.44999L6.54166 4.34167C6.68246 4.01789 6.92245 3.74719 7.22702 3.5686C7.5316 3.39001 7.88502 3.31275 8.23633 3.34798C8.58764 3.3832 8.91869 3.52908 9.18175 3.76459C9.4448 4.00009 9.62627 4.31305 9.69999 4.65834L11.9333 15L14.2333 9.68334C14.2958 9.53116 14.402 9.40088 14.5384 9.30894C14.6749 9.21699 14.8355 9.16749 15 9.16667H17.5C17.721 9.16667 17.933 9.25447 18.0892 9.41075C18.2455 9.56703 18.3333 9.77899 18.3333 10C18.3333 10.221 18.2455 10.433 18.0892 10.5893C17.933 10.7455 17.721 10.8333 17.5 10.8333H15.55L13.4583 15.6583C13.3306 15.9554 13.1192 16.209 12.8499 16.388C12.5806 16.567 12.265 16.6639 11.9417 16.6667Z"
      fill="currentcolor"
      stroke="currentcolor"
      strokeWidth="0.2"
    />
  </svg>,
  'AuditLogOutlinedIcon'
);
