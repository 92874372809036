import { useTranslation } from 'react-i18next';
import { ArrowBackIcon } from '@bright/share';
import { ErrorWidget } from './ErrorWidget';

export const GlobalErrorElement = () => {
  const { t } = useTranslation();

  return (
    <ErrorWidget
      text={t('errorPages.internalError.text')}
      link={
        <>
          <ArrowBackIcon sx={{ fontSize: '21px' }} /> {t('errorPages.internalError.linkText')}
        </>
      }
      href={-1}
      errorCode="500"
      errorCodeOverlay={
        <img
          src="/internal-error-overlay.svg"
          style={{
            transform: 'translateY(-8%)',
            height: 'min(26vh, 310px)'
          }}
          alt="Sad eyes"
        />
      }
    />
  );
};
