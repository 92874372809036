import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { generateMfaCodeAction, loadSecuritySettingsAction } from './security-settings.actions';

const selectSecuritySettingsState = (state: RootState) => state.securitySettings;

export const selectSecuritySettings = createSelector(
  selectSecuritySettingsState,
  state => state.securitySettings
);
export const selectSecuritySettingsPendingStatus = createSelector(
  selectSecuritySettingsState,
  state =>
    state.pending.filter(action => action.type === loadSecuritySettingsAction.pending.type).length >
    0
);

export const selectMfaView = createSelector(selectSecuritySettingsState, state => state.mfaView);
export const selectMfaViewPendingStatus = createSelector(
  selectSecuritySettingsState,
  state =>
    state.pending.filter(action => action.type === generateMfaCodeAction.pending.type).length > 0
);

export const selectSecuritySettingsError = createSelector(
  selectSecuritySettingsState,
  state => state.error
);
