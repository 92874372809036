import type { AxiosProgressEvent, AxiosResponse } from 'axios';
import type { ExportFormat, ID, PaginationResponse } from '@bright/api';
import { axiosInstance } from '@bright/core';
import type {
  GlobalVariable,
  GlobalVariableBulkAction,
  ManageGlobalVariablePayload
} from '../models';

export const loadGlobalVariables = async (
  orgId: string,
  appId: string,
  queryString: string
): Promise<PaginationResponse<GlobalVariable>> => {
  const response = await axiosInstance.get<PaginationResponse<GlobalVariable>>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values?${queryString}`
  );
  return response.data;
};

export const loadGlobalVariableDetails = async (
  orgId: string,
  appId: string,
  globalVariableId: string
): Promise<GlobalVariable> => {
  const response = await axiosInstance.get<GlobalVariable>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values/${globalVariableId}`
  );
  return response.data;
};

export const updateGlobalVariableName = async (
  orgId: string,
  appId: string,
  globalVariableId: string,
  data: Pick<GlobalVariable, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values/${globalVariableId}`,
    data
  );
};

export const runGlobalVariablesBulkAction = async (
  orgId: string,
  appId: string,
  ids: string[],
  action: GlobalVariableBulkAction
): Promise<string[] | void> => {
  const response = await axiosInstance.post<ID[] | void>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values/action`,
    {
      ids,
      action
    }
  );

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportGlobalVariables = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createGlobalVariable = async (
  orgId: string,
  appId: string,
  payload: ManageGlobalVariablePayload
): Promise<ID> => {
  const response = await axiosInstance.post<ID>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values`,
    payload
  );

  return response.data;
};

export const updateGlobalVariable = async (
  orgId: string,
  appId: string,
  globalVariableId: string,
  payload: ManageGlobalVariablePayload
): Promise<void> => {
  await axiosInstance.patch<void>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values/${globalVariableId}`,
    payload
  );
};

export const importDefaultValuesFromFile = (
  orgId: string,
  appId: string,
  file: File,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
): { axiosRequest: Promise<AxiosResponse<void, any>>; abortController: AbortController } => {
  const formData = new FormData();
  formData.append('file', file);
  const abortController = new AbortController();

  const axiosRequest = axiosInstance.post<void>(
    `/api/v4/orgs/${orgId}/apps/${appId}/default-values/import`,
    formData,
    {
      responseType: 'json',
      signal: abortController.signal,
      onUploadProgress
    }
  );

  return { axiosRequest, abortController };
};
