import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type EntityPageHistory = { pathname: string; search: string }[];
export const EntityPageHistoryContext = createContext<EntityPageHistory>([]);

export const useAppEntityPageHistory = () => {
  const [history, setHistory] = useState<EntityPageHistory>([]);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.replace(/\/app\/.{36}\//, '');
    const search = location.search;

    // not app level path
    if (!location.pathname?.startsWith('/app') || !pathname.length) {
      setHistory([]);
      return;
    }

    // root entity path
    if (pathname.split('/').length === 1) {
      setHistory([{ pathname, search }]);
      return;
    }

    const parentPagesPathHistory = history.filter(item => !item.pathname.startsWith(pathname));
    setHistory([...parentPagesPathHistory, { pathname, search }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return history;
};
