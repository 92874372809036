import { useContext, useEffect, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';
import { AbsoluteSpinner } from './AbsoluteSpinner';
import { ConfirmDialogContext } from './ConfirmDialog';

interface PageProps {
  readonly title: ReactNode;
  readonly actions?: ReactNode;
  readonly children?: ReactNode;
  readonly titleSx?: SxProps<Theme>;
  readonly pending?: boolean;
}

export const Page: FC<PageProps> = ({ children, title, actions, titleSx, pending }) => {
  const { setDialogContainer } = useContext(ConfirmDialogContext);
  const mainRef = useRef<Element>(null);

  useEffect(() => {
    setDialogContainer(mainRef.current);
  }, [mainRef, setDialogContainer]);

  return (
    <Box
      ref={mainRef}
      component="main"
      sx={{
        borderTopLeftRadius: theme => theme.spacing(5),
        backgroundColor: theme => theme.palette.light.main,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: theme => theme.spacing(3, 4, 1.5, 4),
          gap: theme => theme.spacing(4),
          ...(titleSx ?? {})
        }}
      >
        <Typography variant="h3" sx={{ my: 0.5, flex: 1, overflow: 'hidden', lineHeight: '32px' }}>
          {title}
        </Typography>
        <Stack direction="row" gap={1}>
          {actions}
        </Stack>
      </Stack>
      <Box sx={{ flex: 1, overflow: 'hidden' }}>{pending ? <AbsoluteSpinner /> : children}</Box>
    </Box>
  );
};
