import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Target } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { TargetDetails } from '../models';
import {
  exportTargetsAction,
  loadTargetDetailsAction,
  loadTargetsAction,
  updateTargetNameAction
} from './targets.actions';

export interface TargetsState {
  readonly items: Target[];
  readonly targetDetails: Record<string, TargetDetails | undefined>;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialTargetsState: TargetsState = {
  items: [],
  targetDetails: {},
  total: 0,
  pending: [],
  error: null
};

export const targetsSlice = createSlice({
  name: 'targets',
  initialState: initialTargetsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadTargetsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadTargetsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadTargetsAction
      )
    );
    builder.addCase(loadTargetsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadTargetsAction
      )
    );

    builder.addCase(updateTargetNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateTargetNameAction.fulfilled, state =>
      cleanupPendingState(state, updateTargetNameAction)
    );
    builder.addCase(updateTargetNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateTargetNameAction
      )
    );

    builder.addCase(exportTargetsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportTargetsAction.fulfilled, state =>
      cleanupPendingState(state, exportTargetsAction)
    );
    builder.addCase(exportTargetsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportTargetsAction
      )
    );

    builder.addCase(loadTargetDetailsAction.pending, (state, action) => ({
      ...state,
      targetDetails: {
        ...state.targetDetails,
        [action.meta.arg.targetId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadTargetDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          targetDetails: {
            ...state.targetDetails,
            [action.payload.id]: action.payload
          }
        },
        loadTargetDetailsAction
      )
    );
    builder.addCase(loadTargetDetailsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadTargetDetailsAction
      )
    );
  }
});

export const targetsReducer = targetsSlice.reducer;
