import type { AxiosResponse } from 'axios';
import type { Bridge, ExportFormat, ID, PaginationResponse } from '@bright/api';
import { axiosInstance } from '@bright/core';
import type { ManageBridgePayload } from '../models';
import { BridgeBulkAction } from '../models';

export const loadBridges = async (queryString: string): Promise<PaginationResponse<Bridge>> => {
  const response = await axiosInstance.get<PaginationResponse<Bridge>>(
    `/rest/v1/bridges?${queryString}`
  );
  return response.data;
};

export const loadBridge = async (bridgeId: string): Promise<Bridge> => {
  const response = await axiosInstance.get<Bridge>(`/rest/v1/bridges/${bridgeId}`);
  return response.data;
};

export const updateBridgeName = async (
  bridgeId: string,
  data: Pick<Bridge, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/bridges/${bridgeId}`, data);
};

export const runBridgesBulkAction = async (
  ids: string[],
  action: BridgeBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case BridgeBulkAction.ACTIVATE:
      response = await axiosInstance.post<void>(`/rest/v1/bridges/activate`, {
        ids
      });
      break;
    case BridgeBulkAction.SUSPEND:
      response = await axiosInstance.post<void>(`/rest/v1/bridges/suspend`, {
        ids
      });
      break;
    case BridgeBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/bridges/delete`, {
        ids
      });
      break;
    default: {
      throw new Error(`Unknown bridge bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportBridges = async (
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/bridges/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createBridge = async (payload: ManageBridgePayload): Promise<ID> => {
  return (await axiosInstance.post<ID>(`/rest/v1/bridges`, payload)).data;
};

export const updateBridge = async (
  bridgeId: string,
  payload: ManageBridgePayload
): Promise<void> => {
  await axiosInstance.patch<ID>(`/rest/v1/bridges/${bridgeId}`, payload);
};
