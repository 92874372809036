import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { GlobalVariable } from '../models';
import {
  createGlobalVariableAction,
  loadGlobalVariableDetailsAction,
  loadGlobalVariablesAction,
  runGlobalVariablesBulkActionAction,
  updateGlobalVariableAction,
  updateGlobalVariableNameAction
} from './global-variables.actions';

export interface GlobalVariablesState {
  readonly items: GlobalVariable[];
  readonly globalVariableDetails: Record<string, GlobalVariable | undefined>;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialGlobalVariablesState: GlobalVariablesState = {
  items: [],
  globalVariableDetails: {},
  total: 0,
  pending: [],
  error: null
};

export const globalVariablesSlice = createSlice({
  name: 'globalVariables',
  initialState: initialGlobalVariablesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadGlobalVariablesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadGlobalVariablesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadGlobalVariablesAction
      )
    );
    builder.addCase(loadGlobalVariablesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadGlobalVariablesAction
      )
    );

    builder.addCase(loadGlobalVariableDetailsAction.pending, (state, action) => ({
      ...state,
      globalVariableDetails: {
        ...state.globalVariableDetails,
        [action.meta.arg.globalVariableId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadGlobalVariableDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          globalVariableDetails: {
            ...state.globalVariableDetails,
            [action.payload.id]: action.payload
          }
        },
        loadGlobalVariableDetailsAction
      )
    );
    builder.addCase(loadGlobalVariableDetailsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadGlobalVariableDetailsAction
      )
    );

    builder.addCase(updateGlobalVariableNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateGlobalVariableNameAction.fulfilled, state =>
      cleanupPendingState(state, updateGlobalVariableNameAction)
    );
    builder.addCase(updateGlobalVariableNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateGlobalVariableNameAction
      )
    );

    builder.addCase(runGlobalVariablesBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runGlobalVariablesBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runGlobalVariablesBulkActionAction)
    );
    builder.addCase(
      runGlobalVariablesBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runGlobalVariablesBulkActionAction
        )
    );

    builder.addCase(createGlobalVariableAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createGlobalVariableAction.fulfilled, state =>
      cleanupPendingState(state, createGlobalVariableAction)
    );
    builder.addCase(createGlobalVariableAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createGlobalVariableAction
      )
    );

    builder.addCase(updateGlobalVariableAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateGlobalVariableAction.fulfilled, state =>
      cleanupPendingState(state, updateGlobalVariableAction)
    );
    builder.addCase(updateGlobalVariableAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateGlobalVariableAction
      )
    );
  }
});

export const globalVariablesReducer = globalVariablesSlice.reducer;
