import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Bridge, ID } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManageBridgePayload } from '../models';
import { createBridge, loadBridge, updateBridge } from '../services';

export const loadBridgeAction = createAsyncThunk<
  Bridge,
  {
    bridgeId: string;
  },
  { state: RootState }
>(
  'bridge/loadBridge',
  async ({ bridgeId }, { rejectWithValue }) => {
    try {
      return await loadBridge(bridgeId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().bridge, loadBridgeAction)
  }
);

export const updateBridgeAction = createAsyncThunk<
  void,
  {
    bridgeId: string;
    payload: ManageBridgePayload;
  },
  { state: RootState }
>(
  'bridge/updateBridge',
  async ({ bridgeId, payload }, { rejectWithValue }) => {
    try {
      return await updateBridge(bridgeId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().bridge, updateBridgeAction)
  }
);

export const createBridgeAction = createAsyncThunk<
  ID,
  {
    payload: ManageBridgePayload;
  },
  { state: RootState }
>(
  'bridge/createBridge',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await createBridge(payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().bridge, createBridgeAction)
  }
);
