import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type {
  ApiError,
  Entrypoint,
  EntrypointDetails,
  ExportFormat,
  ID,
  PaginationResponse
} from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { EntrypointBulkAction, EntrypointsImportPayload } from '../models';
import {
  exportEntrypoints,
  importEntrypoints,
  loadAllEntrypoints,
  loadEntrypointDetails,
  loadEntrypoints,
  runEntrypointsBulkAction
} from '../services';

export const loadEntrypointsAction = createAsyncThunk<
  PaginationResponse<Entrypoint>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'entrypoints/loadEntrypoints',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadEntrypoints(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().entrypoints, loadEntrypointsAction)
  }
);

export const loadAllEntrypointsAction = createAsyncThunk<
  Entrypoint[],
  {
    appId: string;
    params: URLSearchParams;
    limit?: number;
  },
  { state: RootState }
>(
  'entrypoints/loadAllEntrypoints',
  async ({ appId, limit, params }, { rejectWithValue, signal }) => {
    try {
      return await loadAllEntrypoints(appId, signal, params, limit);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().entrypoints, loadAllEntrypointsAction)
  }
);

export const clearAllEntrypointsAction = createAsyncThunk<void, undefined, { state: RootState }>(
  'entrypoints/clearAllEntrypoints',
  () => Promise.resolve()
);

export const loadEntrypointDetailsAction = createAsyncThunk<
  EntrypointDetails,
  {
    entrypointId: string;
  },
  { state: RootState }
>(
  'entrypoints/loadEntrypointDetails',
  async ({ entrypointId }, { rejectWithValue }) => {
    try {
      return await loadEntrypointDetails(entrypointId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ entrypointId }, { getState }) =>
      actionNotPending(
        getState().entrypoints,
        action =>
          action.type === loadEntrypointDetailsAction.pending.type &&
          (action as ReturnType<typeof loadEntrypointDetailsAction.pending>).meta.arg
            .entrypointId === entrypointId
      )
  }
);

export const runEntrypointsBulkActionAction = createAsyncThunk<
  string | void,
  {
    ids: string[];
    action: EntrypointBulkAction;
  }
>('entrypoints/runEntrypointsBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runEntrypointsBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportEntrypointsAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>(
  'entrypoints/exportEntrypointsAction',
  async ({ appId, ids, format }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const response = await exportEntrypoints(orgId, appId, format, ids);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
      fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const importEntrypointsAction = createAsyncThunk<
  ID,
  {
    appId: string;
    payload: EntrypointsImportPayload;
  },
  { state: RootState }
>('entrypoints/importEntrypointsAction', async ({ appId, payload }, { rejectWithValue }) => {
  try {
    return importEntrypoints(appId, payload);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
