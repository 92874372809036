import type { RouteObject } from 'react-router-dom';

export const integrationsRoutes: RouteObject[] = [
  {
    path: 'integration-hub',
    async lazy() {
      const { IntegrationsPage } = await import('./pages');
      return { Component: IntegrationsPage };
    }
  }
];
