import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { DashboardData } from '../models';
import { loadDashboardDataAction } from './dashboard.actions';

export interface DashboardState {
  readonly data: DashboardData | null;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialDashboardState: DashboardState = {
  data: null,
  pending: [],
  error: null
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDashboardDataAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDashboardDataAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          data: action.payload
        },
        loadDashboardDataAction
      )
    );
    builder.addCase(loadDashboardDataAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadDashboardDataAction
      )
    );
  }
});

export const dashboardReducer = dashboardSlice.reducer;
