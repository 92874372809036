import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, EntrypointDetails, PaginationResponse } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { DiscoveryEntrypoint } from '../models';
import { loadDiscoveryEntrypointDetails, loadDiscoveryEntrypoints } from '../services';

export const loadDiscoveryEntrypointsAction = createAsyncThunk<
  PaginationResponse<DiscoveryEntrypoint>,
  { discoveryId: string; queryString: string },
  { state: RootState; rejectedValue: ApiError }
>(
  'discovery/loadDiscoveryEntrypoints',
  async ({ discoveryId, queryString }, { rejectWithValue }) => {
    try {
      if (!discoveryId) {
        return rejectWithValue('Discovery is not available');
      }
      return loadDiscoveryEntrypoints(discoveryId, queryString);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().discoveryEntrypoints, loadDiscoveryEntrypointsAction)
  }
);

export const loadDiscoveryEntrypointDetailsAction = createAsyncThunk<
  EntrypointDetails,
  {
    discoveryId: string;
    entrypointId: string;
  },
  { state: RootState }
>(
  'entrypoints/loadEntrypointDetails',
  async ({ discoveryId, entrypointId }, { rejectWithValue }) => {
    try {
      return await loadDiscoveryEntrypointDetails(discoveryId, entrypointId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ entrypointId }, { getState }) =>
      actionNotPending(
        getState().entrypoints,
        action =>
          action.type === loadDiscoveryEntrypointDetailsAction.pending.type &&
          (action as ReturnType<typeof loadDiscoveryEntrypointDetailsAction.pending>).meta.arg
            .entrypointId === entrypointId
      )
  }
);
