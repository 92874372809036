import type { FC, MouseEventHandler } from 'react';
import Box from '@mui/material/Box';
import Button, { buttonClasses } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import type { LoggedInUser } from '@bright/api';
import { getAvatarInitials, TextWithEllipsis, UserAvatar } from '@bright/share';

export const UserProfileMenuAnchor: FC<{
  readonly user: LoggedInUser;
  readonly organizationName: string;
  readonly onClick: MouseEventHandler;
}> = ({ user, organizationName, onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      disableRipple
      disableFocusRipple
      aria-label={t('topBar.user.ariaLabelMenuAnchor')}
      sx={{
        borderRadius: theme => theme.spacing(3),
        padding: theme => theme.spacing(0.5, 1),
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: theme => theme.palette.custom.userProfile.bg.hover
        },
        [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
          backgroundColor: theme => theme.palette.custom.userProfile.bg.focus
        }
      }}
    >
      <UserAvatar size="sm">{getAvatarInitials(user)}</UserAvatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          ml: 1,
          overflow: 'hidden',
          textAlign: 'start',
          maxWidth: 108
        }}
      >
        <TextWithEllipsis
          sx={{
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '20px',
            color: theme => theme.palette.custom.userProfile.username
          }}
        >
          {user.name}
        </TextWithEllipsis>
        <TextWithEllipsis
          sx={{
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '18px',
            color: theme => theme.palette.common.white
          }}
        >
          {organizationName}
        </TextWithEllipsis>
      </Box>
    </Button>
  );
};
