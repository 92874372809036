import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Bridge } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { createBridgeAction, loadBridgeAction, updateBridgeAction } from './bridge.actions';

export interface BridgeState {
  readonly bridge: Bridge | null;
  readonly pending: Action[];
}

const initialBridgeState: BridgeState = {
  bridge: null,
  pending: []
};

export const bridgeSlice = createSlice({
  name: 'bridge',
  initialState: initialBridgeState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadBridgeAction.pending, (state, action) => ({
      ...state,
      bridge: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBridgeAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          bridge: action.payload
        },
        loadBridgeAction
      )
    );
    builder.addCase(loadBridgeAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBridgeAction
      )
    );

    builder.addCase(updateBridgeAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateBridgeAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateBridgeAction)
    );
    builder.addCase(updateBridgeAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateBridgeAction
      )
    );

    builder.addCase(createBridgeAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createBridgeAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createBridgeAction)
    );
    builder.addCase(createBridgeAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createBridgeAction
      )
    );
  }
});

export const bridgeReducer = bridgeSlice.reducer;
