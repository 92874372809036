import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, PaginationResponse, ResponseStatus } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadDiscoveryResponseStatuses } from '../services';

export const loadDiscoveryResponseStatusesAction = createAsyncThunk<
  PaginationResponse<ResponseStatus>,
  {
    discoveryId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'discovery/loadDiscoveryResponseStatuses',
  async ({ discoveryId, queryString }, { rejectWithValue }) => {
    try {
      if (!discoveryId) {
        return rejectWithValue('Discovery is not available');
      }
      return loadDiscoveryResponseStatuses(discoveryId, queryString);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().discoveryResponseStatuses, loadDiscoveryResponseStatusesAction)
  }
);
