import { createSlice } from '@reduxjs/toolkit';
import type { Action, PayloadAction } from '@reduxjs/toolkit';
import type { ApiError, LoggedInUser } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadUserInfoAction, loginAction, loginRedirect, logoutAction } from './auth.actions';

export interface AuthState {
  readonly pending: Action[];
  readonly userInfo: LoggedInUser | null;
  readonly error: ApiError | null;
}

export const initialAuthState: AuthState = {
  pending: [],
  userInfo: null,
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loginAction.fulfilled, state => cleanupPendingState(state, loginAction));
    builder.addCase(loginAction.rejected, state => {
      cleanupPendingState(state, loginAction);
    });
    builder.addCase(loadUserInfoAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadUserInfoAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          userInfo: action.payload
        },
        loadUserInfoAction
      )
    );
    builder.addCase(loadUserInfoAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          userInfo: null,
          error: action.payload
        },
        loadUserInfoAction
      )
    );
    builder.addCase(logoutAction.fulfilled, state =>
      cleanupPendingState(
        {
          ...state,
          ...initialAuthState
        },
        logoutAction
      )
    );
    builder.addCase(loginRedirect.pending, state => ({
      ...state,
      ...initialAuthState
    }));
  }
});

export const authReducer = authSlice.reducer;
