import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { PersonalSettings } from '../models';
import {
  loadPersonalSettingsAction,
  updatePersonalSettingsAction
} from './personal-settings.actions';

export interface PersonalSettingsState {
  readonly personalSettings: PersonalSettings | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialPersonalSettingsState: PersonalSettingsState = {
  personalSettings: null,
  pending: [],
  error: null
};

export const personalSettingsSlice = createSlice({
  name: 'personalSettings',
  initialState: initialPersonalSettingsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPersonalSettingsAction.pending, (state, action) => ({
      ...state,
      personalSettings: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadPersonalSettingsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          personalSettings: action.payload
        },
        loadPersonalSettingsAction
      )
    );
    builder.addCase(loadPersonalSettingsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadPersonalSettingsAction
      )
    );

    builder.addCase(updatePersonalSettingsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updatePersonalSettingsAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updatePersonalSettingsAction)
    );
    builder.addCase(updatePersonalSettingsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updatePersonalSettingsAction
      )
    );
  }
});

export const personalSettingsReducer = personalSettingsSlice.reducer;
