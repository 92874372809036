import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, NavigationPermissions } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import { actionNotPending, toLongId } from '@bright/core';
import type { RootState } from '@bright/core';
import { loadNavigationPermissions } from '../service';

export const loadNavigationPermissionsAction = createAsyncThunk<
  NavigationPermissions,
  {
    queryString: string;
  },
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'navigationPermissions/loadNavigationPermissions',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadNavigationPermissions(toLongId(orgId), queryString);
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().navigationPermissions, loadNavigationPermissionsAction)
  }
);
