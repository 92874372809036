import type { AxiosResponse } from 'axios';
import type {
  EngineLogsExportStatus,
  ExportFormat,
  ID,
  LogEntry,
  PaginationResponse,
  ResponseStatus,
  TrafficMetric
} from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';
import type {
  RunScanPayload,
  Scan,
  ScanDetails,
  ScanEntrypoint,
  ScanEntrypointTest
} from '../models';
import { ScanBulkAction } from '../models';

export const loadScans = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<Scan>> => {
  const response = await axiosInstance.get<PaginationResponse<Scan>>(
    `/rest/v1/apps/${appId}/scans?${queryString}`
  );
  return response.data;
};

export const updateScanName = async (scanId: string, data: Pick<Scan, 'name'>): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/scans/${scanId}`, data);
};

export const runScan = async (appId: string, payload: RunScanPayload): Promise<ID> => {
  const response = await axiosInstance.post<ID>(`/rest/v1/apps/${appId}/scans`, payload);
  return response.data;
};

export const runScansBulkAction = async (
  ids: string[],
  action: ScanBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case ScanBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/scans/delete`, {
        ids
      });
      break;
    case ScanBulkAction.RESCAN:
      response = await axiosInstance.post<ID[]>(`/rest/v1/scans/rescan`, {
        ids
      });
      break;
    case ScanBulkAction.STOP:
      response = await axiosInstance.post<void>(`/rest/v1/scans/stop`, {
        ids
      });
      break;
    default:
      throw new Error(`Unknown scan bulk action: ${action}`);
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportScans = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/scans/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const loadScan = async (scanId: string): Promise<ScanDetails> => {
  const response = await axiosInstance.get<ScanDetails>(`/rest/v1/scans/${scanId}`);
  return response.data;
};

export const exportScanEngineLogs = async (
  orgId: string,
  appId: string,
  scanId: string
): Promise<{ status: EngineLogsExportStatus }> => {
  return (
    await axiosInstance.put<{ status: EngineLogsExportStatus }>(
      `/api/v4/orgs/${orgId}/apps/${appId}/scans/${scanId}/engine-logs`
    )
  ).data;
};

export const loadScanEntrypoints = async (
  scanId: string,
  queryString: string
): Promise<PaginationResponse<ScanEntrypoint>> => {
  const response = await axiosInstance.get<PaginationResponse<ScanEntrypoint>>(
    `/rest/v1/scans/${scanId}/entrypoints?${queryString}`
  );
  return response.data;
};

export const loadScanEntrypointTests = async (
  scanId: string,
  entypointId: string
): Promise<ScanEntrypointTest[]> => {
  return fetchAllPages<ScanEntrypointTest>(`/rest/v1/scans/${scanId}/entrypoints/${entypointId}`);
};

export const loadScanEntrypoint = async (
  scanId: string,
  queryString: string
): Promise<PaginationResponse<ScanEntrypoint>> => {
  const response = await axiosInstance.get<PaginationResponse<ScanEntrypoint>>(
    `/rest/v1/scans/${scanId}/entrypoints?${queryString}`
  );
  return response.data;
};

export const loadScanResponseStatuses = async (
  scanId: string,
  queryString: string
): Promise<PaginationResponse<ResponseStatus>> => {
  const response = await axiosInstance.get<PaginationResponse<ResponseStatus>>(
    `/rest/v1/scans/${scanId}/response-statuses?${queryString}`
  );
  return response.data;
};

export const loadScanTrafficMetrics = async (
  scanId: string,
  queryString: string
): Promise<PaginationResponse<TrafficMetric>> => {
  const response = await axiosInstance.get<PaginationResponse<Omit<TrafficMetric, 'id'>>>(
    `/rest/v1/scans/${scanId}/traffic-metrics?${queryString}`
  );
  return {
    ...response.data,
    items: response.data.items.map(item => ({ ...item, id: item.host! }))
  };
};

export const loadScanLogs = async (
  scanId: string,
  queryString: string
): Promise<PaginationResponse<LogEntry>> => {
  const response = await axiosInstance.get<PaginationResponse<LogEntry>>(
    `/rest/v1/scans/${scanId}/logs?${queryString}`
  );
  return response.data;
};

export const exportScanLogs = async (
  orgId: string,
  appId: string,
  scanId: string
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.get<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/scans/${scanId}/logs/export`,
    {
      responseType: 'blob'
    }
  );
};
