import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { VulnerabilityEntrypoint, VulnerabilityEntrypointDetails } from '../models';
import {
  loadVulnerabilityEntrypointDetailsAction,
  loadVulnerabilityEntrypointsAction,
  runVulnerabilityEntrypointsBulkActionAction
} from './vulnerability-entrypoints.actions';

export interface VulnerabilityEntrypointsState {
  readonly items: VulnerabilityEntrypoint[];
  readonly vulnerabilityEntrypointDetails: Record<
    string,
    VulnerabilityEntrypointDetails | undefined
  >;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialVulnerabilityEntrypointsState: VulnerabilityEntrypointsState = {
  items: [],
  vulnerabilityEntrypointDetails: {},
  total: 0,
  pending: [],
  error: null
};

export const vulnerabilityEntrypointsSlice = createSlice({
  name: 'vulnerabilityEntrypoints',
  initialState: initialVulnerabilityEntrypointsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadVulnerabilityEntrypointsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadVulnerabilityEntrypointsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadVulnerabilityEntrypointsAction
      )
    );
    builder.addCase(
      loadVulnerabilityEntrypointsAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          loadVulnerabilityEntrypointsAction
        )
    );

    builder.addCase(loadVulnerabilityEntrypointDetailsAction.pending, (state, action) => ({
      ...state,
      vulnerabilityEntrypointDetails: {
        ...state.vulnerabilityEntrypointDetails,
        [action.meta.arg.vulnerabilityEntrypointId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadVulnerabilityEntrypointDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          vulnerabilityEntrypointDetails: {
            ...state.vulnerabilityEntrypointDetails,
            [action.payload.id]: action.payload
          }
        },
        loadVulnerabilityEntrypointDetailsAction
      )
    );
    builder.addCase(
      loadVulnerabilityEntrypointDetailsAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          loadVulnerabilityEntrypointDetailsAction
        )
    );

    builder.addCase(runVulnerabilityEntrypointsBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runVulnerabilityEntrypointsBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runVulnerabilityEntrypointsBulkActionAction)
    );
    builder.addCase(
      runVulnerabilityEntrypointsBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runVulnerabilityEntrypointsBulkActionAction
        )
    );
  }
});

export const vulnerabilityEntrypointsReducer = vulnerabilityEntrypointsSlice.reducer;
