import type { FC, PropsWithChildren } from 'react';
import MuiCssBaseline from '@mui/material/CssBaseline';

/**
 * `<CssBaseline />` - A component that provides a consistent baseline for CSS across the application.
 *
 * This component ensures that the baseline styles are applied.
 * It should be used at the root of your component tree to ensure that all child components inherit the baseline styles.
 *
 * @example
 * ```tsx
 *
 * const App = () => (
 *   <>
 *     <CssBaseline>
 *     <YourComponent />
 *   </>
 * );
 * ```
 */
export const CssBaseline: FC<PropsWithChildren> = ({ children }) => (
  <>
    <MuiCssBaseline />
    {children}
  </>
);
