import { createSvgIcon } from '@mui/material/utils';

export const DiscoveriesOutlinedIcon = createSvgIcon(
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.1667 8.83333C15.0076 8.83333 16.5 10.3257 16.5 12.1667C16.5 14.0076 15.0076 15.5 13.1667 15.5C11.3257 15.5 9.83332 14.0076 9.83332 12.1667H8.16666C8.16666 14.0076 6.67427 15.5 4.83332 15.5C2.99237 15.5 1.49999 14.0076 1.49999 12.1667C1.49999 10.3257 2.99237 8.83333 4.83332 8.83333C6.06709 8.83333 7.1443 9.50358 7.72066 10.4999H10.2793C10.8557 9.50358 11.9329 8.83333 13.1667 8.83333ZM4.83332 10.5C3.91285 10.5 3.16666 11.2462 3.16666 12.1667C3.16666 13.0872 3.91285 13.8333 4.83332 13.8333C5.7538 13.8333 6.49999 13.0872 6.49999 12.1667C6.49999 11.2462 5.7538 10.5 4.83332 10.5ZM13.1667 10.5C12.2462 10.5 11.5 11.2462 11.5 12.1667C11.5 13.0872 12.2462 13.8333 13.1667 13.8333C14.0872 13.8333 14.8333 13.0872 14.8333 12.1667C14.8333 11.2462 14.0872 10.5 13.1667 10.5ZM12.3333 0.5C14.1742 0.5 15.6667 1.99238 15.6667 3.83333V6.33333H17.3333V8H0.666656V6.33333H2.33332V3.83333C2.33332 1.99238 3.82571 0.5 5.66666 0.5H12.3333ZM12.3333 2.16667H5.66666C4.78802 2.16667 3.99999 2.95833 3.99999 3.83333V6.33333H14V3.83333C14 2.9547 13.2083 2.16667 12.3333 2.16667Z" />
  </svg>,
  'DiscoveriesOutlinedIcon'
);
