import type { RouteObject } from 'react-router-dom';

export const auditLogRoutes: RouteObject[] = [
  {
    path: 'audit-log',
    async lazy() {
      const { AuditLogPage } = await import('./pages');
      return { Component: AuditLogPage };
    }
  }
];
