import { buttonBaseClasses } from '@mui/material/ButtonBase';
import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

export const HeaderIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.custom.sidebar.listItem.fg,
  '&:hover': {
    backgroundColor: theme.palette.custom.sidebar.listItem.hover.bg,
    color: theme.palette.custom.sidebar.listItem.hover.fg
  },
  [`&.${buttonBaseClasses.focusVisible}`]: {
    backgroundColor: theme.palette.custom.sidebar.listItem.focus.bg,
    color: theme.palette.custom.sidebar.listItem.focus.fg
  },
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1.5rem'
  }
}));
