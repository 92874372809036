import type { AxiosResponse } from 'axios';
import type {
  BasicBridgesScript,
  BridgesScript,
  ExportFormat,
  ID,
  PaginationResponse
} from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';
import type { BridgesScriptDetails, ManageBridgesScriptPayload } from '../models';
import { BridgesScriptBulkAction } from '../models';

export const loadBridgesScripts = async (
  queryString: string
): Promise<PaginationResponse<BridgesScript>> => {
  const response = await axiosInstance.get<PaginationResponse<BridgesScript>>(
    `/rest/v1/scripts?${queryString}`
  );
  return response.data;
};

export const loadBridgesScript = async (scriptId: string): Promise<BridgesScriptDetails> => {
  const response = await axiosInstance.get<BridgesScriptDetails>(`/rest/v1/scripts/${scriptId}`);
  return response.data;
};

export const updateBridgesScriptName = async (
  scriptId: string,
  data: Pick<BridgesScript, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/scripts/${scriptId}`, data);
};

export const runBridgesScriptsBulkAction = async (
  ids: string[],
  action: BridgesScriptBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case BridgesScriptBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/scripts/delete`, {
        ids
      });
      break;
    default: {
      throw new Error(`Unknown bridges script bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportBridgesScripts = async (
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/scripts/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createBridgesScript = async (payload: ManageBridgesScriptPayload): Promise<ID> => {
  return (await axiosInstance.post<ID>(`/rest/v1/scripts`, payload)).data;
};

export const updateBridgesScript = async (
  scriptId: string,
  payload: ManageBridgesScriptPayload
): Promise<void> => {
  await axiosInstance.patch<ID>(`/rest/v1/scripts/${scriptId}`, payload);
};

export const loadBasicBridgesScripts = async (): Promise<BasicBridgesScript[]> => {
  const url = `/rest/v1/scripts`;
  const allScripts = fetchAllPages<BridgesScript>(url);

  return allScripts.then(allScripts => {
    return allScripts.map(({ id, name }: BasicBridgesScript) => ({
      id,
      name
    }));
  });
};
