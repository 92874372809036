import type { AxiosResponse } from 'axios';
import type { BasicModel, ExportFormat, ID, PaginationResponse } from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';
import type {
  Authentication,
  AuthenticationDetails,
  AuthenticationDetailsOptionalId,
  AuthenticationOtpConfig,
  AuthenticationTestResult,
  AuthenticatorConfig,
  EmailOtpConfig,
  HotpConfig,
  ManageAuthenticationPayload,
  OtpToken,
  TotpConfig
} from '../models';
import { AuthenticationBulkAction, NewAuthenticationTestResultsKey, OtpType } from '../models';

export const loadAuthenticationsLookup = async (appId: string): Promise<BasicModel[]> => {
  const params = new URLSearchParams();
  params.append('pageSize', '100');
  params.append('fields[]', 'name');

  const url = `/rest/v1/apps/${appId}/authentications?${params.toString()}`;
  return fetchAllPages<BasicModel>(url);
};

export const loadAuthentications = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<Authentication>> => {
  const response = await axiosInstance.get<PaginationResponse<Authentication>>(
    `/rest/v1/apps/${appId}/authentications?${queryString}`
  );
  return response.data;
};

export const loadAuthentication = async (
  authenticationId: string
): Promise<AuthenticationDetails> => {
  return (
    await axiosInstance.get<AuthenticationDetails>(`/rest/v1/authentications/${authenticationId}`)
  ).data;
};

export const runAuthenticationsBulkAction = async (
  ids: string[],
  action: AuthenticationBulkAction
): Promise<ID[] | void> => {
  switch (action) {
    case AuthenticationBulkAction.DELETE:
      await axiosInstance.post<void>(`/rest/v1/authentications/delete`, { ids });
      break;
    case AuthenticationBulkAction.DUPLICATE:
      return (await axiosInstance.post<ID[]>(`/rest/v1/authentications/duplicate`, { ids })).data;
    default:
      throw new Error(`Unsupported action: ${action}`);
  }
};

export const exportAuthentications = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/auth-objects/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createAuthentication = async (
  appId: string,
  payload: ManageAuthenticationPayload
): Promise<void> => {
  await axiosInstance.post<void>(`/rest/v1/apps/${appId}/authentications`, payload);
};

export const updateAuthentication = async (
  authenticationId: string,
  payload: Partial<ManageAuthenticationPayload>
): Promise<void> => {
  await axiosInstance.put<void>(`/rest/v1/authentications/${authenticationId}`, payload);
};

export const updateAuthenticationName = async (
  authenticationId: string,
  data: Pick<Authentication, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/authentications/${authenticationId}`, data);
};

export const testAuthentication = async (
  appId: string,
  { id, ...authentication }: AuthenticationDetailsOptionalId
): Promise<AuthenticationTestResult[]> => {
  return (
    await axiosInstance.post<AuthenticationTestResult[]>(
      `/rest/v1/apps/${appId}/authentications/test`,
      {
        ...authentication,
        id: id === NewAuthenticationTestResultsKey ? undefined : id
      }
    )
  )?.data;
};

export const testAuthenticationOtp = async (
  appId: string,
  otpConfig: AuthenticationOtpConfig
): Promise<OtpToken> => {
  if (isEmailOtpConfig(otpConfig)) {
    throw new Error('Email OTP could not be tested');
  }
  const payload: HotpConfig | TotpConfig | AuthenticatorConfig = {
    type: otpConfig.type!,
    name: otpConfig.name,
    secret: otpConfig.secret,
    digits: otpConfig.digits,
    algorithm: otpConfig.algorithm,
    ...((otpConfig.type === OtpType.AUTHENTICATOR ? { step: otpConfig.step } : {}) as Pick<
      AuthenticatorConfig,
      'step'
    >)
  };

  return (
    await axiosInstance.post<OtpToken>(`/rest/v1/apps/${appId}/authentications/test-otp`, payload)
  )?.data;
};

const isEmailOtpConfig = (otpConfig: AuthenticationOtpConfig): otpConfig is EmailOtpConfig =>
  otpConfig.type === OtpType.EMAIL;
