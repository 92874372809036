import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { Integration } from '../models';
import { loadIntegrations } from '../services';

export const loadIntegrationsAction = createAsyncThunk<
  PaginationResponse<Integration>,
  void,
  { state: RootState }
>(
  'integrations/loadIntegrations',
  async (_, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadIntegrations(orgId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().integrations, loadIntegrationsAction)
  }
);
