import { createAsyncThunk } from '@reduxjs/toolkit';
import type { UserComment } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import {
  addVulnerabilityComment,
  deleteVulnerabilityComment,
  loadVulnerabilityComments
} from '../services';

export const loadVulnerabilityCommentsAction = createAsyncThunk<
  UserComment[],
  {
    appId: string;
    vulnerabilityId: string;
    force?: boolean;
  },
  { state: RootState }
>(
  'vulnerabilityComments/loadVulnerabilityComments',
  async ({ appId, vulnerabilityId }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadVulnerabilityComments(orgId, appId, vulnerabilityId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerability, loadVulnerabilityCommentsAction)
  }
);

export const addVulnerabilityCommentAction = createAsyncThunk<
  void,
  {
    appId: string;
    vulnerabilityId: string;
    body: string;
  },
  { state: RootState }
>(
  'vulnerabilityComments/addVulnerabilityComment',
  async ({ appId, vulnerabilityId, body }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await addVulnerabilityComment(orgId, appId, vulnerabilityId, body);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerability, addVulnerabilityCommentAction)
  }
);

export const deleteVulnerabilityCommentAction = createAsyncThunk<
  void,
  {
    appId: string;
    vulnerabilityId: string;
    commentId: string;
  },
  { state: RootState }
>(
  'vulnerabilityComments/deleteVulnerabilityComment',
  async ({ appId, vulnerabilityId, commentId }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await deleteVulnerabilityComment(orgId, appId, vulnerabilityId, commentId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerability, deleteVulnerabilityCommentAction)
  }
);
