import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, PaginationResponse, RoleListItem } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { RoleListItemBulkAction } from '../models';
import { exportRoles, loadRoles, runRolesBulkAction, updateRoleName } from '../services';

export const loadRolesAction = createAsyncThunk<
  PaginationResponse<RoleListItem>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'roles/loadRoles',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await loadRoles(orgId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().roles, loadRolesAction)
  }
);

export const updateRoleNameAction = createAsyncThunk<
  { roleId: string; name: string },
  {
    roleId: string;
    data: Pick<RoleListItem, 'name'>;
  },
  { state: RootState }
>('roles/updateRoleName', async ({ roleId, data }, { rejectWithValue }) => {
  try {
    await updateRoleName(roleId, data);
    return { roleId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const runRolesBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: RoleListItemBulkAction;
  },
  { state: RootState }
>('roles/runRolesBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runRolesBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportRolesAction = createAsyncThunk<
  void,
  {
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('roles/exportRolesAction', async ({ ids, format }, { getState, rejectWithValue }) => {
  const orgId = selectOwnOrganizationId(getState());
  try {
    const response = await exportRoles(orgId, format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
