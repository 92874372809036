import type { RouteObject } from 'react-router-dom';

export const entrypointsRoutes: RouteObject[] = [
  {
    path: 'entrypoints',
    async lazy() {
      const { EntrypointsPage } = await import('./pages');
      return { Component: EntrypointsPage };
    },
    children: [
      {
        path: 'import',
        async lazy() {
          const { ImportEntrypointsPage } = await import('./pages');
          return { Component: ImportEntrypointsPage };
        }
      }
    ]
  }
];
