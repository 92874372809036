import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, ID, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { RunScanPayload, Scan, ScanBulkAction } from '../models';
import { exportScans, loadScans, runScan, runScansBulkAction } from '../services';

export const loadScansAction = createAsyncThunk<
  PaginationResponse<Scan>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'scans/loadScans',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadScans(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().scans, loadScansAction)
  }
);

export const runScanAction = createAsyncThunk<
  ID,
  {
    appId: string;
    payload: RunScanPayload;
  },
  { state: RootState; rejectValue: ApiError }
>('scans/runScan', async ({ appId, payload }, { rejectWithValue }) => {
  try {
    return await runScan(appId, payload);
  } catch (err: unknown) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const runScansBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: ScanBulkAction;
  },
  { state: RootState }
>('scans/runScansBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runScansBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportScansAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('scans/exportScansAction', async ({ appId, ids, format }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    const response = await exportScans(orgId, appId, format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
