import { createSvgIcon } from '@mui/material/utils';

export const DiscoveriesIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.1667 10.8333C16.0076 10.8333 17.5 12.3257 17.5 14.1667C17.5 16.0076 16.0076 17.5 14.1667 17.5C12.3258 17.5 10.8334 16.0076 10.8334 14.1667H9.16669C9.16669 16.0076 7.6743 17.5 5.83335 17.5C3.9924 17.5 2.50002 16.0076 2.50002 14.1667C2.50002 12.3257 3.9924 10.8333 5.83335 10.8333C7.06712 10.8333 8.14433 11.5036 8.72069 12.4999H11.2794C11.8557 11.5036 12.9329 10.8333 14.1667 10.8333ZM5.83335 12.5C4.91288 12.5 4.16669 13.2462 4.16669 14.1667C4.16669 15.0872 4.91288 15.8333 5.83335 15.8333C6.75383 15.8333 7.50002 15.0872 7.50002 14.1667C7.50002 13.2462 6.75383 12.5 5.83335 12.5ZM14.1667 12.5C13.2462 12.5 12.5 13.2462 12.5 14.1667C12.5 15.0872 13.2462 15.8333 14.1667 15.8333C15.0872 15.8333 15.8334 15.0872 15.8334 14.1667C15.8334 13.2462 15.0872 12.5 14.1667 12.5ZM13.3334 2.5C15.1743 2.5 16.6667 3.99238 16.6667 5.83333V8.33333H18.3334V10H1.66669V8.33333H3.33335V5.83333C3.33335 3.99238 4.82574 2.5 6.66669 2.5H13.3334ZM13.3334 4.16667H6.66669C5.78805 4.16667 5.00002 4.95833 5.00002 5.83333V8.33333H15V5.83333C15 4.9547 14.2084 4.16667 13.3334 4.16667Z" />
    <rect x="5" y="4" width="10" height="5" />
    <circle cx="14" cy="14" r="2" />
    <circle cx="6" cy="14" r="2" />
  </svg>,
  'DiscoveriesIcon'
);
