import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import type { ID } from '@bright/api';
import { CustomTooltip } from './CustomTooltip';
import { DropdownMenu } from './DropdownMenu';
import type { DropdownMenuProps } from './DropdownMenu';

interface SearchableDropdownMenuProps<T extends ID> extends DropdownMenuProps<T> {
  readonly placeholder?: string;
  readonly emptySearchResultMessage?: string;
}

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
const SearchComponent: FC<{
  readonly onSearchChange: (text: string) => void;
  readonly placeholder?: string;
}> = ({ onSearchChange, placeholder }) => {
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();

  useEffect(() => {
    onSearchChange(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <ListSubheader sx={{ p: 1 }}>
      <TextField
        size="small"
        placeholder={placeholder ?? t('common.dropdown.search')}
        fullWidth
        value={searchText}
        InputProps={{
          inputRef,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchText && (
            <CustomTooltip text={t('common.button.delete')}>
              <IconButton
                size="small"
                onClick={() => {
                  setSearchText('');
                  inputRef.current?.focus();
                }}
              >
                <Clear />
              </IconButton>
            </CustomTooltip>
          )
        }}
        onChange={e => {
          setSearchText(e.target.value);
        }}
        onKeyDown={e => {
          if (e.key !== 'Escape') {
            // Prevents autoselecting item while typing (default Select behaviour)
            e.stopPropagation();
          }
        }}
      />
    </ListSubheader>
  );
};

export const SearchableDropdownMenu = <T extends { name: string } & ID>(
  props: SearchableDropdownMenuProps<T>
) => {
  const [filteredItems, setFilteredItems] = useState<T[]>(props.items);

  const onSearchChange = (text: string) => {
    setFilteredItems(props.items.filter(item => containsText(item.name, text)));
  };

  const getEmptySearchResultMessage = (): string | undefined => {
    return props.items.length !== 0 && filteredItems.length === 0
      ? props.emptySearchResultMessage
      : undefined;
  };

  const handleClose = () => {
    setFilteredItems(props.items);
  };

  return (
    <DropdownMenu
      {...props}
      items={filteredItems}
      onClose={handleClose}
      emptySearchResultsMessage={getEmptySearchResultMessage()}
      searchComponent={
        <SearchComponent onSearchChange={onSearchChange} placeholder={props.placeholder} />
      }
    />
  );
};
