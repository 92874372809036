import type { FC } from 'react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { svgIconClasses } from '@mui/material/SvgIcon';
import type { Theme } from '@mui/material/styles';
import { styled, ThemeProvider } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Application } from '@bright/api';
import { setSessionAppIdAction, updateMemberSettingsAction } from '@bright/applications/store';
import { useAppDispatch } from '@bright/core';
import { ApplicationHomeIcon, CustomTooltip, SearchableDropdownMenu } from '@bright/share';
import { ApplicationButton } from './ApplicationButton';
import { applicationMenuClasses } from './applicationMenuClasses';

const listItemHeight = 40;

const theme = (current: Theme): Theme => ({
  ...current,
  components: {
    ...current.components,
    MuiPopper: {
      ...current.components?.MuiPaper,
      styleOverrides: {
        root: {
          maxHeight: `${listItemHeight * 10 + 65}px !important`
        }
      }
    },
    MuiMenuItem: {
      ...current.components?.MuiMenuItem,
      styleOverrides: {
        root: props => {
          let parentThemeStyles = {};
          if (typeof current.components?.MuiMenuItem?.styleOverrides?.root === 'function') {
            const styles = current.components?.MuiMenuItem?.styleOverrides?.root?.(props);
            if (styles && typeof styles === 'object') {
              parentThemeStyles = styles;
            }
          }

          return {
            ...parentThemeStyles,
            height: `${listItemHeight}px`,
            minWidth: '400px',
            padding: '12px',
            [`.${applicationMenuClasses.invisible}:not(.${applicationMenuClasses.predefined})`]: {
              opacity: 0
            },
            [`&:hover, &:focus-within, .${applicationMenuClasses.predefined}`]: {
              [`.${applicationMenuClasses.invisible}`]: {
                opacity: 1
              }
            }
          };
        }
      }
    }
  }
});

// something wrong with `ref` after `createSvgIcon`, so extra wrapper is required before `styled`
// eslint-disable-next-line react/display-name
const ApplicationHomeIconIconWrapper = forwardRef<SVGSVGElement, any>((props, ref) => (
  <ApplicationHomeIcon ref={ref} {...props} />
));

const StyledApplicationHomeIcon = styled(ApplicationHomeIconIconWrapper)(({ theme }) => ({
  [`&.${svgIconClasses.root}`]: {
    fill: theme.palette.custom.header.appMenu.icon.selected.fill,
    stroke: theme.palette.custom.header.appMenu.selected.bg
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'transparent',
  [`.${svgIconClasses.root}`]: {
    color: 'inherit',
    stroke: theme.palette.custom.header.appMenu.icon.visible.stroke
  },
  [`&.${applicationMenuClasses.predefined}`]: {
    [`.${svgIconClasses.root}`]: {
      fill: theme.palette.custom.header.appMenu.icon.predefined.fill,
      stroke: theme.palette.custom.header.appMenu.icon.predefined.stroke
    }
  },
  '&:hover, &:focus-visible, &:focus-within': {
    [`&:not(.${applicationMenuClasses.predefined})`]: {
      [`.${svgIconClasses.root}`]: {
        stroke: theme.palette.custom.header.appMenu.icon.hover.stroke,
        fill: 'currentcolor'
      }
    }
  }
}));

export const ApplicationMenu: FC<{ readonly appId: string; readonly apps: Application[] }> = ({
  appId,
  apps
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [defaultId, setDefaultId] = useState<string>(apps.find(app => app.default)?.id || '');
  const [appName, setAppName] = useState<string>(apps.find(app => app.id === appId)?.name || '');
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setAppName(apps.find(app => app.id === appId)?.name || '');
  }, [apps, appId]);

  const isAppPredefined = useCallback(
    (id?: string): boolean => {
      return id && defaultId ? id === defaultId : false;
    },
    [defaultId]
  );

  const handleOnSetDefaultClick = useCallback(
    async (app: Application) => {
      try {
        await dispatch(updateMemberSettingsAction({ defaultAppId: app.id })).unwrap();

        enqueueSnackbar(t('topBar.appMenu.snackbar.success', { application: app.name }), {
          variant: 'success'
        });
        setDefaultId(app.id);
      } catch (e) {
        enqueueSnackbar(t('common.errors.GENERIC_DATA_LOADING_ERROR'), {
          variant: 'error'
        });
      }
    },
    [setDefaultId, enqueueSnackbar, t, dispatch]
  );

  const anchorFormatter = useCallback(
    (x: Application) => {
      return (
        <Stack direction="row" alignItems="center">
          {isAppPredefined(x.id) && <StyledApplicationHomeIcon />}
          <Box component={'span'} sx={{ ml: 1 }}>
            {x.name}
          </Box>
        </Stack>
      );
    },
    [isAppPredefined]
  );

  const formatter = useCallback(
    (app: Application) => {
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <CustomTooltip text={t('topBar.appMenu.setDefault')}>
            <StyledIconButton
              size="small"
              className={`${applicationMenuClasses.invisible} ${
                isAppPredefined(app.id) ? applicationMenuClasses.predefined : ''
              }`}
              onClick={e => {
                e.stopPropagation();
                handleOnSetDefaultClick(app);
              }}
              tabIndex={0}
            >
              <ApplicationHomeIcon />
            </StyledIconButton>
          </CustomTooltip>
          {app.name}
        </Stack>
      );
    },
    [isAppPredefined, t, handleOnSetDefaultClick]
  );

  const handleSelect = (id: string) => {
    const route = location.pathname;
    dispatch(setSessionAppIdAction(id));
    if (route.startsWith('/app/')) {
      const appSubroute = route.split('/').slice(3, 4);
      navigate(`/app/${id ? `${id}/` : ''}${appSubroute.join('/')}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SearchableDropdownMenu
        key={appName}
        activeId={appId}
        placeholder={t('topBar.appMenu.placeholder')}
        items={apps}
        anchorFormatter={anchorFormatter}
        formatter={formatter}
        onSelect={handleSelect}
        emptySearchResultMessage={t('topBar.appMenu.emptySearchResult')}
        OpenMenuButton={ApplicationButton}
        ariaLabel={t('topBar.appMenu.ariaLabelMenuAnchor')}
      />
    </ThemeProvider>
  );
};
