import { createSvgIcon } from '@mui/material/utils';

export const AuditLogIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9417 16.6667H11.7667C11.414 16.6317 11.0816 16.4851 10.8179 16.2484C10.5541 16.0116 10.3727 15.6969 10.3 15.35L8.06669 5.00001L5.76669 10.3333C5.70163 10.4824 5.59438 10.6092 5.45815 10.698C5.32191 10.7869 5.16266 10.8339 5.00002 10.8333H2.50002C2.27901 10.8333 2.06704 10.7455 1.91076 10.5893C1.75448 10.433 1.66669 10.221 1.66669 10C1.66669 9.77899 1.75448 9.56703 1.91076 9.41075C2.06704 9.25447 2.27901 9.16667 2.50002 9.16667H4.45002L6.54169 4.34167C6.68249 4.01789 6.92248 3.74719 7.22705 3.5686C7.53163 3.39001 7.88505 3.31275 8.23636 3.34798C8.58767 3.3832 8.91872 3.52908 9.18178 3.76459C9.44483 4.00009 9.6263 4.31305 9.70002 4.65834L11.9334 15L14.2334 9.68334C14.2959 9.53116 14.402 9.40088 14.5385 9.30894C14.6749 9.21699 14.8355 9.16749 15 9.16667H17.5C17.721 9.16667 17.933 9.25447 18.0893 9.41075C18.2456 9.56703 18.3334 9.77899 18.3334 10C18.3334 10.221 18.2456 10.433 18.0893 10.5893C17.933 10.7455 17.721 10.8333 17.5 10.8333H15.55L13.4584 15.6583C13.3306 15.9554 13.1192 16.209 12.8499 16.388C12.5806 16.567 12.2651 16.6639 11.9417 16.6667Z"
      fill="currentcolor"
      stroke="currentcolor"
      strokeWidth="0.2"
    />
  </svg>,
  'AuditLogIcon'
);
