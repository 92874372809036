import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, ID, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { RootState } from '@bright/core';
import type {
  GlobalVariable,
  GlobalVariableBulkAction,
  ManageGlobalVariablePayload
} from '../models';
import {
  createGlobalVariable,
  exportGlobalVariables,
  loadGlobalVariableDetails,
  loadGlobalVariables,
  runGlobalVariablesBulkAction,
  updateGlobalVariable,
  updateGlobalVariableName
} from '../services';

export const loadGlobalVariablesAction = createAsyncThunk<
  PaginationResponse<GlobalVariable>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'globalVariables/loadGlobalVariables',
  async ({ appId, queryString }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadGlobalVariables(orgId, appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().globalVariables, loadGlobalVariablesAction)
  }
);

export const loadGlobalVariableDetailsAction = createAsyncThunk<
  GlobalVariable,
  {
    appId: string;
    globalVariableId: string;
  },
  { state: RootState }
>(
  'globalVariables/loadGlobalVariableDetails',
  async ({ appId, globalVariableId }, { getState, rejectWithValue }) => {
    const state: RootState = getState();

    const orgId = legacySelectOwnOrganizationId(state);

    try {
      return await loadGlobalVariableDetails(orgId, appId, globalVariableId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ globalVariableId }, { getState }) =>
      actionNotPending(
        getState().globalVariables,
        action =>
          action.type === loadGlobalVariableDetailsAction.pending.type &&
          (action as ReturnType<typeof loadGlobalVariableDetailsAction.pending>).meta.arg
            .globalVariableId === globalVariableId
      )
  }
);

export const updateGlobalVariableNameAction = createAsyncThunk<
  { globalVariableId: string; name: string },
  {
    appId: string;
    globalVariableId: string;
    data: Pick<GlobalVariable, 'name'>;
  },
  { state: RootState }
>(
  'globalVariables/updateGlobalVariableName',
  async ({ appId, globalVariableId, data }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      await updateGlobalVariableName(orgId, appId, globalVariableId, data);
      return { globalVariableId, name: data.name };
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const runGlobalVariablesBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    appId: string;
    ids: string[];
    action: GlobalVariableBulkAction;
  },
  { state: RootState }
>(
  'globalVariables/runGlobalVariablesBulkAction',
  async ({ appId, ids, action }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await runGlobalVariablesBulkAction(orgId, appId, ids, action);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const exportGlobalVariablesAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>(
  'globalVariables/exportGlobalVariablesAction',
  async ({ appId, ids, format }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const response = await exportGlobalVariables(orgId, appId, format, ids);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
      fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const createGlobalVariableAction = createAsyncThunk<
  ID,
  {
    appId: string;
    payload: ManageGlobalVariablePayload;
  },
  { state: RootState }
>(
  'globalVariables/createGlobalVariableAction',
  async ({ appId, payload }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await createGlobalVariable(orgId, appId, payload);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const updateGlobalVariableAction = createAsyncThunk<
  void,
  {
    appId: string;
    globalVariableId: string;
    payload: ManageGlobalVariablePayload;
  },
  { state: RootState }
>(
  'globalVariables/updateGlobalVariableAction',
  async ({ appId, globalVariableId, payload }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await updateGlobalVariable(orgId, appId, globalVariableId, payload);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
