import Lock from 'browser-tabs-lock';

export const BrowserTabLockService = (() => {
  const LOCK_KEY = 'bright-portal:locks:refresh_token';
  const LOCK_TIMEOUT_MS = 5000;
  const lock = new Lock();

  return {
    acquireLock: async (): Promise<boolean> => {
      for (let i = 0; i < 10; i++) {
        if (await lock.acquireLock(LOCK_KEY, LOCK_TIMEOUT_MS)) {
          return true;
        }
      }

      return false;
    },
    releaseLock: async (): Promise<void> => {
      return lock.releaseLock(LOCK_KEY);
    }
  };
})();
