import type { RouteObject } from 'react-router-dom';

export const librariesRoutes: RouteObject[] = [
  {
    path: 'libraries',
    async lazy() {
      const { LibrariesPage } = await import('./pages');
      return { Component: LibrariesPage };
    },
    children: [
      {
        path: 'new-set',
        async lazy() {
          const { CreateSetPage } = await import('@bright/sets');
          return { Component: CreateSetPage };
        }
      }
    ]
  }
];
