import { Navigate } from 'react-router-dom';
import { selectDefaultApplicationId, selectSessionAppId } from '@bright/applications/store';
import { useAppSelector } from '@bright/core';

export const RootPageRedirect = () => {
  const sessionAppId = useAppSelector(selectSessionAppId);
  const defaultAppId = useAppSelector(selectDefaultApplicationId);

  const appId = sessionAppId ?? defaultAppId;

  return <Navigate to={`/app/${appId}/dashboard`} replace />;
};
