import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, Bridge, ExportFormat, PaginationResponse } from '@bright/api';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { BridgeBulkAction } from '../models';
import { exportBridges, loadBridges, runBridgesBulkAction, updateBridgeName } from '../services';

export const loadBridgesAction = createAsyncThunk<
  PaginationResponse<Bridge>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'bridges/loadBridges',
  async ({ queryString }, { rejectWithValue }) => {
    try {
      return await loadBridges(queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().bridges, loadBridgesAction)
  }
);

export const updateBridgeNameAction = createAsyncThunk<
  { bridgeId: string; name: string },
  {
    bridgeId: string;
    data: Pick<Bridge, 'name'>;
  },
  { state: RootState }
>('bridges/updateBridgeName', async ({ bridgeId, data }, { rejectWithValue }) => {
  try {
    await updateBridgeName(bridgeId, data);
    return { bridgeId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const runBridgesBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: BridgeBulkAction;
  },
  { state: RootState }
>('bridges/runBridgesBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runBridgesBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportBridgesAction = createAsyncThunk<
  void,
  {
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('bridges/exportBridgesAction', async ({ ids, format }, { rejectWithValue }) => {
  try {
    const response = await exportBridges(format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
