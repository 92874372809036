import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Application } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadPaginatedApplicationsAction } from './paginated-applications.actions';

interface PaginatedApplicationsState {
  readonly items: Application[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialPaginatedApplicationsState: PaginatedApplicationsState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

const paginatedApplicationsSlice = createSlice({
  name: 'paginatedApplications',
  initialState: initialPaginatedApplicationsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPaginatedApplicationsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadPaginatedApplicationsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadPaginatedApplicationsAction
      )
    );
    builder.addCase(loadPaginatedApplicationsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadPaginatedApplicationsAction
      )
    );
  }
});

export const paginatedApplicationsReducer = paginatedApplicationsSlice.reducer;
