import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ID } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { EditUserPayload, InviteUserPayload, UserDetails } from '../models';
import { inviteUser, loadUser, updateUser } from '../services';

export const loadUserAction = createAsyncThunk<
  UserDetails,
  {
    userId: string;
  },
  { state: RootState }
>(
  'user/loadUser',
  async ({ userId }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await loadUser(orgId, userId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().user, loadUserAction)
  }
);

export const updateUserAction = createAsyncThunk<
  void,
  {
    userId: string;
    payload: EditUserPayload;
  },
  { state: RootState }
>(
  'user/updateUser',
  async ({ userId, payload }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await updateUser(orgId, userId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().user, updateUserAction)
  }
);

export const inviteUserAction = createAsyncThunk<
  ID,
  {
    payload: InviteUserPayload;
  },
  { state: RootState }
>(
  'user/inviteUser',
  async ({ payload }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await inviteUser(orgId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().user, inviteUserAction)
  }
);
