import type { InternalAxiosRequestConfig } from 'axios';

const OLD_ENDPOINT_PREFIX = '/api/v4';

export const legacyQueryParamsRequestInterceptor = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  let url = config.url;

  if (!url) {
    return config;
  }

  if (url.startsWith(OLD_ENDPOINT_PREFIX)) {
    const [baseUrl, queryString] = url.split('?');

    if (queryString) {
      const params = new URLSearchParams(queryString);

      if (params.has('pageSize')) {
        const pageSizeValue = params.get('pageSize');
        params.delete('pageSize');
        params.set('limit', pageSizeValue as string);
      }

      if (params.has('previous')) {
        const previousValue = params.get('previous');
        params.delete('previous');
        params.set('prev', previousValue as string);
      }

      if (params.has('page')) {
        params.delete('page');
      }

      const newQueryString = params.toString();
      url = newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl;
    }
  }

  config.url = url;

  return config;
};
