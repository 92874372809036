export enum CustomApiErrorCode {
  MFA_INVALID_OTP = 'MFA_INVALID_OTP'
}

export interface ApiError {
  readonly type: ApiErrorType;
  readonly title: string;
  readonly code?: CustomApiErrorCode;
  readonly errors?: ApiValidationError[];
}

export interface ApiValidationError {
  readonly detail: string;
  readonly pointer: string;
}

export enum ApiErrorType {
  VALIDATION = 'https://docs.brightsec.com/docs/errors/validation',
  UNKNOWN = 'https://docs.brightsec.com/docs/errors/unknown',
  SESSION_EXPIRED = 'https://docs.brightsec.com/docs/errors/session_expired'
}

export const isApiError = (error: unknown): error is ApiError => {
  return typeof error === 'object' && !!(error as ApiError).type && !!(error as ApiError).title;
};
