import type { RouteObject } from 'react-router-dom';
import { globalVariablesRoutes } from '@bright/global-variables';
import { targetsRoutes } from '@bright/targets';

export const propertiesRoutes: RouteObject[] = [
  {
    path: 'properties',
    async lazy() {
      const { PropertiesPages } = await import('./pages');
      return { Component: PropertiesPages };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { PropertiesPage } = await import('./pages');
          return { Component: PropertiesPage };
        }
      },
      ...targetsRoutes,
      ...globalVariablesRoutes,
      {
        path: 'polymorphic-parameters',
        element: (
          <div>
            <h1>Polymorphic Parameters</h1>
          </div>
        )
      }
    ]
  }
];
