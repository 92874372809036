import type { AxiosResponse } from 'axios';
import type { ExportFormat, ID, PaginationResponse, UserListItem } from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';
import type { EditUserPayload, InviteUserPayload, UserDetails } from '../models';
import { UsersBulkAction } from '../models';

export const loadAllUsers = async (orgId: string): Promise<UserListItem[]> => {
  const url = `/rest/v1/orgs/${orgId}/users`;
  return fetchAllPages<UserListItem>(url);
};

export const loadUsers = async (
  orgId: string,
  queryString: string
): Promise<PaginationResponse<UserListItem>> => {
  const response = await axiosInstance.get<PaginationResponse<UserListItem>>(
    `/rest/v1/orgs/${orgId}/users?${queryString}`
  );
  return response.data;
};

export const loadUser = async (orgId: string, userId: string): Promise<UserDetails> => {
  const response = await axiosInstance.get<UserDetails>(`/rest/v1/orgs/${orgId}/users/${userId}`);
  return response.data;
};

export const runUsersBulkAction = async (
  orgId: string,
  ids: string[],
  action: UsersBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case UsersBulkAction.ACTIVATE:
      response = await axiosInstance.post<void>(`/rest/v1/orgs/${orgId}/users/activate`, {
        ids
      });
      break;
    case UsersBulkAction.DEACTIVATE:
      response = await axiosInstance.post<void>(`/rest/v1/orgs/${orgId}/users/deactivate`, {
        ids
      });
      break;
    case UsersBulkAction.RESET_PASSWORD:
      response = await axiosInstance.post<void>(`/rest/v1/orgs/${orgId}/users/reset-password`, {
        ids
      });
      break;
    case UsersBulkAction.DISABLE_MFA:
      response = await axiosInstance.post<void>(`/rest/v1/orgs/${orgId}/users/disable-mfa`, {
        ids
      });
      break;
    case UsersBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/orgs/${orgId}/users/delete`, {
        ids
      });
      break;
    default: {
      throw new Error(`Unknown users bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const resetUserPassword = async (
  orgId: string,
  userId: string,
  { password }: Pick<EditUserPayload, 'password'>
): Promise<void> => {
  await axiosInstance.patch<void, void, Partial<EditUserPayload>>(
    `/rest/v1/orgs/${orgId}/users/${userId}`,
    {
      changePasswordOnNextLogin: true,
      password
    }
  );
};

export const exportUsers = async (
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/users/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const inviteUser = async (orgId: string, payload: InviteUserPayload): Promise<ID> => {
  return (await axiosInstance.post<ID>(`/rest/v1/orgs/${orgId}/users`, payload)).data;
};

export const updateUser = async (
  orgId: string,
  userId: string,
  payload: EditUserPayload
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/orgs/${orgId}/users/${userId}`, payload);
};
