import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, IpRestriction } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadIpRestrictionAction } from './ip-restriction.actions';

export interface IpRestrictionState {
  readonly ipRestriction: IpRestriction | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialIpRestrictionsState: IpRestrictionState = {
  ipRestriction: null,
  pending: [],
  error: null
};

export const ipRestrictionSlice = createSlice({
  name: 'ipRestrictions',
  initialState: initialIpRestrictionsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadIpRestrictionAction.pending, (state, action) => ({
      ...state,
      ipRestriction: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadIpRestrictionAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          ipRestriction: action.payload
        },
        loadIpRestrictionAction
      )
    );
    builder.addCase(loadIpRestrictionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadIpRestrictionAction
      )
    );
  }
});

export const ipRestrictionReducer = ipRestrictionSlice.reducer;
