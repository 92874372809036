import { isApiError } from '@bright/api';
import type { AuthenticationTestResult } from './AuthenticationTestResult';

export enum AuthenticationTestResultErrorCause {
  GENERIC,
  COMMUNICATION
}

export interface AuthenticationTestResultError {
  cause: AuthenticationTestResultErrorCause;
}

export const mapToTestsResultError = (error: unknown): AuthenticationTestResultError => {
  if (isApiError(error) && error.title.length) {
    return {
      cause: AuthenticationTestResultErrorCause.COMMUNICATION
    };
  }

  return {
    cause: AuthenticationTestResultErrorCause.GENERIC
  };
};

export const isTestResultError = (
  result: null | AuthenticationTestResult[] | AuthenticationTestResultError
): result is AuthenticationTestResultError => {
  if (result == null || Array.isArray(result)) {
    return false;
  }
  return 'cause' in result && !!result.cause;
};
