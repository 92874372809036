import type { useNavigate } from 'react-router-dom';

type NavigateFunction = ReturnType<typeof useNavigate>;

export const RouterNavigationService = (() => {
  let navigateFn: NavigateFunction;

  return {
    setNavigateFunction: (value: NavigateFunction): void => {
      navigateFn = value;
    },
    getNavigateFunction: (): NavigateFunction => navigateFn
  };
})();
