import type { RouteObject } from 'react-router-dom';

export const bridgesRoutes: RouteObject[] = [
  {
    path: 'bridges',
    async lazy() {
      const { BridgesPage } = await import('./pages');
      return { Component: BridgesPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateBridgePage } = await import('./pages');
          return { Component: CreateBridgePage };
        }
      },
      {
        path: ':bridgeId',
        async lazy() {
          const { EditBridgePage } = await import('./pages');
          return { Component: EditBridgePage };
        }
      }
    ]
  }
];
