import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { getSessionAppId } from '@bright/applications';
import {
  loadApplicationsAction,
  selectApplications,
  selectApplicationsPendingStatus,
  setSessionAppIdAction
} from '@bright/applications/store';
import { useAppDispatch, useAppSelector } from '@bright/core';
import { AbsoluteSpinner } from '@bright/share';
import {
  loadSecuritySettingsAction,
  selectSecuritySettings,
  selectSecuritySettingsPendingStatus
} from '@bright/user-settings/store';
import { UserSessionStorageService } from '../services';
import { loadUserInfoAction, selectAuthPendingStatus, selectUserInfo } from '../store';

export const ProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const securitySettings = useSelector(selectSecuritySettings);
  const applicationsPending = useAppSelector(selectApplicationsPendingStatus);
  const securitySettingsPending = useAppSelector(selectSecuritySettingsPendingStatus);
  const authPending = useAppSelector(selectAuthPendingStatus);

  const sessionCookieSet = UserSessionStorageService.hasLoggedIn();
  const isNotAuthenticated = !userInfo && !sessionCookieSet;

  const applications = useAppSelector(selectApplications);

  const loading = [applicationsPending, authPending, securitySettingsPending].every(Boolean);

  useEffect(() => {
    const storedSessionAppId = getSessionAppId();
    if (storedSessionAppId) {
      dispatch(setSessionAppIdAction(storedSessionAppId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNotAuthenticated && !userInfo) {
      dispatch(loadUserInfoAction());
    }
  }, [dispatch, isNotAuthenticated, userInfo]);

  useEffect(() => {
    if (!isNotAuthenticated && !securitySettings) {
      dispatch(loadSecuritySettingsAction());
    }
  }, [dispatch, isNotAuthenticated, securitySettings]);

  useEffect(() => {
    if (userInfo) {
      dispatch(loadApplicationsAction());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (securitySettings?.mfaForced && !securitySettings?.mfaEnabled) {
      navigate('/user-settings/account-security');
    }
  }, [navigate, securitySettings]);

  if (isNotAuthenticated) {
    return <Navigate to={`/login?redirectUrl=${location.pathname}`} />;
  }

  if (loading || !userInfo || !applications) {
    return <AbsoluteSpinner />;
  }

  return <Outlet />;
};
