import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, LogEntry } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { exportDiscoveryLogsAction, loadDiscoveryLogsAction } from './discovery-logs.actions';

const PAGE_SIZE = 100;

export interface DiscoveryLogsState {
  readonly items: LogEntry[];
  readonly total: number;
  readonly next?: string;
  readonly pageSize: number;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialDiscoveryLogsState: DiscoveryLogsState = {
  items: [],
  total: 0,
  pageSize: PAGE_SIZE,
  pending: [],
  error: null
};

const discoveryLogsSlice = createSlice({
  name: 'discoveryLogs',
  initialState: initialDiscoveryLogsState,
  reducers: {
    resetDiscoveryLogs: state => ({
      ...state,
      items: [],
      next: undefined,
      total: 0
    })
  },
  extraReducers: builder => {
    builder.addCase(loadDiscoveryLogsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDiscoveryLogsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: [...state.items, ...action.payload.items],
          total: action.payload.total,
          next: action.payload.next
        },
        loadDiscoveryLogsAction
      )
    );
    builder.addCase(loadDiscoveryLogsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadDiscoveryLogsAction
      )
    );

    builder.addCase(exportDiscoveryLogsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportDiscoveryLogsAction.fulfilled, state =>
      cleanupPendingState(state, exportDiscoveryLogsAction)
    );
    builder.addCase(exportDiscoveryLogsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportDiscoveryLogsAction
      )
    );
  }
});

export const { resetDiscoveryLogs } = discoveryLogsSlice.actions;
export const discoveryLogsReducer = discoveryLogsSlice.reducer;
