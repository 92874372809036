import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type {
  ApiError,
  ExportFormat,
  PaginationResponse,
  Vulnerability,
  VulnerabilityTypeDetails
} from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { VulnerabilityBulkAction } from '../models';
import {
  exportVulnerabilities,
  loadVulnerabilities,
  loadVulnerabilityTypeDetails,
  runVulnerabilitiesBulkAction
} from '../services';

export const loadVulnerabilitiesAction = createAsyncThunk<
  PaginationResponse<Vulnerability>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'vulnerabilities/loadVulnerabilities',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadVulnerabilities(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerabilities, loadVulnerabilitiesAction)
  }
);

export const loadVulnerabilityTypeDetailsAction = createAsyncThunk<
  VulnerabilityTypeDetails,
  {
    typeId: string;
  },
  { state: RootState }
>(
  'vulnerabilities/loadVulnerabilityTypeDetails',
  async ({ typeId }, { rejectWithValue }) => {
    try {
      return await loadVulnerabilityTypeDetails(typeId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ typeId }, { getState }) =>
      actionNotPending(
        getState().vulnerabilities,
        action =>
          action.type === loadVulnerabilityTypeDetailsAction.pending.type &&
          (action as ReturnType<typeof loadVulnerabilityTypeDetailsAction.pending>).meta.arg
            .typeId === typeId
      )
  }
);

export const runVulnerabilitiesBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: VulnerabilityBulkAction;
  },
  { state: RootState }
>('vulnerabilities/runVulnerabilitiesBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runVulnerabilitiesBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportVulnerabilitiesAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>(
  'vulnerabilities/exportVulnerabilitiesAction',
  async ({ appId, ids, format }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const response = await exportVulnerabilities(orgId, appId, format, ids);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
      fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
