import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, PaginationResponse, TrafficMetric } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadDiscoveryTrafficMetrics } from '../services';

export const loadDiscoveryTrafficMetricsAction = createAsyncThunk<
  PaginationResponse<TrafficMetric>,
  { discoveryId: string; queryString: string },
  { state: RootState }
>(
  'discovery/loadDiscoveryTrafficMetrics',
  async ({ discoveryId, queryString }, { rejectWithValue }) => {
    try {
      if (!discoveryId) {
        return rejectWithValue('Discovery is not available');
      }
      return loadDiscoveryTrafficMetrics(discoveryId, queryString);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().discoveryTrafficMetrics, loadDiscoveryTrafficMetricsAction)
  }
);
