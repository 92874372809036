import { createSvgIcon } from '@mui/material/utils';

export const PropertiesIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.6667 1.66669C17.1267 1.66669 17.5 2.04002 17.5 2.50002V5.63085L10.0008 13.1309L9.99583 16.6625L13.5342 16.6675L17.5 12.7017V17.5C17.5 17.96 17.1267 18.3334 16.6667 18.3334H3.33333C2.87333 18.3334 2.5 17.96 2.5 17.5V2.50002C2.5 2.04002 2.87333 1.66669 3.33333 1.66669H16.6667ZM18.1483 7.34002L19.3267 8.51835L12.845 15L11.665 14.9984L11.6667 13.8217L18.1483 7.34002ZM10 10H5.83333V11.6667H10V10ZM12.5 6.66669H5.83333V8.33335H12.5V6.66669Z" />
  </svg>,
  'PropertiesIcon'
);
