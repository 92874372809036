import type { AxiosResponse } from 'axios';
import type { ExportFormat, ID, PaginationResponse, RoleListItem } from '@bright/api';
import { axiosInstance } from '@bright/core';

import type { ManageRolePayload, RoleDetails } from '../models';
import { RoleListItemBulkAction } from '../models';

export const loadRoles = async (
  orgId: string,
  queryString: string
): Promise<PaginationResponse<RoleListItem>> => {
  const response = await axiosInstance.get<PaginationResponse<RoleListItem>>(
    `/rest/v1/orgs/${orgId}/roles?${queryString}`
  );
  return response.data;
};

export const loadRole = async (roleId: string): Promise<RoleDetails> => {
  const response = await axiosInstance.get<RoleDetails>(`/rest/v1/roles/${roleId}`);
  return response.data;
};

export const updateRoleName = async (
  roleId: string,
  data: Pick<RoleListItem, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/roles/${roleId}`, data);
};

export const runRolesBulkAction = async (
  ids: string[],
  action: RoleListItemBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case RoleListItemBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/roles/delete`, {
        ids
      });
      break;
    default: {
      throw new Error(`Unknown role bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportRoles = async (
  orgId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/orgs/${orgId}/roles/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createRole = async (orgId: string, payload: ManageRolePayload): Promise<ID> => {
  return (await axiosInstance.post<ID>(`/rest/v1/orgs/${orgId}/roles`, payload)).data;
};

export const updateRole = async (roleId: string, payload: ManageRolePayload): Promise<void> => {
  await axiosInstance.patch<ID>(`/rest/v1/roles/${roleId}`, payload);
};
