import type { AxiosResponse } from 'axios';
import type { ID, PaginationResponse, VulnerabilityStatus } from '@bright/api';
import { axiosInstance } from '@bright/core';
import type { VulnerabilityEntrypoint, VulnerabilityEntrypointDetails } from '../models';
import { VulnerabilityEntrypointBulkAction } from '../models';

export const loadVulnerabilityEntrypoints = async (
  vulnerabilityId: string,
  queryString: string
): Promise<PaginationResponse<VulnerabilityEntrypoint>> => {
  const response = await axiosInstance.get<PaginationResponse<VulnerabilityEntrypoint>>(
    `/rest/v1/vulnerabilities/${vulnerabilityId}/entrypoints?${queryString}`
  );
  return response.data;
};

export const loadVulnerabilityEntrypointDetails = async (
  vulnerabilityId: string,
  entrypointId: string
): Promise<VulnerabilityEntrypointDetails> => {
  const response = await axiosInstance.get<VulnerabilityEntrypointDetails>(
    `/rest/v1/vulnerabilities/${vulnerabilityId}/entrypoints/${entrypointId}`
  );
  return response.data;
};

export const runVulnerabilityEntrypointsBulkAction = async (
  ids: string[],
  action: VulnerabilityEntrypointBulkAction,
  vulnerabilityId: string,
  status?: VulnerabilityStatus
): Promise<string | void> => {
  let response: AxiosResponse<ID | void>;
  switch (action) {
    case VulnerabilityEntrypointBulkAction.DELETE:
      response = await axiosInstance.post<void>(
        `/rest/v1/vulnerabilities/${vulnerabilityId}/entrypoints/delete`,
        { ids }
      );
      break;

    case VulnerabilityEntrypointBulkAction.RESCAN:
      response = await axiosInstance.post<ID>(
        `/rest/v1/vulnerabilities/${vulnerabilityId}/entrypoints/rescan`,
        { ids }
      );
      break;
    case VulnerabilityEntrypointBulkAction.CHANGE_STATUS:
      response = await axiosInstance.post<void>(
        `/rest/v1/vulnerabilities/${vulnerabilityId}/entrypoints/set-status`,
        {
          ids,
          value: status
        }
      );
      break;
    default:
      throw new Error(`Unknown vulnerability entrypoint bulk action: ${action}`);
  }
  return response.data?.id;
};
