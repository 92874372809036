import type { RouteObject } from 'react-router-dom';

export const discoveriesRoutes: RouteObject[] = [
  {
    path: 'discoveries',
    async lazy() {
      const { DiscoveriesPage } = await import('./pages');
      return { Component: DiscoveriesPage };
    },
    children: [
      {
        path: 'run',
        async lazy() {
          const { RunDiscoveryPage } = await import('./pages');
          return { Component: RunDiscoveryPage };
        }
      }
    ]
  },
  {
    path: 'discoveries/:discoveryId',
    async lazy() {
      const { DiscoveryPage } = await import('./pages');
      return { Component: DiscoveryPage };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { DiscoveryDetailsProgressPage } = await import('./pages');
          return { Component: DiscoveryDetailsProgressPage };
        }
      },
      {
        path: 'logs',
        async lazy() {
          const { DiscoveryLogsPage } = await import('./pages');
          return { Component: DiscoveryLogsPage };
        }
      },
      {
        path: 'response-statuses',
        async lazy() {
          const { DiscoveryResponseStatusesPage } = await import('./pages');
          return { Component: DiscoveryResponseStatusesPage };
        }
      },
      {
        path: 'traffic',
        async lazy() {
          const { DiscoveryDetailsTrafficMetricsPage } = await import('./pages');
          return { Component: DiscoveryDetailsTrafficMetricsPage };
        }
      }
    ]
  }
];
