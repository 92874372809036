import { store } from '@bright/core';
import { loginRedirect } from '../store';
import { refreshToken } from './auth.service';
import { BrowserTabLockService } from './browser-tab-lock.service';
import { RequestQueueService } from './request-queue.service';
import { TokenStorageService } from './token-storage.service';
import { UserSessionStorageService } from './user-session-storage.service';

export const TokenRefreshService = (() => {
  let isRefreshing = false;

  return {
    getNewAccessToken: async (): Promise<string> => {
      if (!(await BrowserTabLockService.acquireLock())) {
        return Promise.reject(new Error('Could not acquire browser tab lock'));
      }

      isRefreshing = true;

      try {
        const token = await refreshToken();

        TokenStorageService.setAccessToken(token);

        RequestQueueService.flushRequestQueue(token.accessToken);

        return token.accessToken;
      } catch (refreshError) {
        store.dispatch(loginRedirect());
        UserSessionStorageService.clearSession();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
        await BrowserTabLockService.releaseLock();
      }
    },
    isRefreshing: (): boolean => {
      return isRefreshing;
    }
  };
})();
