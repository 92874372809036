import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ID } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { GroupDetails, ManageGroupPayload } from '../models';
import { createGroup, loadGroup, updateGroup } from '../services';

export const loadGroupAction = createAsyncThunk<
  GroupDetails,
  {
    groupId: string;
  },
  { state: RootState }
>(
  'group/loadGroup',
  async ({ groupId }, { rejectWithValue }) => {
    try {
      return await loadGroup(groupId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().group, loadGroupAction)
  }
);

export const updateGroupAction = createAsyncThunk<
  void,
  {
    groupId: string;
    payload: ManageGroupPayload;
  },
  { state: RootState }
>(
  'group/updateGroup',
  async ({ groupId, payload }, { rejectWithValue }) => {
    try {
      return await updateGroup(groupId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().group, updateGroupAction)
  }
);

export const createGroupAction = createAsyncThunk<
  ID,
  {
    payload: ManageGroupPayload;
  },
  { state: RootState }
>(
  'group/createGroup',
  async ({ payload }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await createGroup(orgId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().group, createGroupAction)
  }
);
