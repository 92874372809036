import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type {
  ChangePasswordPayload,
  EnableMfaPayload,
  SecuritySettings,
  SetPasswordPayload,
  SetupMfaView
} from '../models';
import {
  changePassword,
  disableMfa,
  enableMfa,
  generateMfaCode,
  loadSecuritySettings,
  setPassword,
  verifyMfa
} from '../services';

export const loadSecuritySettingsAction = createAsyncThunk<
  SecuritySettings,
  void,
  { state: RootState }
>(
  'securitySetings/loadSecuritySettings',
  async (_, { rejectWithValue }) => {
    try {
      return await loadSecuritySettings();
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().securitySettings, loadSecuritySettingsAction)
  }
);

export const generateMfaCodeAction = createAsyncThunk<SetupMfaView, void, { state: RootState }>(
  'securitySetings/generateMfaCode',
  async (_, { rejectWithValue }) => {
    try {
      return await generateMfaCode();
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().securitySettings, generateMfaCodeAction)
  }
);

export const enableMfaAction = createAsyncThunk<void, EnableMfaPayload, { state: RootState }>(
  'securitySetings/enableMfa',
  async (payload, { rejectWithValue }) => {
    try {
      return await enableMfa(payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  }
);

export const disableMfaAction = createAsyncThunk<
  void,
  Pick<EnableMfaPayload, 'otp'>,
  { state: RootState }
>('securitySetings/disableMfa', async (payload, { rejectWithValue }) => {
  try {
    return await disableMfa(payload);
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});

export const verifyMfaAction = createAsyncThunk<
  void,
  Pick<EnableMfaPayload, 'otp'>,
  { state: RootState }
>('securitySetings/verifyMfa', async (payload, { rejectWithValue }) => {
  try {
    return await verifyMfa(payload);
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});

export const setPasswordAction = createAsyncThunk<void, SetPasswordPayload, { state: RootState }>(
  'securitySetings/setPassword',
  async (payload, { rejectWithValue }) => {
    try {
      return await setPassword(payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  }
);

export const changePasswordAction = createAsyncThunk<
  void,
  ChangePasswordPayload,
  { state: RootState }
>('securitySetings/changePassword', async (payload, { rejectWithValue }) => {
  try {
    return await changePassword(payload);
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});
