import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, ResponseStatus } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadDiscoveryResponseStatusesAction } from './discovery-response-statuses.actions';

export interface DiscoveryResponseStatusesState {
  readonly items: ResponseStatus[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialDiscoveryResponseStatusesState: DiscoveryResponseStatusesState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

const discoveryResponseStatusesSlice = createSlice({
  name: 'discoveryResponseStatuses',
  initialState: initialDiscoveryResponseStatusesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDiscoveryResponseStatusesAction.pending, (state, action) => ({
      ...state,
      items: [],
      total: 0,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDiscoveryResponseStatusesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadDiscoveryResponseStatusesAction
      )
    );
    builder.addCase(
      loadDiscoveryResponseStatusesAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          loadDiscoveryResponseStatusesAction
        )
    );
  }
});

export const discoveryResponseStatusesReducer = discoveryResponseStatusesSlice.reducer;
