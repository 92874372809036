import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { loadDiscoveryAction } from './discovery.actions';

const selectDiscoveryStore = (state: RootState) => state.discovery;

export const selectDiscovery = createSelector(selectDiscoveryStore, state => state.discovery);
export const selectDiscoveryPendingStatus = createSelector(
  selectDiscoveryStore,
  state =>
    state.pending.filter(action => action.type === loadDiscoveryAction.pending.type)?.length > 0
);
