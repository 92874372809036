import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { Library, LibraryPossibleVulnerabilities } from '../models';
import {
  exportLibrariesAction,
  loadLibrariesAction,
  loadLibraryPossibleVulnerabilitiesAction
} from './libraries.actions';

export interface LibrariesState {
  readonly items: Library[];
  readonly libraryPossibleVulnerabilities: Record<
    string,
    LibraryPossibleVulnerabilities | undefined
  >;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialLibrariesState: LibrariesState = {
  items: [],
  libraryPossibleVulnerabilities: {},
  total: 0,
  pending: [],
  error: null
};

export const librariesSlice = createSlice({
  name: 'libraries',
  initialState: initialLibrariesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadLibrariesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadLibrariesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadLibrariesAction
      )
    );
    builder.addCase(loadLibrariesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadLibrariesAction
      )
    );

    builder.addCase(loadLibraryPossibleVulnerabilitiesAction.pending, (state, action) => ({
      ...state,
      libraryPossibleVulnerabilities: {
        ...state.libraryPossibleVulnerabilities,
        [action.meta.arg.libraryId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadLibraryPossibleVulnerabilitiesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          libraryPossibleVulnerabilities: {
            ...state.libraryPossibleVulnerabilities,
            [action.meta.arg.libraryId]: action.payload
          }
        },
        loadLibraryPossibleVulnerabilitiesAction
      )
    );
    builder.addCase(
      loadLibraryPossibleVulnerabilitiesAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          loadLibraryPossibleVulnerabilitiesAction
        )
    );

    builder.addCase(exportLibrariesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportLibrariesAction.fulfilled, state =>
      cleanupPendingState(state, exportLibrariesAction)
    );
    builder.addCase(exportLibrariesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportLibrariesAction
      )
    );
  }
});

export const librariesReducer = librariesSlice.reducer;
