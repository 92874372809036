import type { AxiosResponse } from 'axios';
import type { ExportFormat, Group, ID, PaginationResponse } from '@bright/api';
import { axiosInstance, fetchAllPages } from '@bright/core';
import type { GroupDetails, ManageGroupPayload } from '../models';
import { GroupsBulkAction } from '../models';

export const loadAllGroups = async (orgId: string): Promise<Group[]> => {
  const url = `/rest/v1/orgs/${orgId}/groups`;
  return fetchAllPages<Group>(url);
};

export const loadGroups = async (
  orgId: string,
  queryString: string
): Promise<PaginationResponse<Group>> => {
  const response = await axiosInstance.get<PaginationResponse<Group>>(
    `/rest/v1/orgs/${orgId}/groups?${queryString}`
  );
  return response.data;
};

export const loadGroup = async (groupId: string): Promise<GroupDetails> => {
  const response = await axiosInstance.get<GroupDetails>(`/rest/v1/groups/${groupId}`);
  return response.data;
};

export const updateGroupName = async (
  groupId: string,
  data: Pick<Group, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/groups/${groupId}`, data);
};

export const runGroupsBulkAction = async (
  ids: string[],
  action: GroupsBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case GroupsBulkAction.DELETE:
      response = await axiosInstance.post<void>('/rest/v1/groups/remove', {
        ids
      });
      break;
    default: {
      throw new Error(`Unknown users bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportGroups = async (
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/groups/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createGroup = async (orgId: string, payload: ManageGroupPayload): Promise<ID> => {
  return (await axiosInstance.post<ID>(`/rest/v1/orgs/${orgId}/groups`, payload)).data;
};

export const updateGroup = async (groupId: string, payload: ManageGroupPayload): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/groups/${groupId}`, payload);
};
