import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, PaginationResponse } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ScanEntrypoint, ScanEntrypointTest } from '../models';
import { loadScanEntrypoints, loadScanEntrypointTests } from '../services';

export const loadScanEntrypointsAction = createAsyncThunk<
  PaginationResponse<ScanEntrypoint>,
  { scanId: string; queryString: string },
  { state: RootState; rejectedValue: ApiError }
>(
  'scan/loadScanEntrypoints',
  async ({ scanId, queryString }, { rejectWithValue }) => {
    try {
      if (!scanId) {
        return rejectWithValue('Scan is not available');
      }
      return loadScanEntrypoints(scanId, queryString);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().scanEntrypoints, loadScanEntrypointsAction)
  }
);

export const loadScanEntrypointTestsAction = createAsyncThunk<
  ScanEntrypointTest[],
  { scanId: string; entrypointId: string },
  { state: RootState; rejectedValue: ApiError }
>(
  'scan/loadScanEntrypointTests',
  async ({ scanId, entrypointId }, { rejectWithValue }) => {
    try {
      return loadScanEntrypointTests(scanId, entrypointId);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().scanEntrypoints, loadScanEntrypointTestsAction)
  }
);
