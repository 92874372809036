import type { RouteObject } from 'react-router-dom';

export const rolesRoutes: RouteObject[] = [
  {
    path: 'roles',
    async lazy() {
      const { RolesPage } = await import('./pages');
      return { Component: RolesPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateRolePage } = await import('./pages');
          return { Component: CreateRolePage };
        }
      },
      {
        path: ':roleId',
        async lazy() {
          const { EditRolePage } = await import('./pages');
          return { Component: EditRolePage };
        }
      }
    ]
  }
];
