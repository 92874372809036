import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import type { SxProps, Theme } from '@mui/material/styles';

export const ConfirmationDialogIcon: FC<{
  readonly sx?: SxProps<Theme>;
  readonly innerBgColor: string;
  readonly outerBgColor: string;
}> = ({ sx, innerBgColor, outerBgColor }) => {
  return (
    <SvgIcon sx={sx}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill={outerBgColor} />
        <g clipPath="url(#clip0_1_2)">
          <path
            d="M16 7C11.032 7 7 11.032 7 16C7 20.968 11.032 25 16 25C20.968 25 25 20.968 25 16C25 11.032 20.968 7 16 7Z"
            fill="currentColor"
          />
          <path
            d="M16.0001 6.00005C10.4801 6.00005 6.00005 10.48 6.00005 16C6.00005 21.52 10.4801 26 16.0001 26C21.5201 26 26.0001 21.52 26.0001 16C26.0001 10.48 21.5201 6.00005 16.0001 6.00005ZM17.0001 20C17.0001 20.5523 16.5523 21 16.0001 21V21C15.4478 21 15.0001 20.5523 15.0001 20V20C15.0001 19.4478 15.4478 19 16.0001 19V19C16.5523 19 17.0001 19.4478 17.0001 20V20ZM17.0001 16C17.0001 16.5523 16.5523 17 16.0001 17V17C15.4478 17 15.0001 16.5523 15.0001 16V12C15.0001 11.4478 15.4478 11 16.0001 11V11C16.5523 11 17.0001 11.4478 17.0001 12V16Z"
            fill={innerBgColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="24" height="24" transform="translate(4 4)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
