import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Application, ApplicationProperties } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportApplicationsAction,
  loadApplicationsAction,
  loadPropertiesAction,
  setSessionAppIdAction,
  updateMemberSettingsAction,
  updatePropertiesAction
} from './applications.actions';

export interface ApplicationsState {
  readonly pending: Action[];
  readonly sessionAppId: string | null;
  readonly applications: Application[] | null;
  readonly properties: ApplicationProperties | null;
}

export const initialApplicationsState: ApplicationsState = {
  pending: [],
  sessionAppId: null,
  applications: null,
  properties: null
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: initialApplicationsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadApplicationsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadApplicationsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          applications: action.payload ?? []
        },
        loadApplicationsAction
      )
    );
    builder.addCase(loadApplicationsAction.rejected, state =>
      cleanupPendingState(state, loadApplicationsAction)
    );

    builder.addCase(updateMemberSettingsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateMemberSettingsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          applications: (state.applications ?? []).map(app => {
            if (app.id === action.meta.arg.defaultAppId) {
              return {
                ...app,
                default: true
              };
            }
            return { ...app, default: false };
          })
        },
        updateMemberSettingsAction
      )
    );
    builder.addCase(updateMemberSettingsAction.rejected, state =>
      cleanupPendingState(state, updateMemberSettingsAction)
    );

    builder.addCase(setSessionAppIdAction.pending, (state, action) => ({
      ...state,
      sessionAppId: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(setSessionAppIdAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          sessionAppId: action.meta.arg
        },
        setSessionAppIdAction
      )
    );
    builder.addCase(setSessionAppIdAction.rejected, state =>
      cleanupPendingState(state, setSessionAppIdAction)
    );

    builder.addCase(loadPropertiesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadPropertiesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          properties: action.payload
        },
        loadPropertiesAction
      )
    );
    builder.addCase(loadPropertiesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadPropertiesAction
      )
    );

    builder.addCase(updatePropertiesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updatePropertiesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          applications: state.applications!.map(app => {
            if (app.id === action.meta.arg.appId && app.name !== action.meta.arg.data.name) {
              return {
                ...app,
                name: action.meta.arg.data.name
              };
            }

            return app;
          })
        },
        updatePropertiesAction
      )
    );
    builder.addCase(updatePropertiesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updatePropertiesAction
      )
    );

    builder.addCase(exportApplicationsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportApplicationsAction.fulfilled, state =>
      cleanupPendingState(state, exportApplicationsAction)
    );
    builder.addCase(exportApplicationsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportApplicationsAction
      )
    );
  }
});

export const applicationsReducer = applicationsSlice.reducer;
