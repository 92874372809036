import { createSvgIcon } from '@mui/material/utils';

export const LibrariesIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.2222 2H5.55552C4.84828 2 4.17 2.28095 3.6699 2.78105C3.16981 3.28115 2.88885 3.95942 2.88885 4.66667V15.3333C2.88885 16.0406 3.16981 16.7189 3.6699 17.219C4.17 17.719 4.84828 18 5.55552 18H16.2222C16.4579 18 16.684 17.9064 16.8507 17.7397C17.0174 17.573 17.1111 17.3469 17.1111 17.1111V2.88889C17.1111 2.65314 17.0174 2.42705 16.8507 2.26035C16.684 2.09365 16.4579 2 16.2222 2ZM5.55552 16.2222C5.31977 16.2222 5.09368 16.1286 4.92698 15.9619C4.76028 15.7952 4.66663 15.5691 4.66663 15.3333C4.66663 15.0976 4.76028 14.8715 4.92698 14.7048C5.09368 14.5381 5.31977 14.4444 5.55552 14.4444H15.3333V16.2222H5.55552Z" />
  </svg>,
  'LibrariesIcon'
);
