import type { FC, ReactNode } from 'react';
import { isValidElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialogIcon } from '../../icons';

export interface ConfirmDialogProps {
  readonly opened: boolean;
  readonly onConfirm: () => void;
  readonly onClose: () => void;
  readonly palette?: 'primary' | 'warning' | 'error';
  readonly title?: string;
  readonly message?:
    | ((controls: Pick<ConfirmDialogProps, 'onConfirm' | 'onClose'>) => ReactNode)
    | string
    | ReactNode;
  readonly cancelButtonText?: string;
  readonly submitButtonText?: string;
  readonly submitButtonIcon?: ReactNode;
  readonly submitButtonIsDisabled?: boolean;
  readonly container?: Element | null;
  readonly hideCancelButton?: boolean;
  readonly hideFooter?: boolean;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  opened,
  onConfirm,
  onClose,
  palette = 'primary',
  title,
  message,
  cancelButtonText,
  submitButtonText,
  submitButtonIcon,
  container,
  hideCancelButton = false,
  hideFooter
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getContent = () => {
    if (typeof message === 'string' || isValidElement(message)) {
      return message;
    }

    if (typeof message === 'function') {
      return message({ onConfirm, onClose });
    }

    return t('common.dialog.confirmation.message');
  };

  return (
    <Dialog
      open={opened}
      onClose={() => onClose()}
      container={container}
      PaperProps={{
        sx: {
          minWidth: 400,
          maxWidth: 400
        }
      }}
      sx={{
        position: 'absolute',
        zIndex: theme => theme.zIndex.modal + 1
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" gap={1}>
          <ConfirmationDialogIcon
            sx={{
              fontSize: 32,
              color: theme.palette.custom.confirmationDialog.icon[palette].fg,
              transform: `rotateZ(${palette === 'primary' ? 180 : 0}deg)`
            }}
            innerBgColor={theme.palette.custom.confirmationDialog.icon[palette].bg}
            outerBgColor={theme.palette.custom.confirmationDialog.icon[palette].outerBg}
          />
          {title ?? t('common.dialog.confirmation.title')}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>{getContent()}</DialogContentText>
      </DialogContent>
      {hideFooter ? null : (
        <DialogActions disableSpacing sx={{ gap: theme => theme.spacing(0.5) }}>
          {!hideCancelButton && (
            <Button type="button" variant="text" color="secondary" onClick={() => onClose()}>
              {cancelButtonText ?? t('common.button.cancel')}
            </Button>
          )}
          <Button
            type="button"
            startIcon={submitButtonIcon}
            variant="contained"
            color={palette}
            onClick={() => onConfirm()}
          >
            {submitButtonText ?? t('common.button.confirm')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
