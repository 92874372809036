import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type {
  ApiError,
  BasicTestSet,
  ExportFormat,
  ID,
  PaginationResponse,
  TestSet
} from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { ManageTestSetPayload, SetBulkAction, TestSetConfig, TestSetDetails } from '../models';
import {
  createSet,
  exportSets,
  loadBasicSets,
  loadSet,
  loadSetDetails,
  loadSets,
  runSetsBulkAction,
  updateSet,
  updateSetName,
  updateSetTests
} from '../services';

export const loadSetsAction = createAsyncThunk<
  PaginationResponse<TestSet>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'sets/loadSets',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadSets(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().sets, loadSetsAction)
  }
);

export const loadSetDetailsAction = createAsyncThunk<
  TestSetDetails,
  {
    setId: string;
  },
  { state: RootState }
>(
  'sets/loadSetDetails',
  async ({ setId }, { rejectWithValue }) => {
    try {
      return await loadSetDetails(setId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ setId }, { getState }) =>
      actionNotPending(
        getState().sets,
        action =>
          action.type === loadSetDetailsAction.pending.type &&
          (action as ReturnType<typeof loadSetDetailsAction.pending>).meta.arg.setId === setId
      )
  }
);

export const loadSetAction = createAsyncThunk<
  TestSetConfig,
  {
    setId: string;
  },
  { state: RootState }
>(
  'sets/loadSet',
  async ({ setId }, { rejectWithValue }) => {
    try {
      return await loadSet(setId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().sets, loadSetAction)
  }
);

export const loadBasicSetsAction = createAsyncThunk<
  BasicTestSet[],
  {
    appId: string;
  },
  { state: RootState }
>(
  'sets/loadBasicSets',
  async ({ appId }, { rejectWithValue }) => {
    try {
      return await loadBasicSets(appId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().sets, loadBasicSetsAction)
  }
);

export const updateSetNameAction = createAsyncThunk<
  { setId: string; name: string },
  {
    setId: string;
    data: Pick<TestSet, 'name'>;
  },
  { state: RootState }
>('sets/updateSetName', async ({ setId, data }, { rejectWithValue }) => {
  try {
    await updateSetName(setId, data);
    return { setId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const updateSetTestsAction = createAsyncThunk<
  void,
  {
    setId: string;
    testIds: string[];
  },
  { state: RootState }
>('sets/updateSetTests', async ({ setId, testIds }, { rejectWithValue }) => {
  try {
    await updateSetTests(setId, testIds);
    return;
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const runSetsBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: SetBulkAction;
  },
  { state: RootState }
>('sets/runSetsBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runSetsBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportSetsAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('sets/exportSetsAction', async ({ appId, ids, format }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    const response = await exportSets(orgId, appId, format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const createSetAction = createAsyncThunk<
  ID,
  {
    appId: string;
    payload: ManageTestSetPayload;
  },
  { state: RootState }
>('sets/createSetAction', async ({ appId, payload }, { rejectWithValue }) => {
  try {
    return await createSet(appId, payload);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const updateSetAction = createAsyncThunk<
  void,
  {
    setId: string;
    payload: ManageTestSetPayload;
  },
  { state: RootState }
>('sets/updateSetAction', async ({ setId, payload }, { rejectWithValue }) => {
  try {
    return await updateSet(setId, payload);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
