import type { RouteObject } from 'react-router-dom';

export const setsRoutes: RouteObject[] = [
  {
    path: 'sets',
    async lazy() {
      const { SetsPage } = await import('./pages');
      return { Component: SetsPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateSetPage } = await import('./pages');
          return { Component: CreateSetPage };
        }
      },
      {
        path: ':setId',
        async lazy() {
          const { EditSetPage } = await import('./pages');
          return { Component: EditSetPage };
        }
      }
    ]
  }
];
