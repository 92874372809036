import type { Action } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { cleanupPendingState } from '@bright/core';
import type {
  AuthenticationTestResult,
  AuthenticationTestResultError,
  OtpToken,
  TestResultKey
} from '../models';
import {
  mapToTestsResultError,
  NewAuthenticationTestResultsKey,
  OtpTokenTestStatus
} from '../models';
import {
  clearCreateAuthenticationTestResultAction,
  testAuthenticationAction,
  testAuthenticationOtpAction
} from './authentication-test.actions';

export interface AuthenticationTestsState {
  readonly testResults: Partial<
    Record<TestResultKey, AuthenticationTestResult[] | AuthenticationTestResultError | undefined>
  >;
  readonly otpTestResults: Record<string, { status: OtpTokenTestStatus; result: OtpToken | null }>;
  readonly pending: Action[];
}

const initialAuthenticationTestsState: AuthenticationTestsState = {
  testResults: {},
  otpTestResults: {},
  pending: []
};

export const authenticationTestsSlice = createSlice({
  name: 'authentication-tests',
  initialState: initialAuthenticationTestsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(testAuthenticationAction.pending, (state, action) => ({
      ...state,
      testResults: {
        ...state.testResults,
        ...{ [action.meta.arg.payload.id]: undefined }
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(testAuthenticationAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          testResults: {
            ...state.testResults,
            ...{ [action.meta.arg.payload.id]: action.payload }
          }
        },
        testAuthenticationAction
      )
    );
    builder.addCase(testAuthenticationAction.rejected, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          testResults: {
            ...state.testResults,
            ...{ [action.meta.arg.payload.id]: mapToTestsResultError(action.payload) }
          }
        },
        testAuthenticationAction
      )
    );
    builder.addCase(clearCreateAuthenticationTestResultAction, state =>
      cleanupPendingState(
        {
          ...state,
          testResults: {
            ...state.testResults,
            ...{ [NewAuthenticationTestResultsKey]: undefined }
          }
        },
        testAuthenticationAction
      )
    );
    builder.addCase(testAuthenticationOtpAction.pending, (state, action) => ({
      ...state,
      otpTestResults: {
        ...state.otpTestResults,
        ...{ [action.meta.arg.payload.name]: { status: OtpTokenTestStatus.EMPTY, result: null } }
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(testAuthenticationOtpAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          otpTestResults: {
            ...state.otpTestResults,
            ...{
              [action.meta.arg.payload.name]: {
                status: action.payload?.otp ? OtpTokenTestStatus.VALID : OtpTokenTestStatus.INVALID,
                result: action.payload
              }
            }
          }
        },
        testAuthenticationOtpAction
      )
    );
    builder.addCase(testAuthenticationOtpAction.rejected, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          otpTestResults: {
            ...state.otpTestResults,
            ...{
              [action.meta.arg.payload.name]: {
                status: OtpTokenTestStatus.INVALID,
                result: null
              }
            }
          },
          error: action.payload ?? null
        },
        testAuthenticationOtpAction
      )
    );
  }
});

export const authenticationTestsReducer = authenticationTestsSlice.reducer;
