import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import type { ApiError, LogEntry, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import { exportDiscoveryLogs, loadDiscoveryLogs } from '../services';
import { selectDiscoveryLogsNextPageParams } from './discovery-logs.selectors';
import { selectDiscovery } from './discovery.selectors';

export const loadDiscoveryLogsAction = createAsyncThunk<
  PaginationResponse<LogEntry>,
  { discoveryId: string },
  { state: RootState }
>(
  'discovery/loadDiscoveryLogs',
  async ({ discoveryId }, { getState, rejectWithValue }) => {
    try {
      if (!discoveryId) {
        return rejectWithValue('Discovery is not available');
      }

      const { next, pageSize } = selectDiscoveryLogsNextPageParams(getState());

      return loadDiscoveryLogs(discoveryId, `pageSize=${pageSize}${next ? `&next=${next}` : ''}`);
    } catch (error: unknown) {
      return rejectWithValue(error as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().discoveryLogs, loadDiscoveryLogsAction)
  }
);

export const exportDiscoveryLogsAction = createAsyncThunk<
  void,
  { appId: string },
  { state: RootState }
>('discovery/exportDiscoveryLogs', async ({ appId }, { getState, rejectWithValue }) => {
  const state: RootState = getState();

  const discovery = selectDiscovery(state);
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    if (!discovery) {
      return rejectWithValue('Discovery is not available');
    }
    const res = await exportDiscoveryLogs(orgId, appId, discovery.id);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(res.headers));
    fileSaver.saveAs(res.data, name);
  } catch (error: unknown) {
    return rejectWithValue(error as unknown as ApiError);
  }
});
