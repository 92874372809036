import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Entrypoint, EntrypointDetails } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  clearAllEntrypointsAction,
  exportEntrypointsAction,
  loadAllEntrypointsAction,
  loadEntrypointDetailsAction,
  loadEntrypointsAction,
  runEntrypointsBulkActionAction
} from './entrypoints.actions';

export interface EntrypointsState {
  readonly items: Entrypoint[];
  readonly entrypointDetails: Record<string, EntrypointDetails | undefined>;
  readonly all: Entrypoint[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialEntrypointsState: EntrypointsState = {
  items: [],
  entrypointDetails: {},
  all: [],
  total: 0,
  pending: [],
  error: null
};

export const entrypointsSlice = createSlice({
  name: 'entrypoints',
  initialState: initialEntrypointsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadEntrypointsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadEntrypointsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadEntrypointsAction
      )
    );
    builder.addCase(loadEntrypointsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadEntrypointsAction
      )
    );

    builder.addCase(loadAllEntrypointsAction.pending, (state, action) => ({
      ...state,
      all: [],
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAllEntrypointsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          all: action.payload
        },
        loadAllEntrypointsAction
      )
    );
    builder.addCase(loadAllEntrypointsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAllEntrypointsAction
      )
    );

    builder.addCase(clearAllEntrypointsAction.fulfilled, state => ({
      ...state,
      all: []
    }));

    builder.addCase(loadEntrypointDetailsAction.pending, (state, action) => ({
      ...state,
      entrypointDetails: {
        ...state.entrypointDetails,
        [action.meta.arg.entrypointId]: undefined
      },
      pending: [...state.pending, action]
    }));
    builder.addCase(loadEntrypointDetailsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          entrypointDetails: {
            ...state.entrypointDetails,
            [action.payload.id]: action.payload
          }
        },
        loadEntrypointDetailsAction
      )
    );
    builder.addCase(loadEntrypointDetailsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadEntrypointDetailsAction
      )
    );

    builder.addCase(runEntrypointsBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runEntrypointsBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runEntrypointsBulkActionAction)
    );
    builder.addCase(runEntrypointsBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runEntrypointsBulkActionAction
      )
    );

    builder.addCase(exportEntrypointsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportEntrypointsAction.fulfilled, state =>
      cleanupPendingState(state, exportEntrypointsAction)
    );
    builder.addCase(exportEntrypointsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportEntrypointsAction
      )
    );
  }
});

export const entrypointsReducer = entrypointsSlice.reducer;
