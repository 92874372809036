import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { DashboardData } from '../models';
import { loadDashboard } from '../services';

export const loadDashboardDataAction = createAsyncThunk<
  DashboardData,
  {
    appId: string;
  },
  { state: RootState }
>(
  'dashboard/loadDashboard',
  async ({ appId }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadDashboard(orgId, appId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().dashboard, loadDashboardDataAction)
  }
);
