import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { BridgesScriptDetails } from '../models';
import {
  createBridgesScriptAction,
  loadBridgesScriptAction,
  updateBridgesScriptAction
} from './bridges-script.actions';

export interface BridgesScriptState {
  readonly bridgesScript: BridgesScriptDetails | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialBridgesScriptState: BridgesScriptState = {
  bridgesScript: null,
  pending: [],
  error: null
};

export const bridgesScriptSlice = createSlice({
  name: 'bridgesScript',
  initialState: initialBridgesScriptState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadBridgesScriptAction.pending, (state, action) => ({
      ...state,
      bridgesScript: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBridgesScriptAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          bridgesScript: action.payload
        },
        loadBridgesScriptAction
      )
    );
    builder.addCase(loadBridgesScriptAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBridgesScriptAction
      )
    );

    builder.addCase(updateBridgesScriptAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateBridgesScriptAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateBridgesScriptAction)
    );
    builder.addCase(updateBridgesScriptAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateBridgesScriptAction
      )
    );

    builder.addCase(createBridgesScriptAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createBridgesScriptAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createBridgesScriptAction)
    );
    builder.addCase(createBridgesScriptAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createBridgesScriptAction
      )
    );
  }
});

export const bridgesScriptReducer = bridgesScriptSlice.reducer;
