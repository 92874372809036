import type { FC, PropsWithChildren } from 'react';
import type { DefaultTheme } from '@mui/system';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import type { ThemeVariants } from '../../themes';
import { themes } from '../../themes';

type ThemeProviderProps = {
  /**
   * Theme that will be defined for the application.
   * Accepts the theme as a ready-made preset or a custom theme object.
   *
   * @example ```tsx
   *  <ThemeProvider theme="light">
   *    <App />
   *  </ThemeProvider>
   * ```
   *
   * @default 'light'
   */
  readonly theme?: ThemeVariants | Partial<DefaultTheme>;
};

/**
 *
 * `<ThemeProvider />` - is the container for the entire application, it defines the theme and other settings for the application.
 *
 * ***`ThemeProvider` must be the root element of your application to ensure theme inheritance***
 *
 * @example ```tsx
 * <ThemeProvider theme="light">
 *   <App />
 * </ThemeProvider>
 * ```
 */
export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = ({
  children,
  theme = 'light'
}) => {
  const currentTheme = typeof theme === 'string' && themes[theme] ? themes[theme] : theme;

  return <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>;
};
