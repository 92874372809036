import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Bridge } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportBridgesAction,
  loadBridgesAction,
  runBridgesBulkActionAction,
  updateBridgeNameAction
} from './bridges.actions';

export interface BridgesState {
  readonly items: Bridge[];
  readonly total: number;
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
}

const initialBridgesState: BridgesState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const bridgesSlice = createSlice({
  name: 'bridges',
  initialState: initialBridgesState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadBridgesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBridgesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadBridgesAction
      )
    );
    builder.addCase(loadBridgesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBridgesAction
      )
    );

    builder.addCase(updateBridgeNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateBridgeNameAction.fulfilled, state =>
      cleanupPendingState(state, updateBridgeNameAction)
    );
    builder.addCase(updateBridgeNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateBridgeNameAction
      )
    );

    builder.addCase(runBridgesBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runBridgesBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runBridgesBulkActionAction)
    );
    builder.addCase(runBridgesBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runBridgesBulkActionAction
      )
    );

    builder.addCase(exportBridgesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportBridgesAction.fulfilled, state =>
      cleanupPendingState(state, exportBridgesAction)
    );
    builder.addCase(exportBridgesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportBridgesAction
      )
    );
  }
});

export const bridgesReducer = bridgesSlice.reducer;
