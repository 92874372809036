import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, UserListItem } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportUsersAction,
  loadAllUsersAction,
  loadUsersAction,
  resetUserPasswordAction,
  runUsersBulkActionAction
} from './users.actions';

export interface UsersState {
  readonly items: UserListItem[];
  readonly all: UserListItem[];
  readonly total: number;
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
}

const initialUsersState: UsersState = {
  items: [],
  all: [],
  total: 0,
  pending: [],
  error: null
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadUsersAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadUsersAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadUsersAction
      )
    );
    builder.addCase(loadUsersAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadUsersAction
      )
    );

    builder.addCase(runUsersBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runUsersBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runUsersBulkActionAction)
    );
    builder.addCase(runUsersBulkActionAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runUsersBulkActionAction
      )
    );

    builder.addCase(resetUserPasswordAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(resetUserPasswordAction.fulfilled, state =>
      cleanupPendingState(state, resetUserPasswordAction)
    );
    builder.addCase(resetUserPasswordAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        resetUserPasswordAction
      )
    );

    builder.addCase(loadAllUsersAction.pending, (state, action) => ({
      ...state,
      all: [],
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAllUsersAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          all: action.payload
        },
        loadAllUsersAction
      )
    );
    builder.addCase(loadAllUsersAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAllUsersAction
      )
    );

    builder.addCase(exportUsersAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportUsersAction.fulfilled, state =>
      cleanupPendingState(state, exportUsersAction)
    );
    builder.addCase(exportUsersAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportUsersAction
      )
    );
  }
});

export const usersReducer = usersSlice.reducer;
