import { parse } from '@tinyhttp/content-disposition';
import type { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export const convertAxiosHeadersToDomHeaders = (
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
): Headers => {
  const fetchHeaders = new Headers();

  for (const [key, value] of Object.entries(headers)) {
    fetchHeaders.append(key, value);
  }

  return fetchHeaders;
};

export const getFileNameFromHeaders = (headers: Headers, defaultName = 'download'): string => {
  const contentDisposition = headers.get('Content-Disposition');
  if (!contentDisposition) {
    return defaultName;
  }

  try {
    const { filename } = parse(contentDisposition).parameters;
    return typeof filename === 'string' ? filename : defaultName;
  } catch (e) {
    return defaultName;
  }
};
