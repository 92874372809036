import type { RouteObject } from 'react-router-dom';

export const userSettingsRoutes: RouteObject[] = [
  {
    path: 'user-settings',
    async lazy() {
      const { UserSettingsPages } = await import('./pages');
      return { Component: UserSettingsPages };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { PersonalSettingsPage } = await import('./pages');
          return { Component: PersonalSettingsPage };
        }
      },
      {
        path: 'account-security',
        async lazy() {
          const { AccoutSecurityPage } = await import('./pages');
          return { Component: AccoutSecurityPage };
        }
      },
      {
        path: 'api-keys',
        element: (
          <div>
            <p>API Keys</p>
          </div>
        )
      }
    ]
  }
];
