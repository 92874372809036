import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiKey, CreateApiKeyResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManageOrgApiKeyPayload } from '../models';
import { createOrgApiKey, loadApiKey, updateApiKey } from '../services';

export const loadOrgApiKeyAction = createAsyncThunk<
  ApiKey,
  {
    apiKeyId: string;
  },
  { state: RootState }
>(
  'orgApiKey/loadApiKey',
  async ({ apiKeyId }, { rejectWithValue }) => {
    try {
      return await loadApiKey(apiKeyId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().orgApiKey, loadOrgApiKeyAction)
  }
);

export const updateOrgApiKeyAction = createAsyncThunk<
  void,
  {
    apiKeyId: string;
    payload: ManageOrgApiKeyPayload;
  },
  { state: RootState }
>(
  'orgApiKey/updateOrgApiKey',
  async ({ apiKeyId, payload }, { rejectWithValue }) => {
    try {
      return await updateApiKey(apiKeyId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().orgApiKey, updateOrgApiKeyAction)
  }
);

export const createOrgApiKeyAction = createAsyncThunk<
  CreateApiKeyResponse,
  {
    payload: ManageOrgApiKeyPayload;
  },
  { state: RootState }
>(
  'orgApiKey/createOrgApiKey',
  async ({ payload }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());
    try {
      return await createOrgApiKey(orgId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().orgApiKey, createOrgApiKeyAction)
  }
);
