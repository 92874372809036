import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { BridgeNetworkResultState } from '../models';
import type { BridgeNetworkResult } from '../models';
import {
  loadBridgeNetworkResultAction,
  pollBridgeNetworkResultAction,
  runBridgeNetworkTestAction
} from './network.actions';

export interface BridgeNetworkState {
  readonly result: BridgeNetworkResult | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialBridgeNetworkState: BridgeNetworkState = {
  result: null,
  pending: [],
  error: null
};

export const bridgeNetworkSlice = createSlice({
  name: 'bridgeNetwork',
  initialState: initialBridgeNetworkState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadBridgeNetworkResultAction.pending, (state, action) => ({
      ...state,
      result: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBridgeNetworkResultAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          result: action.payload ?? null
        },
        loadBridgeNetworkResultAction
      )
    );
    builder.addCase(loadBridgeNetworkResultAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBridgeNetworkResultAction
      )
    );

    builder.addCase(pollBridgeNetworkResultAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(pollBridgeNetworkResultAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          result: action.payload ?? null
        },
        pollBridgeNetworkResultAction
      )
    );
    builder.addCase(pollBridgeNetworkResultAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        pollBridgeNetworkResultAction
      )
    );

    builder.addCase(runBridgeNetworkTestAction.pending, (state, action) => ({
      ...state,
      result: state.result
        ? { ...state.result, state: BridgeNetworkResultState.IN_PROGRESS }
        : null,
      pending: [...state.pending, action]
    }));
    builder.addCase(runBridgeNetworkTestAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          result: action.payload
        },
        runBridgeNetworkTestAction
      )
    );
    builder.addCase(runBridgeNetworkTestAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        runBridgeNetworkTestAction
      )
    );
  }
});

export const bridgeNetworkReducer = bridgeNetworkSlice.reducer;
