import { createSvgIcon } from '@mui/material/utils';

export const AuthenticationsIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 13.5C10.4833 13.5 10.875 13.1083 10.875 12.625C10.875 12.1418 10.4833 11.75 10 11.75C9.51675 11.75 9.125 12.1418 9.125 12.625C9.125 13.1083 9.51675 13.5 10 13.5Z" />
    <path d="M14.375 6.5H8.25V4.75C8.25 4.28587 8.43438 3.84075 8.76257 3.51257C9.09076 3.18438 9.53588 3 10 3C10.4641 3 10.9093 3.18438 11.2374 3.51257C11.5656 3.84075 11.75 4.28587 11.75 4.75C11.75 4.98207 11.8422 5.20463 12.0063 5.36872C12.1704 5.53282 12.3929 5.625 12.625 5.625C12.8571 5.625 13.0796 5.53282 13.2437 5.36872C13.4078 5.20463 13.5 4.98207 13.5 4.75C13.5 3.82174 13.1313 2.93151 12.4749 2.27513C11.8185 1.61875 10.9283 1.25 10 1.25C9.07175 1.25 8.18151 1.61875 7.52513 2.27513C6.86875 2.93151 6.5 3.82174 6.5 4.75V6.5H5.625C4.92881 6.5 4.26113 6.77657 3.76885 7.26885C3.27656 7.76113 3 8.42881 3 9.12501V16.125C3 16.8212 3.27656 17.4889 3.76885 17.9812C4.26113 18.4735 4.92881 18.75 5.625 18.75H14.375C15.0712 18.75 15.7389 18.4735 16.2312 17.9812C16.7235 17.4889 17 16.8212 17 16.125V9.12501C17 8.42881 16.7235 7.76113 16.2312 7.26885C15.7389 6.77657 15.0712 6.5 14.375 6.5ZM10 15.25C9.48083 15.25 8.97331 15.0961 8.54163 14.8076C8.10995 14.5192 7.7735 14.1092 7.57482 13.6296C7.37614 13.1499 7.32416 12.6221 7.42544 12.1129C7.52673 11.6037 7.77674 11.136 8.14385 10.7689C8.51096 10.4017 8.97869 10.1517 9.48789 10.0504C9.99709 9.94916 10.5249 10.0011 11.0046 10.1998C11.4842 10.3985 11.8942 10.735 12.1826 11.1666C12.4711 11.5983 12.625 12.1058 12.625 12.625C12.625 13.3212 12.3484 13.9889 11.8562 14.4812C11.3639 14.9735 10.6962 15.25 10 15.25Z" />
  </svg>,
  'AuthenticationsIcon'
);
