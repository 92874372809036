import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, TrafficMetric } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadDiscoveryTrafficMetricsAction } from './discovery-traffic-metrics.actions';

export interface DiscoveryTrafficMetricsState {
  readonly items: TrafficMetric[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialDiscoveryTrafficMetricsState: DiscoveryTrafficMetricsState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

const discoveryTrafficMetricsSlice = createSlice({
  name: 'discoveryTrafficMetrics',
  initialState: initialDiscoveryTrafficMetricsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDiscoveryTrafficMetricsAction.pending, (state, action) => ({
      ...state,
      items: [],
      total: 0,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadDiscoveryTrafficMetricsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadDiscoveryTrafficMetricsAction
      )
    );
    builder.addCase(
      loadDiscoveryTrafficMetricsAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          loadDiscoveryTrafficMetricsAction
        )
    );
  }
});

export const discoveryTrafficMetricsReducer = discoveryTrafficMetricsSlice.reducer;
