import type { FC } from 'react';
import { Dashboard, DashboardOutlined, Map, MapOutlined } from '@mui/icons-material';
import Games from '@mui/icons-material/Games';
import GamesOutlined from '@mui/icons-material/GamesOutlined';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  AuditLogIcon,
  AuditLogOutlinedIcon,
  AuthenticationsIcon,
  AuthenticationsOutlinedIcon,
  DiscoveriesIcon,
  DiscoveriesOutlinedIcon,
  IntegrationHubIcon,
  IntegrationHubOutlinedIcon,
  LibrariesIcon,
  LibrariesOutlinedIcon,
  PropertiesIcon,
  PropertiesOutlinedIcon,
  ScansIcon,
  ScansOutlinedIcon,
  SetsIcon,
  SetsOutlinedIcon,
  SettingsIcon,
  SettingsOutlinedIcon,
  VulnerabilitiesIcon,
  VulnerabilitiesOutlinedIcon
} from '@bright/share';
import type { NavSection } from './NavListSection';
import { NavListSection } from './NavListSection';

export function useNavSections(): NavSection[] {
  const { t } = useTranslation();

  return [
    {
      children: [
        {
          text: t('menu.pages.dashboard'),
          icon: <DashboardOutlined />,
          iconActive: <Dashboard />,
          to: 'dashboard'
        },
        {
          text: t('menu.pages.vulnerabilities'),
          icon: <VulnerabilitiesOutlinedIcon />,
          iconActive: <VulnerabilitiesIcon />,
          to: 'vulnerabilities'
        }
      ]
    },
    {
      title: t('menu.sections.inventory'),
      children: [
        {
          text: t('menu.pages.entrypoints'),
          icon: <GamesOutlined />,
          iconActive: <Games />,
          to: 'entrypoints'
        },
        {
          text: t('menu.pages.sitemap'),
          icon: <MapOutlined />,
          iconActive: <Map />,
          to: 'sitemap'
        },
        {
          text: t('menu.pages.authentications'),
          icon: <AuthenticationsOutlinedIcon />,
          iconActive: <AuthenticationsIcon />,
          to: 'authentications'
        },
        {
          text: t('menu.pages.discoveries'),
          icon: <DiscoveriesOutlinedIcon />,
          iconActive: <DiscoveriesIcon />,
          to: 'discoveries'
        },
        {
          text: t('menu.pages.properties'),
          icon: <PropertiesOutlinedIcon />,
          iconActive: <PropertiesIcon />,
          to: 'properties'
        }
      ]
    },
    {
      title: t('menu.sections.tests'),
      children: [
        {
          text: t('menu.pages.libraries'),
          icon: <LibrariesOutlinedIcon />,
          iconActive: <LibrariesIcon />,
          to: 'libraries'
        },
        {
          text: t('menu.pages.sets'),
          icon: <SetsOutlinedIcon />,
          iconActive: <SetsIcon />,
          to: 'sets'
        },
        {
          text: t('menu.pages.scans'),
          icon: <ScansOutlinedIcon />,
          iconActive: <ScansIcon />,
          to: 'scans'
        }
      ]
    },
    {
      title: t('menu.sections.general'),
      children: [
        {
          text: t('menu.pages.integrationHub'),
          icon: <IntegrationHubOutlinedIcon />,
          iconActive: <IntegrationHubIcon />,
          to: '/integration-hub',
          global: true
        },
        {
          text: t('menu.pages.auditLog'),
          icon: <AuditLogOutlinedIcon />,
          iconActive: <AuditLogIcon />,
          to: '/audit-log',
          global: true
        },
        {
          text: t('menu.pages.settings'),
          icon: <SettingsOutlinedIcon />,
          iconActive: <SettingsIcon />,
          to: '/settings',
          global: true
        }
      ]
    }
  ];
}

export const NavList: FC<{ readonly appId?: string; readonly opened?: boolean }> = ({
  appId,
  opened
}) => {
  const sections = useNavSections();

  return (
    <Box
      component="nav"
      sx={{
        padding: theme => theme.spacing(1, 3, 0),
        display: 'flex',
        flexDirection: 'column',
        gap: theme => theme.spacing(2)
      }}
    >
      {sections
        .filter(section => section.children.every(item => appId || item.global))
        .map((section, index) => (
          <NavListSection key={index} section={section} appId={appId} collapsed={!opened} />
        ))}
    </Box>
  );
};
