import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ErrorWidgetProps {
  readonly text: string;
  readonly errorCode: string;
  readonly errorCodeOverlay: ReactNode;
  readonly link: ReactNode;
  readonly href: string | number;
}

export const ErrorWidget: FC<ErrorWidgetProps> = ({
  text,
  errorCode,
  errorCodeOverlay,
  link,
  href
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = useCallback(() => {
    if (typeof href === 'string') {
      navigate(href);
    } else {
      navigate(href);
    }
  }, [href, navigate]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: theme => theme.palette.light.main,
        padding: '40px',
        justifyContent: 'stretch',
        gap: '40px'
      }}
    >
      <img src="/logo-theme-light.svg" height={70} alt={t('errorPages.brightLogo')} />
      <Typography
        sx={{
          fontSize: 'min(72px, 7vh)',
          fontWeight: 900,
          color: theme => theme.palette.text.primary,
          textAlign: 'center',
          maxWidth: '80vw',
          flex: 1,
          lineHeight: 1
        }}
      >
        {text}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Typography
          sx={{
            fontSize: 'min(616px, 52vh)',
            lineHeight: 1,
            fontWeight: 900,
            color: theme => theme.palette.custom.errorPage.code
          }}
        >
          {errorCode}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          {errorCodeOverlay}
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          alignItems: 'flex-end',
          display: 'flex'
        }}
      >
        <Link
          component={'button'}
          onClick={() => handleNavigation()}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            borderBottom: theme => `2px solid ${theme.palette.custom.errorPage.link.fg}`,
            paddingBottom: '8px',
            fontSize: '24px',
            lineHeight: '28px',
            '&:hover': {
              borderBottom: theme => `2px solid ${theme.palette.custom.errorPage.link.hoverFg}`
            }
          }}
          underline="none"
        >
          {link}
        </Link>
      </Box>
    </Stack>
  );
};
