import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { AuditLogEntry } from '../models';
import { loadAuditLogEntriesAction } from './audit-log.actions';

export interface AuditLogState {
  readonly items: AuditLogEntry[];
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialAuditLogState: AuditLogState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const auditLogSlice = createSlice({
  name: 'audit-log',
  initialState: initialAuditLogState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadAuditLogEntriesAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadAuditLogEntriesAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadAuditLogEntriesAction
      )
    );
    builder.addCase(loadAuditLogEntriesAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadAuditLogEntriesAction
      )
    );
  }
});

export const auditLogReducer = auditLogSlice.reducer;
