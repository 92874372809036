import type { RouteObject } from 'react-router-dom';
import { apiKeysRoutes } from '@bright/api-keys';
import { applicationsRoutes } from '@bright/applications';
import { bridgesRoutes } from '@bright/bridges';
import { bridgesScriptsRoutes } from '@bright/bridges-scripts';
import { groupsRoutes } from '@bright/groups';
import { rolesRoutes } from '@bright/roles';
import { securityRoutes } from '@bright/security';
import { usersRoutes } from '@bright/users';

export const settingsRoutes: RouteObject[] = [
  {
    path: 'settings',
    async lazy() {
      const { SettingsPages } = await import('./pages');
      return { Component: SettingsPages };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { SettingsPage } = await import('./pages');
          return { Component: SettingsPage };
        }
      },
      ...securityRoutes,
      ...applicationsRoutes,
      ...usersRoutes,
      ...groupsRoutes,
      ...rolesRoutes,
      ...apiKeysRoutes,
      ...bridgesRoutes,
      ...bridgesScriptsRoutes
    ]
  }
];
