import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, NavigationPermissionSummary } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import { loadNavigationPermissionsAction } from './navigation-permissions.actions';

interface NavigationPermissionsState {
  readonly items: NavigationPermissionSummary[];
  readonly organization: NavigationPermissionSummary | null;
  readonly total: number;
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly pending: Action[];
}

export const initialNavigationPermissionsState: NavigationPermissionsState = {
  items: [],
  organization: null,
  total: 0,
  pending: [],
  error: null
};

const navigationPermissionsSlice = createSlice({
  name: 'navigationPermissions',
  initialState: initialNavigationPermissionsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadNavigationPermissionsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadNavigationPermissionsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          organization: action.payload.organization,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadNavigationPermissionsAction
      )
    );
    builder.addCase(loadNavigationPermissionsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadNavigationPermissionsAction
      )
    );
  }
});

export const navigationPermissionsReducer = navigationPermissionsSlice.reducer;
