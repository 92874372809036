import { axiosInstance } from '@bright/core';
import type { BridgeNetworkPayload, BridgeNetworkResult } from '../models';

export const loadBridgeNetworkResult = async (
  bridgeId: string
): Promise<BridgeNetworkResult | void> => {
  const response = await axiosInstance.get<BridgeNetworkResult | void>(
    `/rest/v1/bridges/${bridgeId}/network`
  );
  return response.data;
};

export const runBridgeNetworkTest = async (
  bridgeId: string,
  payload: BridgeNetworkPayload
): Promise<BridgeNetworkResult> => {
  const response = await axiosInstance.post<BridgeNetworkResult>(
    `/rest/v1/bridges/${bridgeId}/network`,
    payload
  );
  return response.data;
};
