import type { AxiosResponse } from 'axios';
import type { ID, IpRestriction, PaginationResponse } from '@bright/api';
import { axiosInstance } from '@bright/core';
import type { IpRestrictionStatus, ManageIpRestrictionPayload } from '../models';
import { IpRestrictionBulkAction } from '../models';

export const loadIpRestrictions = async (orgId: ID['id'], queryString: string) => {
  const response = await axiosInstance.get<PaginationResponse<IpRestriction>>(
    `/rest/v1/orgs/${orgId}/ip-restriction/allows?${queryString}`
  );
  return response.data;
};

export const getIpRestrictionsStatus = async (orgId: ID['id']) => {
  const response = await axiosInstance.get<IpRestrictionStatus>(
    `/rest/v1/orgs/${orgId}/ip-restriction`
  );

  return response.data;
};

export const updateIpRestrictionStatus = async (orgId: ID['id'], payload: IpRestrictionStatus) => {
  await axiosInstance.patch<ID>(`/rest/v1/orgs/${orgId}/ip-restriction`, payload);
};

export const runIpRestrictionBulkAction = async (
  ids: ID['id'][],
  orgId: ID['id'],
  action: IpRestrictionBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case IpRestrictionBulkAction.DELETE:
      response = await axiosInstance.post<void>(
        `/rest/v1/orgs/${orgId}/ip-restriction/allows/delete`,
        {
          ids
        }
      );
      break;
    default: {
      throw new Error(`Unknown ip restriction bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const loadIpRestriction = async (orgId: ID['id'], ipRestrictionId: ID['id']) => {
  const response = await axiosInstance.get<IpRestriction>(
    `/rest/v1/orgs/${orgId}/ip-restriction/allows/${ipRestrictionId}`
  );

  return response.data;
};

export const createIpRestriction = async (orgId: ID['id'], payload: ManageIpRestrictionPayload) => {
  const response = await axiosInstance.post<ID>(
    `/rest/v1/orgs/${orgId}/ip-restriction/allows`,
    payload
  );
  return response.data;
};

export const updateIpRestriction = async (
  orgId: ID['id'],
  ipRestrictionId: ID['id'],
  payload: ManageIpRestrictionPayload
) => {
  const response = await axiosInstance.put<void>(
    `/rest/v1/orgs/${orgId}/ip-restriction/allows/${ipRestrictionId}`,
    payload
  );

  return response.data;
};
