import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { SitemapNode } from '../models';
import { loadSitemapAction } from './sitemap.actions';

export interface SitemapState {
  readonly items: SitemapNode[];
  readonly error: ApiError | null;
  readonly pending: Action[];
}

const initialSitemapState: SitemapState = {
  items: [],
  pending: [],
  error: null
};

export const sitemapSlice = createSlice({
  name: 'sitemap',
  initialState: initialSitemapState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSitemapAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadSitemapAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload
        },
        loadSitemapAction
      )
    );
    builder.addCase(loadSitemapAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadSitemapAction
      )
    );
  }
});

export const sitemapReducer = sitemapSlice.reducer;
