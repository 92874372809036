import type { RouteObject } from 'react-router-dom';

export const globalVariablesRoutes: RouteObject[] = [
  {
    path: 'global-variables',
    async lazy() {
      const { GlobalVariablesPage } = await import('./pages');
      return { Component: GlobalVariablesPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { CreateGlobalVariableParameterPage } = await import('./pages');
          return { Component: CreateGlobalVariableParameterPage };
        }
      },
      {
        path: ':globalVariableId',
        async lazy() {
          const { EditGlobalVariableParameterPage } = await import('./pages');
          return { Component: EditGlobalVariableParameterPage };
        }
      }
    ]
  }
];
