import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, ExportFormat, PaginationResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import type { AuthPool, AuthPoolBulkAction } from '../models';
import {
  exportAuthenticationPools,
  loadAuthenticationPools,
  runAuthenticationPoolsBulkAction
} from '../services';

export const loadAuthenticationPoolsAction = createAsyncThunk<
  PaginationResponse<AuthPool>,
  {
    appId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'authentication-pools/loadAuthenticationPools',
  async ({ appId, queryString }, { rejectWithValue }) => {
    try {
      return await loadAuthenticationPools(appId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().authenticationPools, loadAuthenticationPoolsAction)
  }
);

export const runAuthenticationPoolsBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: AuthPoolBulkAction;
  },
  { state: RootState }
>(
  'authentication-pools/runAuthenticationPoolsBulkAction',
  async ({ ids, action }, { rejectWithValue }) => {
    try {
      return await runAuthenticationPoolsBulkAction(ids, action);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const exportAuthenticationPoolsAction = createAsyncThunk<
  void,
  {
    appId: string;
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>(
  'authentication-pools/exportAuthenticationPools',
  async ({ appId, ids, format }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      const response = await exportAuthenticationPools(orgId, appId, format, ids);
      const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
      fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
