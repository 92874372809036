import type { FC } from 'react';
import type { OverridableStringUnion } from '@mui/types';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import type { Variant } from '@mui/material/styles/createTypography';

interface AbsoluteSpinnerProps {
  readonly opened?: boolean;
  readonly text?: string;
  readonly backgroundColor?: string;
  readonly typography?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
}

export const AbsoluteSpinner: FC<AbsoluteSpinnerProps> = ({
  opened = true,
  text,
  backgroundColor,
  typography = 'h1'
}) => {
  if (!opened) return null;

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
        backgroundColor: backgroundColor
          ? backgroundColor
          : theme => theme.palette.custom.spinner.bg
      }}
    >
      <CircularProgress />
      {text && (
        <Typography marginTop={2} variant={typography}>
          {text}
        </Typography>
      )}
    </Stack>
  );
};
