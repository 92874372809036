import { axiosInstance } from '@bright/core';
// eslint-disable-next-line import/extensions
import mockedDashboardData from '../../../mocks/dashboard.json';
import type { DashboardData } from '../models';

export const loadDashboard = async (orgId: string, appId: string): Promise<DashboardData> => {
  // eslint-disable-next-line no-constant-condition
  if ('mocked') {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockedDashboardData as DashboardData);
      }, 500);
    });
  }

  const response = await axiosInstance.get<DashboardData>(
    `/api/v4/orgs/${orgId}/apps/${appId}/dashboard`
  );
  return response.data;
};
