export const blue = {
  50: '#E0EAFF',
  100: '#BAD6F1',
  200: '#A3C8ED',
  300: '#8CBAE9',
  400: '#75ADE4',
  500: '#6AABEB',
  600: '#1976D2',
  700: '#0864C0',
  800: '#075AAE',
  900: '#00439F'
} as const;

export const red = {
  50: '#FFF4F4',
  100: '#FFEAEA',
  200: '#FFD2D2',
  300: '#FFBFBF',
  400: '#FF9494',
  500: '#FF7F7F',
  600: '#FF4343',
  700: '#FF2929',
  800: '#EA1010',
  900: '#E00000'
} as const;

export const yellow = {
  50: '#FFF7D8',
  100: '#FFF3C4',
  200: '#FFEFB0',
  300: '#FFEB9C',
  400: '#FFE789',
  500: '#FFE375',
  600: '#FAD649',
  700: '#F0C825',
  800: '#E4BA14',
  900: '#B08D00'
} as const;

export const orange = {
  50: '#FFF5E9',
  100: '#FFEBD2',
  200: '#FFD8A6',
  300: '#FFC479',
  400: '#FFC06F',
  500: '#FFB250',
  600: '#FB8C00',
  700: '#EC8400',
  800: '#DB7A00',
  900: '#C16B00'
} as const;

export const green = {
  50: '#ECF9F2',
  100: '#DAF3E5',
  200: '#C7EDD8',
  300: '#B5E7CB',
  400: '#A2E1BE',
  500: '#8FDBB2',
  600: '#45C37E',
  700: '#2CA965',
  800: '#0A8340',
  900: '#047B39'
} as const;

export const purple = {
  50: '#F1F1FB',
  100: '#E3E2F8',
  200: '#D6D4F4',
  300: '#C8C6F0',
  400: '#BAB7ED',
  500: '#ACA9E9',
  600: '#7B77DE',
  700: '#5C57C0',
  800: '#544FB9',
  900: '#423DA7'
} as const;

export const violet = {
  50: '#FBEDFC',
  100: '#F6DBF9',
  200: '#F2C9F6',
  300: '#EEB7F3',
  400: '#E9A5EF',
  500: '#E593EC',
  600: '#D44CE0',
  700: '#BA32C6',
  800: '#AE22BA',
  900: '#A119AD'
} as const;

export const brightDark = {
  50: '#E8E8E8',
  100: '#C7CBD3',
  200: '#8B96A8',
  300: '#5E6C83',
  400: '#4A5971',
  500: '#334259',
  600: '#202E43',
  700: '#07152A',
  800: '#030F20',
  900: '#000915'
} as const;

export const brightLight = {
  50: '#FFFFFF',
  100: '#FCFDFD',
  200: '#FBFCFC',
  300: '#FAFBFB',
  400: '#F9F9FB',
  500: '#F7F8FA',
  600: '#F2F4F6',
  700: '#D8DDE7',
  800: '#BCC7DA',
  900: '#B0BED7'
} as const;

export const grey = {
  50: '#F6F7FA',
  100: '#F3F5F9',
  200: '#F1F4F8',
  300: '#EFF2F7',
  400: '#EDF0F5',
  500: '#EAEEF4',
  600: '#E8ECF3',
  700: '#D8DDE7',
  800: '#D5D9E0',
  900: '#CED2D9'
} as const;

export const ocean = {
  50: '#EFF4FF',
  100: '#E5EAF4'
} as const;
