import type { RouteObject } from 'react-router-dom';

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    async lazy() {
      const { DashboardPage } = await import('./pages');
      return { Component: DashboardPage };
    }
  }
];
