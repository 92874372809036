import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, IpRestriction } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  loadIpRestrictionsAction,
  runIpRestrictionsBulkActionAction
} from './ip-restrictions.actions';

export interface IpRestrictionsState {
  readonly items: IpRestriction[];
  readonly total: number;
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
  readonly enabled: boolean;
}

const initialIpRestrictionsState: IpRestrictionsState = {
  items: [],
  total: 0,
  pending: [],
  error: null,
  enabled: false
};

export const ipRestrictionsSlice = createSlice({
  name: 'ipRestrictions',
  initialState: initialIpRestrictionsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadIpRestrictionsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadIpRestrictionsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadIpRestrictionsAction
      )
    );
    builder.addCase(loadIpRestrictionsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadIpRestrictionsAction
      )
    );

    builder.addCase(runIpRestrictionsBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runIpRestrictionsBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runIpRestrictionsBulkActionAction)
    );
    builder.addCase(
      runIpRestrictionsBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runIpRestrictionsBulkActionAction
        )
    );
  }
});

export const ipRestrictionsReducer = ipRestrictionsSlice.reducer;
