import type { RouteObject } from 'react-router-dom';

export const usersRoutes: RouteObject[] = [
  {
    path: 'users',
    async lazy() {
      const { UsersPage } = await import('./pages');
      return { Component: UsersPage };
    },
    children: [
      {
        path: 'new',
        async lazy() {
          const { InviteUserPage } = await import('./pages');
          return { Component: InviteUserPage };
        }
      },
      {
        path: ':userId',
        async lazy() {
          const { EditUserPage } = await import('./pages');
          return { Component: EditUserPage };
        }
      }
    ]
  }
];
