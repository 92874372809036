import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { SitemapNode } from '../models';
import { loadSitemap } from '../services';

export const loadSitemapAction = createAsyncThunk<
  SitemapNode[],
  {
    appId: string;
  },
  { state: RootState }
>(
  'sitemap/loadSitemap',
  async ({ appId }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());

    try {
      return await loadSitemap(orgId, appId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().sitemap, loadSitemapAction)
  }
);
