import { createSvgIcon } from '@mui/material/utils';

export const SignOutIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.31681 3H4.22857C3.76054 3 3.31168 3.18437 2.98074 3.51256C2.64979 3.84075 2.46387 4.28587 2.46387 4.75V15.25C2.46387 15.7141 2.64979 16.1592 2.98074 16.4874C3.31168 16.8156 3.76054 17 4.22857 17H7.31681M7.53613 10H17.5361M17.5361 10L13.7152 6M17.5361 10L13.7152 14"
      stroke="currentcolor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'SignOutIcon'
);
