import type { AxiosResponse } from 'axios';
import type { ExportFormat, PaginationResponse } from '@bright/api';
import { axiosInstance } from '@bright/core';
import type { Library, LibraryPossibleVulnerabilities } from '../models';

export const loadLibraries = async (
  appId: string,
  queryString: string
): Promise<PaginationResponse<Library>> => {
  const response = await axiosInstance.get<PaginationResponse<Library>>(
    `/rest/v1/apps/${appId}/libraries/bright/tests?${queryString}`
  );

  return response.data;
};

export const loadLibraryPossibleVulnerabilities = async (
  appId: string,
  libraryId: string
): Promise<LibraryPossibleVulnerabilities> => {
  const response = await axiosInstance.get<LibraryPossibleVulnerabilities>(
    `/rest/v1/apps/${appId}/libraries/bright/tests/${libraryId}`
  );

  return response.data;
};

export const exportLibraries = async (
  orgId: string,
  appId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/api/v4/orgs/${orgId}/apps/${appId}/libraries/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};
