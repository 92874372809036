import type { ThemeOptions } from '@mui/material/styles';

export const typographyOptions: ThemeOptions = {
  typography: {
    fontSize: 12.25,
    fontFamily: [
      'Roboto',
      '-apple-system',
      'system-ui',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 400
    },
    h2: {
      fontSize: 18,
      lineHeight: 1.4,
      fontWeight: 500
    },
    h2Regular: {
      fontSize: 18,
      lineHeight: '28px',
      fontWeight: 400,
      letterSpacing: 0.15
    },
    h3: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 500
    },
    h4: {
      fontSize: 14,
      lineHeight: 1.57,
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400
    },
    subtitle1Medium: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: 0.15
    },
    subtitle2Medium: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: 0.4
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400
    },
    body1Medium: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500
    },
    body2: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: 0.15
    },
    body2Medium: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: 0.4
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 400
    },
    overline: {
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 400
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
      fontWeight: 400,
      letterSpacing: 0.15
    },
    buttonMedium: {
      fontSize: 14,
      textTransform: 'none',
      fontWeight: 500,
      letterSpacing: 0.15
    },
    button2: {
      fontSize: 12,
      textTransform: 'none',
      lineHeight: 1.5,
      fontWeight: 400
    },
    tag: {
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 500
    }
  },
  shape: {
    borderRadius: 8
  }
};
