import type { AuthenticationRequest } from './AuthenticationRequest';
import type { AuthenticationResponse } from './AuthenticationResponse';
import type { AuthenticationTestStageType } from './AuthenticationTestStageType';
import type { AuthenticationTestStatus } from './AuthenticationTestStatus';

export const NewAuthenticationTestResultsKey = Symbol('NewAuthentication');

export type TestResultKey = string | typeof NewAuthenticationTestResultsKey;

export interface AuthenticationTestResult {
  readonly stage: AuthenticationTestStageType;
  readonly name?: string;
  readonly status: AuthenticationTestStatus;
  readonly timestamp: number;
  readonly request?: AuthenticationRequest;
  readonly response?: AuthenticationResponse;
  readonly screenshot?: string;
  readonly message?: string;
  readonly pageBody: string;
}
