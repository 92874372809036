import type { FC } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import {
  axiosInstance,
  legacyQueryParamsRequestInterceptor,
  legacyResponseInterceptor,
  store
} from '@bright/core';
import { i18n } from '../i18n/config';
import './global.css';
import { CssBaseline, ThemeProvider } from '../share/ui';
import { router } from './router';

// TODO: remove when no BFF used for pagination (ADM-2671)
axiosInstance.interceptors.request.use(legacyQueryParamsRequestInterceptor);
axiosInstance.interceptors.response.use(legacyResponseInterceptor);

export const App: FC = () => {
  LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_KEY);

  return (
    <>
      <ReduxProvider store={store}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme="light">
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeProvider>
        </I18nextProvider>
      </ReduxProvider>
    </>
  );
};
