import type { PaginationResponse } from '@bright/api';
import { axiosInstance } from './axiosInstance';

export const fetchAllPages = async <T>(
  initialUrl: string,
  limit?: number,
  signal?: AbortSignal
): Promise<T[]> => {
  let url = initialUrl;
  let allItems: T[] = [];
  const hasSearchParams = initialUrl.includes('?');

  while (url && (!limit || allItems.length < limit)) {
    const res = (await axiosInstance.get<PaginationResponse<T>>(url, { signal })).data;
    allItems = [...allItems, ...res.items];
    url = res.next ? `${initialUrl}${hasSearchParams ? '&' : '?'}next=${res.next}` : '';
  }

  return allItems;
};
