import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, Target, TargetHost, TargetHostStatus } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  createTargetAction,
  loadTargetAction,
  updateTargetAction,
  verifyCrawlerTargetAction
} from './target.actions';

export interface TargetState {
  readonly target: Target | null;
  readonly crawler: ({ status: TargetHostStatus | null } & Pick<TargetHost, 'url'>)[] | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialTargetState: TargetState = {
  target: null,
  crawler: null,
  pending: [],
  error: null
};

export const targetSlice = createSlice({
  name: 'target',
  initialState: initialTargetState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadTargetAction.pending, (state, action) => ({
      ...state,
      target: null,
      crawler: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadTargetAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          target: action.payload
        },
        loadTargetAction
      )
    );
    builder.addCase(loadTargetAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadTargetAction
      )
    );

    builder.addCase(updateTargetAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateTargetAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateTargetAction)
    );
    builder.addCase(updateTargetAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateTargetAction
      )
    );

    builder.addCase(createTargetAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createTargetAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createTargetAction)
    );
    builder.addCase(createTargetAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createTargetAction
      )
    );

    builder.addCase(verifyCrawlerTargetAction.pending, (state, action) => ({
      ...state,
      crawler: !state!.crawler
        ? null
        : [
            ...state.crawler.filter(item => item.url !== action.meta.arg.url),
            { url: action.meta.arg.url, status: null }
          ],
      pending: [...state.pending, action]
    }));
    builder.addCase(verifyCrawlerTargetAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          crawler: [
            ...(state.crawler || []).filter(item => item.url !== action.meta.arg.url),
            action.payload[0]
          ]
        },
        verifyCrawlerTargetAction
      )
    );
    builder.addCase(verifyCrawlerTargetAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        verifyCrawlerTargetAction
      )
    );
  }
});

export const targetReducer = targetSlice.reducer;
