import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, PaginationResponse, TrafficMetric } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import { loadScanTrafficMetrics } from '../services';

export const loadScanTrafficMetricsAction = createAsyncThunk<
  PaginationResponse<TrafficMetric>,
  { scanId: string; queryString: string },
  { state: RootState }
>(
  'scan/loadScanTrafficMetrics',
  async ({ scanId, queryString }, { rejectWithValue }) => {
    try {
      if (!scanId) {
        return rejectWithValue('Scan is not available');
      }
      return loadScanTrafficMetrics(scanId, queryString);
    } catch (err: unknown) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().scanTrafficMetrics, loadScanTrafficMetricsAction)
  }
);
