import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, PaginationResponse, VulnerabilityStatus } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type {
  VulnerabilityEntrypoint,
  VulnerabilityEntrypointBulkAction,
  VulnerabilityEntrypointDetails
} from '../models';
import {
  loadVulnerabilityEntrypointDetails,
  loadVulnerabilityEntrypoints,
  runVulnerabilityEntrypointsBulkAction
} from '../services';

export const loadVulnerabilityEntrypointsAction = createAsyncThunk<
  PaginationResponse<VulnerabilityEntrypoint>,
  {
    vulnerabilityId: string;
    queryString: string;
  },
  { state: RootState }
>(
  'vulnerabilityEntrypoints/loadVulnerabilityEntrypoints',
  async ({ vulnerabilityId, queryString }, { rejectWithValue }) => {
    try {
      return await loadVulnerabilityEntrypoints(vulnerabilityId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerabilityEntrypoints, loadVulnerabilityEntrypointsAction)
  }
);

export const loadVulnerabilityEntrypointDetailsAction = createAsyncThunk<
  VulnerabilityEntrypointDetails,
  {
    vulnerabilityId: string;
    vulnerabilityEntrypointId: string;
  },
  { state: RootState }
>(
  'vulnerabilityEntrypoints/loadVulnerabilityEntrypointDetails',
  async ({ vulnerabilityId, vulnerabilityEntrypointId }, { rejectWithValue }) => {
    try {
      return await loadVulnerabilityEntrypointDetails(vulnerabilityId, vulnerabilityEntrypointId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: ({ vulnerabilityEntrypointId }, { getState }) =>
      actionNotPending(
        getState().vulnerabilityEntrypoints,
        action =>
          action.type === loadVulnerabilityEntrypointDetailsAction.pending.type &&
          (action as ReturnType<typeof loadVulnerabilityEntrypointDetailsAction.pending>).meta.arg
            .vulnerabilityEntrypointId === vulnerabilityEntrypointId
      )
  }
);

export const runVulnerabilityEntrypointsBulkActionAction = createAsyncThunk<
  string | void,
  {
    ids: string[];
    action: VulnerabilityEntrypointBulkAction;
    vulnerabilityId: string;
    status?: VulnerabilityStatus;
  }
>(
  'vulnerabilityEntrypoints/runVulnerabilityEntrypointsBulkAction',
  async ({ ids, action, vulnerabilityId, status }, { rejectWithValue }) => {
    try {
      return await runVulnerabilityEntrypointsBulkAction(ids, action, vulnerabilityId, status);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
