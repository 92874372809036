import { createSvgIcon } from '@mui/material/utils';

export const HelpMenuIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 12.8C13.3255 12.8 14.4 11.7255 14.4 10.4C14.4 9.07453 13.3255 8.00001 12 8.00001C10.6745 8.00001 9.60001 9.07453 9.60001 10.4M11.8499 15.45H12.1499M11.8499 15.75H12.1499M20 12C20 16.4183 16.4183 20 12 20C7.58173 20 4 16.4183 4 12C4 7.58173 7.58173 4 12 4C16.4183 4 20 7.58173 20 12ZM12.4 15.6C12.4 15.8209 12.2209 16 12 16C11.7791 16 11.6 15.8209 11.6 15.6C11.6 15.3791 11.7791 15.2 12 15.2C12.2209 15.2 12.4 15.3791 12.4 15.6Z"
      stroke="currentColor"
      strokeWidth="1.44"
      strokeLinecap="round"
    />
  </svg>,
  'HelpMenuIcon'
);
