import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ID } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { BridgesScriptDetails, ManageBridgesScriptPayload } from '../models';
import { createBridgesScript, loadBridgesScript, updateBridgesScript } from '../services';

export const loadBridgesScriptAction = createAsyncThunk<
  BridgesScriptDetails,
  {
    scriptId: string;
  },
  { state: RootState }
>(
  'bridgesScript/loadBridgesScript',
  async ({ scriptId }, { rejectWithValue }) => {
    try {
      return await loadBridgesScript(scriptId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().bridgesScript, loadBridgesScriptAction)
  }
);

export const updateBridgesScriptAction = createAsyncThunk<
  void,
  {
    scriptId: string;
    payload: ManageBridgesScriptPayload;
  },
  { state: RootState }
>(
  'bridgesScript/updateBridgesScript',
  async ({ scriptId, payload }, { rejectWithValue }) => {
    try {
      return await updateBridgesScript(scriptId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().bridgesScript, updateBridgesScriptAction)
  }
);

export const createBridgesScriptAction = createAsyncThunk<
  ID,
  {
    payload: ManageBridgesScriptPayload;
  },
  { state: RootState }
>(
  'bridgesScript/createBridgesScript',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await createBridgesScript(payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().bridgesScript, createBridgesScriptAction)
  }
);
